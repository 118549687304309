<template>
  <div>
    <slot name="content" />
  </div>
</template>

<script>
import { SystemDialogMessage } from '@/constants/enums';
import * as Sentry from '@sentry/vue';

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ErrorBoundary',
  props: {
    stopPropagation: {
      type: Boolean,
      default: true
    }
  },

  errorCaptured(error, vm, info) {
    Sentry.captureException(error);

    if (error) this.$log.error('ErrorBoundary Error :>> ', error);
    if (vm) this.$log.error('ErrorBoundary Vm :>> ', vm);
    if (info) this.$log.error('ErrorBoundary Info :>> ', info);

    window.location.href = this.$systemDialog(SystemDialogMessage.ERROR_COMMON);

    return !this.stopPropagation;
  }
});
</script>
