import Repository from '../Repository';
const resource = '/dp/bookmark';

export default {
  getList() {
    return Repository.get(`${resource}`);
  },

  setMyrecipeStatus(id, params) {
    return Repository.post(`${resource}/${id}`, params);
  }
};
