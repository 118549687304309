import ProCard from './ProCard.vue';

export default {
  title: 'SHARED/ProCard',
  component: ProCard
};

const Template = (args, { argTypes }) => ({
  components: { ProCard },
  props: Object.keys(argTypes),
  template: '<ProCard v-bind="$props" />'
});

const content = {
  id: 1,
  name: '田中 太郎',
  name_alphabetical: 'Taro Tanaka',
  overview: 'ジャパン サイフォニスト チャンピオンシップ2019 優勝',
  image_url: 'https://via.placeholder.com/128x128/3c4/fff'
};

export const Default = Template.bind({});
Default.args = {
  content: [content],
  description: ''
};

export const WithDescription = Template.bind({});
WithDescription.args = {
  content: [content],
  description:
    'ほんのり苦味があって、コクを感じる風味深いオリジナルブレンド。ほんのり苦味があって、コクを感じる風味深いオリジナルブレンド。\n深い味わいをぜひお楽しみください^^'
};

export const MultipleContents = Template.bind({});
MultipleContents.args = {
  content: [content, content, content],
  description: ''
};
