export const whitelist = [
  'https://www.ucc.co.jp/',
  'https://club-ucc.jp/',
  'https://store.ucc.co.jp/',
  'https://coffeestyle.jp/',
  'https://mystyle.ucc.co.jp/magazine',
  'https://www.ucccoffeeprofessional.co.jp/',
  'https://cafe-owners.jp/',
  'https://foodsfridge.co.jp/',
  'https://foodsfridge.jp/',
  'https://www.ufs.co.jp/',
  'https://www.ueshima-coffee-ten.jp/',
  'https://www.ueshima-coffee-ten-onlineshop.net/',
  'https://www.lucky-coffee-machine.co.jp/',
  'https://solofreshcoffee.com/',
  'https://drip-pod.jp/',
  'https://www.keurig.jp/',
  'https://www.unicafe.com/',
  'https://www.ucot.co.jp/',
  'https://www.saitou-coffee.co.jp/company/',
  'https://www.unitedcoffeejapan.co.jp/',
  'https://www.jpcc.co.jp/',
  'https://www.hills-coffee.co.jp/',
  'https://www.drink.co.jp/',
  'https://www.u-coffee.co.jp/',
  'https://ueshima.co.jp/',
  'https://www.ueshima-coffee.co.jp/',
  'https://www.cafe-club.jp/',
  'https://www.shinwacoffee.co.jp/ccshop.html',
  'https://www.artcoffee.co.jp/',
  'https://www.artcoffee.jp/',
  'https://www.ucc-hawaii.com/',
  'https://web.dev.comsbi.com/v1/c6844203465535695872/',
  'https://web.stg.comsbi.com/v1/c6844204535240135680/',
  'https://web.prd.comsbi.com/v1/c6849510067977063424/',
  'https://access.line.me/oauth2/v2.1/',
  'https://dev.ucc-coffee-creation.sonicmoov.com/',
  'https://stg.ucc-coffee-creation.sonicmoov.com/',
  'https://journal.ucc.co.jp/'
];
