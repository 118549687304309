export default {
  get() {
    const response = {
      take_over_code: '12disped'
    };
    return [200, response];

    // error: タイムアウト
    // return [408, { message: 'タイムアウトしました' }];
  },

  post({ data }) {
    if (data.take_over_code !== 'code') return [500, {}];

    const response = {
      access_token:
        process.env.VUE_APP_DEBUG_ACCESS_TOKEN_USER_1 || 'NEW_ACCESS_TOKEN',
      token_type: 'bearer',
      expires_in: 1672412400000
    };
    return [200, response];
  }
};
