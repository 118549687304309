import EventCard from './EventCard.vue';

export default {
  title: 'SHARED/EventCard',
  component: EventCard
};

const Template = (args, { argTypes }) => ({
  components: { EventCard },
  props: Object.keys(argTypes),
  template: '<EventCard v-bind="$props" />'
});

const content = {
  id: 1,
  image_url: 'https://placehold.jp/333/fff/640x200.png?text=HEADER',
  title: '田中太郎プロイベント',
  venue_name: 'UCC コーヒーアカデミー神戸校',
  schedules: [
    {
      start_at: '2022-12-23 13:00:00',
      end_at: '2022-12-23 18:00:00'
    }
  ],
  reward: [
    {
      type: 1,
      amount: 30
    }
  ]
};

export const SingleDay = Template.bind({});
SingleDay.args = {
  content
};

export const MultipleDays = Template.bind({});
MultipleDays.args = {
  content: {
    ...content,
    schedules: [
      {
        start_at: '2022-12-23 13:00:00',
        end_at: '2022-12-24 18:00:00'
      }
    ]
  }
};
