import { wordings } from '@/constants/wordings';

/**
 * コンテンツタブ
 */
export class ContentTab {
  static TOP = wordings.top;
  static READ = wordings.read;
  static JOIN = wordings.join;
  static STUDY = wordings.study;

  /**
   * タブ名からルート名を取得する
   * @param {string} name
   * @returns {string | undefined}
   */
  static getTabRouteName = (name) => {
    const prefix = 'Content';
    let value = '';
    switch (name) {
      case this.TOP:
        value = 'Top';
        break;
      case this.READ:
        value = 'Read';
        break;
      case this.JOIN:
        value = 'Join';
        break;
      case this.STUDY:
        value = 'Study';
        break;
      default:
        return undefined;
    }
    return prefix + value;
  };
}
