import { dialog } from '@/plugins/customUrlScheme';

/**
 * @example
 * $presentDialog('10')
 */
export default (_, inject) => {
  const message = (id) => {
    return dialog('gift', 'url=/gift/detail/?id=' + id);
  };
  inject('presentDialog', message);
};
