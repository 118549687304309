var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.content
    ? _c("ContentCard", {
        staticClass: "event-card c-card--mb20",
        attrs: {
          src: _vm.content["image_url"],
          title: _vm.content["title"],
          subtitle: _vm.content["venue_name"],
          schedules: _vm.content["schedules"][0] || [],
          "content-type": "join",
        },
        on: {
          click: function ($event) {
            return _vm.onClickContentLink(_vm.content["id"])
          },
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }