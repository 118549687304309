<template>
  <div class="l-wrap">
    <ErrorBoundary :stop-propagation="true">
      <template v-slot:error />
      <template v-slot:content>
        <router-view />
      </template>
    </ErrorBoundary>
  </div>
</template>

<script>
import ErrorBoundary from '@/components/ErrorBoundary';
export default {
  components: {
    ErrorBoundary
  }
};
</script>
