<template>
  <div class="c-empty">
    <div class="c-empty__icon">
      <Icon v-if="iconName !== 'none'" :name="iconName" />
    </div>
    <!-- /.c-empty__icon -->

    <div class="c-empty__text">
      <p class="c-title6 c-title6--grey02">{{ text }}</p>
    </div>
    <!-- /.c-empty__text -->
    <div v-if="subtext" class="c-empty__subtext">
      <p>{{ subtext }}</p>
    </div>
    <!-- /.c-empty__subtext -->
  </div>
  <!-- /.c-empty -->
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: 'information'
    },

    text: {
      type: String,
      default: 'データがありません'
    },

    subtext: {
      type: String,
      default: ''
    }
  }
};
</script>
