var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "c-buttonLineLogin",
      class: _vm.classes,
      attrs: { href: _vm.href },
    },
    [
      _c(
        "span",
        { staticClass: "c-buttonLineLogin__logo" },
        [
          _c("Icon", {
            attrs: {
              name: "logoLine",
              width: "43",
              height: "40",
              color: _vm.disabled ? "lineDisabledText" : "white01",
            },
          }),
        ],
        1
      ),
      _c(
        "span",
        { staticClass: "c-buttonLineLogin__label" },
        [
          _vm._t("default", function () {
            return [
              _vm._v(
                _vm._s(_vm.WORDS.LINE_ID) +
                  "と" +
                  _vm._s(_vm.WORDS.LINK) +
                  "する"
              ),
            ]
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }