import Repository from '../Repository';
const resource = '/dp/extract-history';

export default {
  getList() {
    return Repository.get(`${resource}`);
  },
  create(requestBody) {
    return Repository.post(`${resource}`, requestBody);
  }
};
