import BaseCheckbox from './BaseCheckbox.vue';

export default {
  title: 'SHARED/BaseCheckbox',
  component: BaseCheckbox
};

const Template = (args, { argTypes }) => ({
  components: { BaseCheckbox },
  props: Object.keys(argTypes),
  template: '<BaseCheckbox v-bind="$props" />'
});

const SlotTemplate = (args, { argTypes }) => ({
  components: { BaseCheckbox },
  props: Object.keys(argTypes),
  template: '<BaseCheckbox v-bind="$props">With slot content</BaseCheckbox>'
});

export const Default = Template.bind({});
Default.args = {
  label: 'ラベル',
  value: false
};

export const Checked = Template.bind({});
Checked.args = {
  label: 'ラベル',
  value: true
};

export const WithSlot = SlotTemplate.bind({});
WithSlot.args = {
  value: false
};
WithSlot.parameters = {
  chromatic: { disableSnapshot: true }
};
