<template>
  <div class="pro-avatar" :style="blockStyle">
    <div class="pro-avatar__wrapper" :style="wrapperStyle">
      <div v-if="src" class="pro-avatar__img" :style="imgStyle">
        <img :src="src" :alt="alt" />
      </div>

      <div class="pro-avatar__bg" :style="bgStyle">
        <Icon name="person" color="white01" :width="size" :height="size" />
      </div>
    </div>

    <div v-if="isFavorite" class="pro-avatar__favorite" :style="favoriteStyle">
      <Icon name="heartCircleOn" color="primary01" :width="favoriteSize" :height="favoriteSize" />
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
  props: {
    src: {
      type: String,
      default: ''
    },

    size: {
      type: [String, Number],
      default: 120
    },

    alt: {
      type: String,
      default: ''
    },

    isFavorite: {
      type: Boolean,
      default: false
    },

    isBordered: {
      type: Boolean,
      default: true
    }
  },

  setup: (props) => {
    const size = Number(props.size);

    const borderWidth = computed(() => size && props.isBordered ? `${Math.ceil(size * 0.032)}` : 0);
    const wrapperSize = computed(() => size && props.isBordered && borderWidth.value ? size + borderWidth.value : 0);
    const bgPosition = computed(() => size ? `${Math.ceil(size * 0.5)}` : 0);

    const blockStyle = computed(() =>
      size
        ? {
          width: size + 'px',
          height: size + 'px'
        }
        : false
    );

    const wrapperStyle = computed(() =>
      size
        ? {
          'border-width': `${borderWidth.value}px`,
          'border-radius': size + 'px',
          width: wrapperSize + 'px',
          height: wrapperSize + 'px'
        }
        : false
    );

    const imgStyle = computed(() =>
      size
        ? {
          'margin-top': `-${Math.ceil(borderWidth.value)}px`,
          'margin-left': `-${Math.ceil(borderWidth.value)}px`,
          'background-size': 'cover',
          width: size + 'px',
          height: size + 'px'
        }
        : false
    );

    const bgStyle = computed(() =>
      size
        ? {
          'margin-top': `-${bgPosition.value}px`,
          'margin-left': `-${bgPosition.value}px`,
          'width': size + 'px',
          'height': size + 'px'
        }
        : false
    );

    const favoriteSize = computed(() =>
      size
        ? Math.ceil(size * 0.5)
        : false);

    const favoriteStyle = computed(() =>
      size && favoriteSize
        ? {
          'margin-right': `-${Math.ceil(size * 0.2)}px`,
          'margin-bottom': `-${Math.ceil(size * 0.12)}px`,
          'width': favoriteSize.value + 'px',
          'height': favoriteSize.value + 'px'
        }
        : false
    );

    return {
      blockStyle,
      wrapperStyle,
      imgStyle,
      bgStyle,
      favoriteSize,
      favoriteStyle
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.pro-avatar {
  position: relative;
  &__wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: variables.$grey04;
    border-color: variables.$white02;
    border-style: solid;
    overflow: hidden;
  }

  &__img {
    @include mixin.imageAspectRatio(120, 120);
    z-index: 1;
    img {
      border-radius: 50%;
    }
  }

  &__bg {
    z-index: 0;
    position: absolute;
    top: 50%;
    left: 50%;
  }

  &__favorite {
    z-index: 1;
    position: absolute;
    right: 2px;
    bottom: 2px;
    display: grid;
    place-items: center;
  }
}
</style>
