<template>
  <div class="c-headImage">
    <div class="c-headImage__inner">
      <div
        class="c-headImage__bg"
        :class="{ 'c-headImage__bg--contain': contain }">
        <img :src="src" alt="" />
      </div>
      <!-- /.c-headImage__bg -->

      <div v-if="recentAction" class="c-headImage__rate">
        <div class="c-headImage__rate__face">
          <Icon :name="recentAction.icon" :color="recentAction.color" />
        </div>
        <!-- /.c-headImage__rate__face -->

        <div class="c-headImage__rate__content">
          <p class="c-headImage__rate__message">
            {{ recentAction.label }}
          </p>
          <p class="c-headImage__rate__date">
            {{ $utilities.localizeDate(action.at) }}
          </p>
        </div>
        <!-- /.c-headImage__rate__content -->
      </div>
      <!-- /.c-headImage__rate -->

      <div v-if="mapZone" class="c-headImage__map">
        <Icon :name="`coffeeMap${mapZone}`" color="white01" />
      </div>
      <!-- /.c-headImage__map -->
    </div>
    <!-- /.c-headImage__inner -->
  </div>
  <!-- /.c-headImage -->
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: ''
    },

    mapZone: {
      type: String,
      default: ''
    },

    /**
     * @typedef {number} UnixTime
     * @type {{ type: 'like' | 'hate' | 'memo'; at: UnixTime; }}
     */
    action: {
      type: Object,
      default: null
    },

    contain: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    recentAction() {
      if (!this.action) return null;

      return {
        like: {
          icon: 'faceGoodBordered',
          color: 'accent02',
          label: '「好き！」しました'
        },

        hate: {
          icon: 'faceBadBordered',
          color: 'grey01',
          label: '「う〜ん…」しました'
        },

        memo: {
          icon: 'memo',
          color: 'white01',
          label: 'コーヒーを登録'
        }
      }[this.action.type];
    }
  }
};
</script>
