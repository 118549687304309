import Vue from 'vue';
import Vuex from 'vuex';
import { useEnvironment } from '@/composables/useEnvironment';
const { isPrd } = useEnvironment();
const modules = {
  // hoge
};

Vue.use(Vuex);

export default new Vuex.Store({
  strict: !isPrd,
  modules: Object.keys(modules).reduce((acc, key) => {
    acc[key] = {
      ...modules[key],
      namespaced: true
    };
    return acc;
  }, {})
});
