const data = [
  {
    id: 1,
    is_favorable: 1,
    created_at: '1640340430',
    memo: {
      aroma: 100,
      bitterness: 200,
      sourness: 100,
      sweetness: 300,
      richness: 300,
      comment: 'うまい',
      event: {
        id: 1,
        title:
          'お気に入りのコーヒーに出会える！世界60カ国を飲み比べ コーヒージャーニー2022',
        at: 1640340430
      }
    },
    product: {
      id: 1,
      name: 'ブラック無糖コーヒー（PRO）',
      image_url: 'https://via.placeholder.com/750x460/0bc/fff?text=PRO',
      map_position: 36,
      map_zone: 'D',
      description:
        'レギュラーコーヒー100%、香りとコクを最大限引き出す高級豆を使用。ブラック無糖はUCC。香料は使用せず、コーヒー本来のおいしさを追求し、香りとキレが際立った無糖コーヒーにこだわりました。',
      ec_link_url: 'https://example.com',
      impressions: [],
      ingredients: [
        {
          id: 1,
          name: 'PROコーヒーに使用されているコーヒー名PROコーヒーに使用されているコーヒー名',
          description: '説明',
          image_url:
            'https://via.placeholder.com/550x460/ff7a00/fff?text=PRO%20COFFEE',
          ec_link_url:
            'https://store.ucc.co.jp/category/ITEM_60/GSP1301006.html'
        },
        {
          id: 2,
          name: 'PROコーヒーに使用されているコーヒー名２PROコーヒーに使用されているコーヒー名２',
          description: '説明',
          image_url:
            'https://via.placeholder.com/550x460/5a9d1d/fff?text=PRO%20COFFEE2',
          ec_link_url:
            'https://store.ucc.co.jp/category/ITEM_60/GSP1301006.html'
        },
        {
          id: null,
          name: '手入力されたコーヒー名',
          description: null,
          image_url: null,
          ec_link_url: null
        },
        {
          id: null,
          name: '手入力されたコーヒー名２',
          description: null,
          image_url: null,
          ec_link_url: null
        }
      ],
      baristas: [
        {
          id: 1,
          name: '中井 千香子',
          name_alphabetical: 'Chikako Nakai',
          overview: 'ジャパン サイフォニスト チャンピオンシップ2019 優勝',
          image_url: 'https://via.placeholder.com/128x128/3c4/fff'
        }
      ],
      barista_message:
        'ほんのり苦味があって、コクを感じる風味深いオリジナルブレンド。ほんのり苦味があって、コクを感じる風味深いオリジナルブレンド。\n深い味わいをぜひお楽しみください^^'
    },
    mileage: {
      now_mileage: 301,
      plus_mileage: 21,
      rank_up: true
    },
    present: [
      {
        id: 10,
        name: '74ポイントプレゼント！',
        description: 'プレセント',
        warning: '注意事項',
        type: 2,
        amount: 74,
        coupon: 'coupon1',
        image_url: 'https://via.placeholder.com/640x480.png/008844?text=nihil'
      }
    ]
  },
  {
    id: 2,
    is_favorable: 1,
    created_at: '1640340430',
    product: {
      id: 2,
      name: 'ブラック無糖コーヒー',
      image_url: 'https://via.placeholder.com/750x460/0bc/fff',
      map_position: 36,
      map_zone: 'D',
      description:
        'レギュラーコーヒー100%、香りとコクを最大限引き出す高級豆を使用。ブラック無糖はUCC。香料は使用せず、コーヒー本来のおいしさを追求し、香りとキレが際立った無糖コーヒーにこだわりました。',
      ec_link_url: 'https://example.com',
      impressions: [],
      ingredients: [],
      baristas: [],
      barista_message: null
    },
    mileage: {
      now_mileage: 301,
      plus_mileage: 21,
      rank_up: true
    },
    present: [
      {
        id: 10,
        name: '74ポイントプレゼント！',
        description: 'プレセント',
        warning: '注意事項',
        type: 2,
        amount: 74,
        coupon: 'coupon1',
        image_url: 'https://via.placeholder.com/640x480.png/008844?text=nihil'
      }
    ]
  },
  {
    id: 3,
    is_favorable: 0,
    created_at: '1640340430',
    product: {
      id: 3,
      name: 'ブラック無糖コーヒー（PRO・マップなし）',
      image_url: 'https://via.placeholder.com/750x460/0bc/fff?text=PRO',
      map_position: null,
      map_zone: null,
      description:
        'レギュラーコーヒー100%、香りとコクを最大限引き出す高級豆を使用。ブラック無糖はUCC。香料は使用せず、コーヒー本来のおいしさを追求し、香りとキレが際立った無糖コーヒーにこだわりました。',
      ec_link_url: 'https://example.com',
      impressions: [],
      ingredients: [
        {
          id: 1,
          name: 'PROコーヒーに使用されているコーヒー名PROコーヒーに使用されているコーヒー名',
          description: '説明',
          image_url:
            'https://via.placeholder.com/550x460/ff7a00/fff?text=PRO%20COFFEE',
          ec_link_url:
            'https://store.ucc.co.jp/category/ITEM_60/GSP1301006.html'
        },
        {
          id: 2,
          name: 'PROコーヒーに使用されているコーヒー名２PROコーヒーに使用されているコーヒー名２',
          description: '説明',
          image_url:
            'https://via.placeholder.com/550x460/5a9d1d/fff?text=PRO%20COFFEE2',
          ec_link_url:
            'https://store.ucc.co.jp/category/ITEM_60/GSP1301006.html'
        },
        {
          id: null,
          name: '手入力されたコーヒー名',
          description: null,
          image_url: null,
          ec_link_url: null
        },
        {
          id: null,
          name: '手入力されたコーヒー名２',
          description: null,
          image_url: null,
          ec_link_url: null
        }
      ],
      baristas: [
        {
          id: 1,
          name: '中井 千香子',
          name_alphabetical: 'Chikako Nakai',
          overview: 'ジャパン サイフォニスト チャンピオンシップ2019 優勝',
          image_url: 'https://via.placeholder.com/128x128/3c4/fff'
        }
      ],
      barista_message:
        'ほんのり苦味があって、コクを感じる風味深いオリジナルブレンド。ほんのり苦味があって、コクを感じる風味深いオリジナルブレンド。\n深い味わいをぜひお楽しみください^^'
    },
    mileage: {
      now_mileage: 301,
      plus_mileage: 21,
      rank_up: true
    },
    present: [
      {
        id: 10,
        name: '74ポイントプレゼント！',
        description: 'プレセント',
        warning: '注意事項',
        type: 2,
        amount: 74,
        coupon: 'coupon1',
        image_url: 'https://via.placeholder.com/640x480.png/008844?text=nihil'
      }
    ]
  }
];

export { data };
