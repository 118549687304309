import Repository from './Repository';
const resource = '/customer';

export default {
  /**
   * 顧客情報取得
   */
  getCustomer() {
    return Repository.get(`${resource}`);
  },

  /**
   * ECユーザー情報取得
   *
   * @returns {Promise<{ name: string; access_token: string; point: number; expire_at: number; }>}
   */
  getEcCustomer() {
    return Repository.get(`${resource}/ec`);
  },

  /**
   * ECユーザーログイン
   *
   * @param {{ id: string; password: string; }} payload
   * @returns {Promise<{ name: string; access_token: string; point: number; expire_at: number; }>}
   */
  loginEcCustomer(payload) {
    return Repository.post(`${resource}/ec`, payload);
  },

  /**
   * 引き継ぎコード取得
   */
  getTakeoverCode() {
    return Repository.get(`${resource}/take-over`);
  },

  /**
   * 引き継ぎコード使用
   *
   * @param {{ take_over_code: string; }} payload
   * @returns {Promise<{ access_token: string; token_type: string; expires_in: number; }>}
   */
  useTakeoverCode(payload) {
    return Repository.post(`${resource}/take-over`, payload);
  }
};
