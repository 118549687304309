var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.content
    ? _c(
        "ul",
        { staticClass: "pro-card" },
        [
          _vm._l(_vm.content, function (pro) {
            return _c(
              "li",
              { key: pro.index, staticClass: "pro-card-item" },
              [
                _c(
                  pro.id ? "a" : "div",
                  {
                    tag: "component",
                    staticClass: "pro-card-item-head",
                    attrs: {
                      href: pro.id
                        ? _vm.$customUrlScheme.page(
                            "detail",
                            "url=/pro/detail/" + pro.id
                          )
                        : false,
                    },
                    on: { click: _vm.onClick },
                  },
                  [
                    _c("div", { staticClass: "pro-card-item-head-thumbnail" }, [
                      _c(
                        "div",
                        { staticClass: "pro-card-item-head-thumbnail__inner" },
                        [
                          _c("ProAvatar", {
                            attrs: {
                              src: pro.image_url,
                              size: "64",
                              alt: pro.name,
                              "is-bordered": false,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "pro-card-item-head-profile" }, [
                      _c(
                        "header",
                        { staticClass: "pro-card-item-head-profile__head" },
                        [
                          _c(
                            "p",
                            {
                              staticClass: "pro-card-item-head-profile__title",
                            },
                            [_vm._v(" " + _vm._s(pro["name"]) + " ")]
                          ),
                          _c(
                            "span",
                            { staticClass: "pro-card-item-head-profile__sub" },
                            [
                              _vm._v(
                                " " + _vm._s(pro["name_alphabetical"]) + " "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "p",
                        {
                          staticClass:
                            "pro-card-item-head-profile__description",
                        },
                        [_vm._v(" " + _vm._s(pro["overview"]) + " ")]
                      ),
                    ]),
                  ]
                ),
              ],
              1
            )
          }),
          _vm.description
            ? _c("li", [
                _c("div", { staticClass: "pro-card-item-bottom" }, [
                  _c("div", { staticClass: "c-card c-card--beige03Bg" }, [
                    _c("p", { staticClass: "c-lead2 c-lead2--beige01" }, [
                      _vm._v(" " + _vm._s(_vm.description) + " "),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }