<template>
  <div class="c-balloon" :class="classes">
    <div><slot /></div>
  </div>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api';
export default defineComponent({
  props: {
    rounded: {
      type: Boolean,
      default: false
    },

    elevated: {
      type: Boolean,
      default: false
    },

    block: {
      type: Boolean,
      default: false
    },

    top: {
      type: Boolean,
      default: false
    },

    bottom: {
      type: Boolean,
      default: false
    },

    right: {
      type: Boolean,
      default: false
    },

    left: {
      type: Boolean,
      default: false
    },

    alignRight: {
      type: Boolean,
      default: false
    },

    alignLeft: {
      type: Boolean,
      default: false
    },

    alignCenter: {
      type: Boolean,
      default: false
    },

    bold: {
      type: Boolean,
      default: false
    },

    type: {
      // @values line
      type: String,
      default: null
    },

    size: {
      // @values md | lg
      type: String,
      default: 'md'
    }
  },

  setup: (props) => {
    const classes = computed(() => {
      return {
        'c-balloon--rounded': props.rounded,
        'c-balloon--elevated': props.elevated,
        'c-balloon--block': props.block,
        'c-balloon--top':
          props.top || (!props.bottom && !props.right && !props.left),

        'c-balloon--bottom': props.bottom,
        'c-balloon--right': props.right,
        'c-balloon--left': props.left,
        'c-balloon--align-right': props.alignRight,
        'c-balloon--align-left': props.alignLeft,
        'c-balloon--align-center': props.alignCenter,
        'c-balloon--bold': props.bold,
        'c-balloon--line': props.type === 'line',
        'c-balloon--lg': props.size === 'lg'
      };
    });

    return {
      classes
    };
  }
});
</script>
