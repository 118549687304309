var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "c-bottomSheet",
      attrs: { "data-open": _vm.state === "open" ? 1 : 0 },
    },
    [
      _c("div", {
        staticClass: "bg",
        style: _vm.stylesBg,
        on: {
          click: function () {
            return _vm.setState("half")
          },
        },
      }),
      _c(
        "div",
        {
          ref: "card",
          staticClass: "c-bottomSheet__inner",
          style: _vm.stylesCard,
          attrs: { "data-state": _vm.isMove ? "move" : _vm.state },
        },
        [
          _c(
            "div",
            {
              ref: "header",
              staticClass: "c-bottomSheet__header",
              class: { "c-bottomSheet__header--noShadow": _vm.noShadow },
            },
            [
              _c("div", { staticClass: "c-bottomSheet__panArea" }, [
                _c("div", {
                  ref: "bar",
                  staticClass: "c-bottomSheet__bar",
                  style: _vm.stylesHeader,
                }),
              ]),
              _vm.showCloseIcon
                ? _c("Icon", {
                    attrs: { name: "close" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.onClickCloseIcon.apply(null, arguments)
                      },
                    },
                  })
                : _vm._e(),
              _vm._t("header"),
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass: "c-bottomSheet__contents",
              style: _vm.stylesContents,
            },
            [_vm._t("default")],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }