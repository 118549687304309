<template>
  <component
    class="c-banner"
    :type="type"
    :is="wrapper"
    :href="hrefLink"
    :to="toLink"
    @click="!disabled && $emit('click', $event)"
    v-ripple="to || href ? $utilities.getRippleOptions('light') : false">
    <img :src="src" :alt="alt" />
  </component>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api';
export default defineComponent({
  props: {
    href: {
      type: [String, Boolean],
      default: ''
    },

    type: {
      type: [String, Boolean],
      default: ''
    },

    to: {
      type: String,
      default: ''
    },

    src: {
      type: String,
      default: ''
    },

    alt: {
      type: String,
      default: ''
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  setup: (props, context) => {
    const wrapper = computed(() => {
      if (props.href) {
        return 'a';
      } else if (props.to) {
        return 'router-link';
      } else if (props.type === 'button') {
        return 'button';
      }
      return 'div';
    });

    const hrefLink = computed(() =>
      !props.to && props.href
        ? context.root.$contentList.getLink(props.href)
        : false
    );
    const toLink = computed(() => (!props.href && props.to ? props.to : false));

    return {
      wrapper,
      hrefLink,
      toLink
    };
  }
});
</script>
