const mode = process.env.VUE_APP_MODE;
const origin = process.env.VUE_APP_ORIGIN;
const apiUrl = process.env.VUE_APP_API_URL;
const appName = process.env.VUE_APP_NAME;

export const APP_NAME = mode !== 'PRD' ? '[' + mode + '] ' + appName : appName;
export const ORIGIN = origin;
export const API_URL = apiUrl;

export const loggerCss = {
  request: 'color: #43a047;',
  response: 'color: #03a9f4;',
  put: 'color: #afb42b;',
  error: 'color: #ef5350;',
  warn: 'color: #ff9800;',
  lifecycle: 'color: #ff7043; font-weight: bold'
};
