import BaseInput from './BaseInput.vue';

export default {
  title: 'SHARED/BaseInput',
  component: BaseInput,
  argTypes: {
    value: {
      control: { type: 'text' }
    }
  }
};

const Template = (args, { argTypes }) => ({
  components: { BaseInput },
  props: Object.keys(argTypes),
  template: '<BaseInput v-bind="$props" />'
});

export const Default = Template.bind({});
Default.args = {
  value: '',
  placeholder: 'placeholder',
  label: 'LABEL',
  hint: 'HINT',
  required: true
};
