const data = {
  data: [
    {
      id: 1,
      created_at: '2023-02-08T15:00:00.000000Z',
      name: 'はちみつしょうがコーヒー',
      image_url:
        'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/dp-recipe/test09_m.png',
      capsule: {
        id: 4,
        name: 'モカ ＆ キリマンジァロ',
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/capsule/img_capsule-2.png',
        description:
          'アフリカで育った相性の良いコーヒー豆をブレンド。フルーティーでなめらかな味わいです。',
        map_position_id: 7,
        taste_parameter: {
          rich: 3,
          bitter: 2,
          acidity: 4
        },
        link_url: 'https://drip-pod.jp/products/417',
        order: 997,
        recommend: true,
        is_list_publishable: true,
        is_publishable: true,
        publish_start_at: '2023-01-12 00:00:00',
        publish_end_at: '2024-01-12 00:00:00',
        created_at: '2023-02-02T03:39:03.000000Z',
        updated_at: '2023-02-02T03:39:03.000000Z'
      },
      barista: {
        id: 8,
        name: '早川 契史',
        name_alphabetical: 'Hisashi Hayakawa',
        overview:
          '・UCCコーヒーアドバイザー・UCCコーヒー抽出士\n・(アメリカ)CQI認定Qアラビカグレーダー\n・(コロンビア)マイルドコーヒークオリティコントロールスペシャリスト',
        header: 1,
        short_message:
          '前職の経験を活かし、エスプレッソマシンからハンドドリップ、サイフォンなどお客様に合った機械、器具をご案内し、ワンランク上の美味しいコーヒーを楽しんでいただける様お手伝いいたします。',
        notice: '',
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/barista/instructor-7.jpg',
        is_publishable: true,
        created_at: '2023-02-02T03:38:53.000000Z',
        updated_at: '2023-02-02T03:38:53.000000Z'
      },
      recipe_id: 9,
      is_bookmark: true,
      is_publishable: true,
      publish_start_at: '2023-01-09 00:00:00',
      publish_end_at: '2024-01-09 00:00:00'
    },
    {
      id: 8,
      created_at: '2023-02-07T15:00:00.000000Z',
      name: 'カフェ・ド・コラーダ',
      image_url:
        'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/dp-recipe/test08_m.png',
      capsule: {
        id: 2,
        name: 'ハワイコナブレンド',
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/capsule/img_capsule-3.png',
        description:
          'トロピカルフルーツのような明るい酸味が特長。 南国の雰囲気を感じさせる、爽やかで透明感のある味わい。',
        map_position_id: 2,
        taste_parameter: {
          rich: 2,
          bitter: 2,
          acidity: 4
        },
        link_url: 'https://drip-pod.jp/products/1369',
        order: 999,
        recommend: false,
        is_list_publishable: true,
        is_publishable: true,
        publish_start_at: '2023-01-14 00:00:00',
        publish_end_at: null,
        created_at: '2023-02-02T03:39:03.000000Z',
        updated_at: '2023-02-02T03:39:03.000000Z'
      },
      barista: {
        id: 7,
        name: '幸田 貴枝',
        name_alphabetical: 'Takae Koda',
        overview:
          '・UCCコーヒーアドバイザー・(アメリカ)CQI認定Qアラビカグレーダー',
        header: 0,
        short_message:
          'コーヒーのある生活をより豊かなものにしてみませんか？\n好奇心をそそるプログラムをご用意しています。ぜひ一緒に学びましょう！',
        notice: '',
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/barista/instructor-6.jpg',
        is_publishable: true,
        created_at: '2023-02-02T03:38:53.000000Z',
        updated_at: '2023-02-02T03:38:53.000000Z'
      },
      recipe_id: 8,
      is_bookmark: true,
      is_publishable: true,
      publish_start_at: '2023-01-08 00:00:00',
      publish_end_at: '2024-01-08 00:00:00'
    },
    {
      id: 15,
      created_at: '2023-02-14T15:00:00.000000Z',
      name: 'ストロベリーオレ_サブタイトルが長い',
      image_url:
        'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/dp-recipe/test05_m.png',
      capsule: {
        id: 4,
        name: 'モカ ＆ キリマンジァロ',
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/capsule/img_capsule-2.png',
        description:
          'アフリカで育った相性の良いコーヒー豆をブレンド。フルーティーでなめらかな味わいです。',
        map_position_id: 7,
        taste_parameter: {
          rich: 3,
          bitter: 2,
          acidity: 4
        },
        link_url: 'https://drip-pod.jp/products/417',
        order: 997,
        recommend: true,
        is_list_publishable: true,
        is_publishable: true,
        publish_start_at: '2023-01-12 00:00:00',
        publish_end_at: '2024-01-12 00:00:00',
        created_at: '2023-02-02T03:39:03.000000Z',
        updated_at: '2023-02-02T03:39:03.000000Z'
      },
      barista: {
        id: 5,
        name: '香月 麻里',
        name_alphabetical: 'Mari Katsuki',
        overview:
          '・UCCコーヒーアドバイザー・UCCコーヒー鑑定士\n・(ブラジル)コーヒー鑑定士',
        header: 1,
        short_message: '新たなコーヒーの愉しみ方を見つけに来てください。',
        notice: '',
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/barista/instructor-4.jpg',
        is_publishable: true,
        created_at: '2023-02-02T03:38:53.000000Z',
        updated_at: '2023-02-02T03:38:53.000000Z'
      },
      recipe_id: 15,
      is_bookmark: true,
      is_publishable: true,
      publish_start_at: '2023-01-05 00:00:00',
      publish_end_at: '2024-01-05 00:00:00'
    },
    {
      id: 10,
      created_at: '2023-02-09T15:00:00.000000Z',
      name: '黒糖しるこ・ラテ',
      image_url:
        'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/dp-recipe/test10_m.png',
      capsule: null,
      barista: null,
      recipe_id: 10,
      is_bookmark: true,
      is_publishable: true,
      publish_start_at: '2023-01-10 00:00:00',
      publish_end_at: '2024-01-10 00:00:00'
    },
    {
      id: 14,
      created_at: '2023-02-13T15:00:00.000000Z',
      name: 'あんこコーヒー_タイトルが長いタイトルが長いタイトルが長いタイトルが長いタイトルが長い',
      image_url:
        'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/dp-recipe/test04_m.png',
      capsule: {
        id: 1,
        name: 'ブルーマウンテンブレンド',
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/capsule/img_capsule-4.png',
        description:
          '苦味・酸味・コクが見事に調和し、ブラウンシュガーのような上品な甘みが特長。コーヒーの王様の名にふさわしい、品格のある味わい。',
        map_position_id: 9,
        taste_parameter: {
          rich: 3,
          bitter: 3,
          acidity: 3
        },
        link_url: 'https://drip-pod.jp/products/1368',
        order: 1000,
        recommend: false,
        is_list_publishable: true,
        is_publishable: true,
        publish_start_at: '2023-01-15 00:00:00',
        publish_end_at: null,
        created_at: '2023-02-02T03:39:03.000000Z',
        updated_at: '2023-02-02T03:39:03.000000Z'
      },
      barista: {
        id: 4,
        name: '小山 勝宝',
        name_alphabetical: 'Katsutaka Koyama',
        overview:
          '・UCCコーヒーアドバイザー・UCCコーヒー鑑定士\n・(ブラジル)コーヒー鑑定士\n・(アメリカ)CQI認定Qアラビカグレーダー\n・(アメリカ)CQI認定Qロブスタグレーダー',
        header: 0,
        short_message:
          'コーヒーの「美味しさ」「楽しさ」を一緒になって体験しましょう。コーヒーをもっと好きになるお手伝いをさせて頂ければ幸いです。',
        notice: '',
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/barista/instructor-3.jpg',
        is_publishable: true,
        created_at: '2023-02-02T03:38:53.000000Z',
        updated_at: '2023-02-02T03:38:53.000000Z'
      },
      recipe_id: 14,
      is_bookmark: true,
      is_publishable: true,
      publish_start_at: '2023-01-04 00:00:00',
      publish_end_at: '2024-01-04 00:00:00'
    },
    {
      id: 13,
      created_at: '2023-02-12T15:00:00.000000Z',
      name: '氷コーヒー_公開期間後',
      image_url:
        'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/dp-recipe/test03_m.png',
      capsule: {
        id: 6,
        name: 'マンデリン & ブラジル',
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/capsule/img_capsule-0.png',
        description:
          'アジアと南米のコラボレーションブレンド。 ドライフルーツのような甘み、力強くコク深い味わい。',
        map_position_id: 23,
        taste_parameter: {
          rich: 4,
          bitter: 4,
          acidity: 3
        },
        link_url: 'https://drip-pod.jp/products/1373',
        order: 995,
        recommend: true,
        is_list_publishable: true,
        is_publishable: true,
        publish_start_at: '2023-01-10 00:00:00',
        publish_end_at: '2024-01-10 00:00:00',
        created_at: '2023-02-02T03:39:03.000000Z',
        updated_at: '2023-02-02T03:39:03.000000Z'
      },
      barista: {
        id: 3,
        name: '川口 雅也',
        name_alphabetical: 'Masaya Kawaguchi',
        overview:
          '・UCCコーヒーアドバイザー・UCCコーヒー抽出士\n・UCCコーヒー鑑定士\n・(ブラジル)コーヒー鑑定士\n・(アメリカ)CQI認定Qアラビカグレーダー',
        header: 2,
        short_message:
          'ハッピーな時間を過ごすには、コーヒーは欠かせません。おいしいコーヒーから生まれるスマイル。最高です！',
        notice: '',
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/barista/instructor-2.jpg',
        is_publishable: true,
        created_at: '2023-02-02T03:38:53.000000Z',
        updated_at: '2023-02-02T03:38:53.000000Z'
      },
      recipe_id: 13,
      is_bookmark: true,
      is_publishable: true,
      publish_start_at: '2022-01-03 00:00:00',
      publish_end_at: '2023-01-03 00:00:00'
    },
    {
      id: 16,
      created_at: '2023-02-15T15:00:00.000000Z',
      name: 'ベトナム風コーヒー_抽出工程が長い',
      image_url:
        'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/dp-recipe/test06_m.png',
      capsule: {
        id: 9,
        name: '炭焼珈琲',
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/capsule/img_capsule-10.png',
        description:
          '炭火で丹念に焙煎したスモーキーな香りとコク深く、甘い余韻のある味わい。',
        map_position_id: 11,
        taste_parameter: {
          rich: 4,
          bitter: 4,
          acidity: 2
        },
        link_url: 'https://drip-pod.jp/products/1374',
        order: 992,
        recommend: false,
        is_list_publishable: true,
        is_publishable: true,
        publish_start_at: '2023-01-07 00:00:00',
        publish_end_at: '2024-01-07 00:00:00',
        created_at: '2023-02-02T03:39:03.000000Z',
        updated_at: '2023-02-02T03:39:03.000000Z'
      },
      barista: null,
      recipe_id: 16,
      is_bookmark: true,
      is_publishable: true,
      publish_start_at: '2023-01-06 00:00:00',
      publish_end_at: '2024-01-06 00:00:00'
    },
    {
      id: 11,
      created_at: '2023-02-10T15:00:00.000000Z',
      name: 'レモンミントコーヒー_非公開',
      image_url:
        'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/dp-recipe/test01_m.png',
      capsule: {
        id: 12,
        name: 'アイスコーヒー',
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/capsule/img_capsule-7.png',
        description:
          'ダークチョコレートのような滑らかさとすっきりとしたコクのある味わい。 より良い地球環境、より良い生産者の生活に繋がるよう、サステナブルに調達されたコーヒー豆を50%以上使用しています。',
        map_position_id: 36,
        taste_parameter: {
          rich: 5,
          bitter: 5,
          acidity: 1
        },
        link_url: 'https://drip-pod.jp/products/1372',
        order: 989,
        recommend: false,
        is_list_publishable: true,
        is_publishable: true,
        publish_start_at: '2023-01-04 00:00:00',
        publish_end_at: '2024-01-04 00:00:00',
        created_at: '2023-02-02T03:39:03.000000Z',
        updated_at: '2023-02-02T03:39:03.000000Z'
      },
      barista: {
        id: 1,
        name: '栄 秀文',
        name_alphabetical: 'Hidefumi Sakae',
        overview:
          '・UCCコーヒーアドバイザー・(コロンビア)マイルドコーヒークオリティコントロールスペシャリスト',
        header: 0,
        short_message:
          'いつでもどこでも飲めるようになったコーヒー！\nその楽しさをもっとお伝えすることで受講される皆様にコーヒーを通じて笑顔になって頂きたいと講師一同願っております。\n是非、一緒に「GOOD COFFEE SMILE」になりましょう！',
        notice: '',
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/barista/president.jpg',
        is_publishable: true,
        created_at: '2023-02-02T03:38:53.000000Z',
        updated_at: '2023-02-02T03:38:53.000000Z'
      },
      recipe_id: 11,
      is_bookmark: true,
      is_publishable: false,
      publish_start_at: '2023-01-01 00:00:00',
      publish_end_at: '2024-01-01 00:00:00'
    }
  ],
  next_page_url: null,
  prev_page_url: null
};

export { data };
