import Repository from './Repository';
const resource = '/coffee-map';

export default {
  /**
   * コーヒーマップデータを取得
   */
  get() {
    return Repository.get(`${resource}`);
  },

  /**
   * おすすめコーヒー一覧を取得
   *
   * @param {string} [queryString] クエリ文字列 ?page=2
   */
  getRecommended(queryString = '') {
    return Repository.get(`${resource}/product/recommend${queryString}`);
  }
};
