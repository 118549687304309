import Icon from './Icon.vue';

export default {
  title: 'SHARED/Icon',
  component: Icon
};

const DEFAULT_ICON_SIZE = 48;

const Template = (args, { argTypes }) => ({
  components: { Icon },
  props: Object.keys(argTypes),
  template: '<Icon v-bind="$props" />'
});

export const Default = Template.bind({});

export const Primary = Template.bind({});
Primary.args = {
  name: 'faceGood',
  color: 'primary01',
  width: DEFAULT_ICON_SIZE,
  height: DEFAULT_ICON_SIZE
};

export const Secondary = Template.bind({});
Secondary.args = {
  name: 'bell',
  color: 'secondary01',
  width: DEFAULT_ICON_SIZE,
  height: DEFAULT_ICON_SIZE
};

export const Accent01 = Template.bind({});
Accent01.args = {
  name: 'mileOff',
  color: 'accent01',
  width: DEFAULT_ICON_SIZE,
  height: DEFAULT_ICON_SIZE
};

export const Accent02 = Template.bind({});
Accent02.args = {
  name: 'checkCircleBordered',
  color: 'accent02',
  width: DEFAULT_ICON_SIZE,
  height: DEFAULT_ICON_SIZE
};
