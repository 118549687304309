<template>
  <div class="c-baseInput c-form__item">
    <FormLabel :label="label" :hint="hint" :required="required" />
    <ValidationProvider v-bind="veeProps" v-slot="{ errors, failed }">
      <input
        :type="type"
        :value="value"
        v-bind="$attrs"
        class="c-baseInput__control c-formControl"
        :class="{ 'has-error': failed }"
        @input="$emit('input', $event.target.value)" />
      <div v-if="errors[0]" class="c-errorMessage">{{ errors[0] }}</div>
    </ValidationProvider>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: ''
    },

    type: {
      type: String,
      default: 'text'
    },

    required: {
      type: Boolean,
      default: false
    },

    label: {
      type: String,
      default: ''
    },

    hint: {
      type: String,
      default: ''
    },

    veeProps: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>
