import utilities from './utilities';
import repositories from './repositories';
import log from './log';
import icons from './icons';
import { customUrlScheme } from './customUrlScheme';
import { systemDialog } from './customUrlSchemeDialog/systemDialog';
import mileDialog from './customUrlSchemeDialog/mileDialog';
import presentDialog from './customUrlSchemeDialog/presentDialog';
import onlineStoreDialog from './customUrlSchemeDialog/onlineStoreDialog';
import wordings from './wordings';
import vueScrollTo from './vue-scrollto';
import './vee-validate';
import contentTabOrder from './contentTabOrder';
import contentList from './contentList';

Number.prototype.format = function () {
  return new Intl.NumberFormat().format(this);
};

export default [
  utilities,
  repositories,
  log,
  icons,
  customUrlScheme,
  systemDialog,
  mileDialog,
  presentDialog,
  onlineStoreDialog,
  wordings,
  vueScrollTo,
  contentTabOrder,
  contentList
];
