export class ProRecipeTasteType {
  static MILD_AND_ACIDITY = 'A'; // マイルド&酸味系
  static MILD_AND_BITTER = 'B'; // マイルド&苦味系
  static RICH_AND_ACIDITY = 'C'; // コク&酸味系
  static RICH_AND_BITTER = 'D'; // コク&苦味系
  static BALANCE = 'E'; // バランス系
  static TEA = 'F'; // 紅茶
  static GREEN_TEA = 'G'; // お茶

  /**
   * タイプIDからラベルを取得する
   * @param {number} type
   * @returns {string | undefined}
   */
  static getTypeLabel = (type) => {
    switch (type) {
      case this.MILD_AND_ACIDITY:
        return 'マイルド&酸味系';
      case this.MILD_AND_BITTER:
        return 'マイルド&苦味系';
      case this.RICH_AND_ACIDITY:
        return 'コク&酸味系';
      case this.RICH_AND_BITTER:
        return 'コク&苦味系';
      case this.BALANCE:
        return 'バランス系';
      case this.TEA:
        return '紅茶';
      case this.GREEN_TEA:
        return 'お茶';
    }
  };

  /**
   * タイプIDから英語のラベルを取得する
   * @param {number} type
   * @returns {string | undefined}
   */
  static getTypeLabelEn = (type) => {
    switch (type) {
      case this.MILD_AND_ACIDITY:
        return 'LIGHT & FRUITY';
      case this.MILD_AND_BITTER:
        return 'LIGHT & BITTER';
      case this.RICH_AND_ACIDITY:
        return 'STRONG & FRUITY';
      case this.RICH_AND_BITTER:
        return 'STRONG & BITTER';
      case this.BALANCE:
        return 'BALANCE';
      case this.TEA:
        return 'TEA';
      case this.GREEN_TEA:
        return 'GREEN TEA';
    }
  };

  /**
   * タイプIDからタイプ名を取得する
   * @param {number} type
   * @returns {string | undefined}
   */
  static getTypeName = (type) => {
    switch (type) {
      case this.MILD_AND_BITTER:
        return 'mild-and-bitter';
      case this.MILD_AND_ACIDITY:
        return 'mild-and-acidity';
      case this.RICH_AND_BITTER:
        return 'rich-and-bitter';
      case this.RICH_AND_ACIDITY:
        return 'rich-and-acidity';
      case this.BALANCE:
        return 'balance';
      case this.TEA:
        return 'tea';
      case this.GREEN_TEA:
        return 'green-tea';
    }
  };
}
