import Repository from './Repository';
const resource = '/content/event';

export default {
  get(queryString = '') {
    return Repository.get(`${resource}${queryString}`);
  },
  getEvent(eventId) {
    return Repository.get(`${resource}/${eventId}`);
  }
};
