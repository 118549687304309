import Repository from './Repository';
const resource = '/barista';

export default {
  getPro(id) {
    return Repository.get(`${resource}/${id}`);
  },

  favoritePro(id, params) {
    return Repository.post(`${resource}/${id}`, params);
  }
};
