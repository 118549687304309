const response = {
  id: 't.sonic@sonicmoov.com',
  l_name: 'ソニック',
  f_name: '太郎',
  rank: 'ステージ4',
  access_token: 'EC_ACCESS_TOKEN',
  point: 3400,
  expire_at: 1627298048,
  mileage: {
    now_mileage: 301,
    plus_mileage: 21,
    rank_up: true
  },
  present: [
    {
      id: 10,
      name: '74ポイントプレゼント！',
      description: 'プレセント',
      warning: '注意事項',
      type: 2,
      amount: 74,
      coupon: 'coupon1',
      image_url: 'https://via.placeholder.com/640x480.png/008844?text=nihil'
    }
  ]
};

export default {
  get() {
    return [200, response];

    // error: EC未連携時
    // return [500, {}];

    // error: タイムアウト
    // return [408, { message: 'タイムアウトしました' }];
  },

  post() {
    return [200, response];
  }
};
