import { dialog } from '@/plugins/customUrlScheme';

/**
 * マイル取得のダイアログを表示するカスタムURLスキームのパスを取得
 * @param {number} mile マイル数
 * @param {number} isStageUp ステージアップフラグ 0: false, 1: true
 * @return {string} /dialog/mile/?plus_mileage={獲得マイル数}/?rank_up={ステージアップフラグ}
 * @example
 * $mileDialog(100, 0)
 */
export default (_, inject) => {
  const message = (mile, isStageUp = 0) => {
    const params = encodeURI(`?plus_mileage=${mile}&rank_up=${isStageUp}`);
    return dialog('mile', params);
  };
  inject('mileDialog', message);
};
