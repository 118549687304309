import axios from 'axios';
import mock from './mocks/$mock.js';
import log from 'loglevel';
import { API_URL, loggerCss } from '@/app.config.js';
import { useEnvironment } from '@/composables/useEnvironment';
const { isLocal } = useEnvironment();
import { useNativeConnection } from '@/composables/useNativeConnection';

const BASE_URL = API_URL;
const DEFAULT_TIMEOUT_TIME = 15000;
const DEFAULT_MOCK_DELAY_TIME = 1000;

const { getAccessToken } = useNativeConnection();

const repository = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  timeout: DEFAULT_TIMEOUT_TIME
});

/* eslint-disable no-console */
repository.interceptors.request.use(async (request) => {
  // 認証ヘッダーの付与
  const { access_token: accessToken } = await getAccessToken();
  request.headers.common.Authorization = `Bearer ${accessToken}`;

  console.debug('%c Request method :>> ', loggerCss.request, request.method);
  console.debug('%c Request url :>> ', loggerCss.request, request.url);
  console.debug('%c Request headers :>> ', loggerCss.request, request.headers);
  if (request.data instanceof FormData) {
    console.debug(
      '%c Request data :>> ',
      loggerCss.request,
      ...request.data.entries()
    );
  } else {
    console.debug('%c Request data :>> ', loggerCss.request, request.data);
  }

  return request;
});

repository.interceptors.response.use(
  (response) => {
    console.debug('%c Response data :>> ', loggerCss.response, response.data);
    return response;
  },
  (error) => {
    log.error(
      '%c Error :>> ',
      loggerCss.error,
      error.response?.status,
      error.toJSON()
    );
    return Promise.reject(error);
  }
);
/* eslint-enable no-console */

if (isLocal)
  // mock化
  mock(repository).setDelayTime(DEFAULT_MOCK_DELAY_TIME).enableLog();

export default repository;
