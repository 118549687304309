import Spinner from './Spinner.vue';
import { colors } from '@/composables/useColors';

export default {
  title: 'SHARED/Spinner',
  component: Spinner,
  argTypes: {
    color: {
      options: Object.keys(colors.common),
      control: { type: 'select' }
    }
  },
  parameters: {
    type: {
      chromatic: { disableSnapshot: true }
    }
  }
};

const Template = (args, { argTypes }) => ({
  components: { Spinner },
  props: Object.keys(argTypes),
  template: '<Spinner v-bind="$props" />'
});

export const Default = Template.bind({});
Default.args = {
  size: '2em',
  color: 'grey01'
};
