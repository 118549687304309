import { data } from './data/data';

export default {
  get({ values }) {
    const response = data.find((item) => item.id === values.id) || data[0];
    return [200, response];

    // error: タイムアウト
    // return [408, { message: 'タイムアウトしました' }];
  }
};
