import Vue from 'vue';
import App from '@/App.vue';
import VueRouter from 'vue-router';
import VueCompositionAPI from '@vue/composition-api';
import VWave from 'v-wave';
import VueNumberAnimation from 'vue-number-animation';
import VConsole from 'vconsole';
import { useEnvironment } from '@/composables/useEnvironment';
const { isDebug } = useEnvironment();
import { initGtm } from '@/plugins/vue-gtm-support';
import { initSentry } from '@/plugins/sentry';

import { ROUTES } from '@/router.config';
import { APP_NAME } from '@/app.config.js';

import plugins from '@/plugins';
import store from '@/store';
import '@/mixins/loading';

// SCSS
import variables from '@/assets/scss/_variables.scss';
import '@/assets/scss/foundation.scss';
import '@/assets/scss/layout.scss';
import '@/assets/scss/object.scss';

Vue.config.productionTip = false;

// Plugins
Vue.use(VueCompositionAPI);
Vue.use(VWave, {
  directive: 'ripple',
  color: variables['common-grey04'],
  initialOpacity: 0.28,
  easing: 'ease-in'
});

// Vue router
Vue.use(VueRouter);
const router = new VueRouter({
  mode: 'history',
  linkActiveClass: 'is-active',
  scrollBehavior: () => ({
    x: 0,
    y: 0
  }),
  routes: ROUTES
});
router.afterEach((to) => {
  const title = to.meta.title || APP_NAME;
  if (document.title) {
    document.title = title;
  }

  // Native側のタイトル表示用（通常のtitle属性は利用しない）
  document.head.querySelector(
    'meta[name="coffee-creation-app:title"]'
  ).content = title;

  document.head.querySelector(
    'meta[name="coffee-creation-app:coffee-free-link"]'
  ).content = '';
});

const context = {
  router,
  store
};

// plugins/index.js
function inject(name, value) {
  const prop = '$' + name.replace(/^\$+/g, '');
  Vue.prototype[prop] = Vue.observable(value);
  context[prop] = Vue.prototype[prop];
}
for (const plugin of plugins) {
  Vue.use({
    install() {
      plugin(context, inject);
    }
  });
}

// Directive
Vue.use(VueNumberAnimation);

// Global components
const requireComponent = require.context('./components/shared', false, /.*/);
requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);
  const componentName = fileName
    .split('/')
    .pop()
    .replace(/\.\w+$/, '');

  Vue.component(componentName, componentConfig.default || componentConfig);
});

if (isDebug) {
  const x = window.innerWidth - 93 - 8;
  const vConsole = new VConsole({
    theme: 'dark',
    onReady: () => {
      vConsole.setSwitchPosition(x, 20);
    }
  });

  initSentry(Vue, router);
}

initGtm(router);

new Vue({
  router,
  store,
  data: () => ({
    /**
     * アプリケーション全体のローディング状態を管理。
     * 更新や状態の取得は mixin 経由で行う
     *
     * @see {@link @/mixins/loading.js}
     */
    isLoading: false
  }),

  render: (h) => h(App)
}).$mount('#app');
