var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-headImage" }, [
    _c("div", { staticClass: "c-headImage__inner" }, [
      _c(
        "div",
        {
          staticClass: "c-headImage__bg",
          class: { "c-headImage__bg--contain": _vm.contain },
        },
        [_c("img", { attrs: { src: _vm.src, alt: "" } })]
      ),
      _vm.recentAction
        ? _c("div", { staticClass: "c-headImage__rate" }, [
            _c(
              "div",
              { staticClass: "c-headImage__rate__face" },
              [
                _c("Icon", {
                  attrs: {
                    name: _vm.recentAction.icon,
                    color: _vm.recentAction.color,
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "c-headImage__rate__content" }, [
              _c("p", { staticClass: "c-headImage__rate__message" }, [
                _vm._v(" " + _vm._s(_vm.recentAction.label) + " "),
              ]),
              _c("p", { staticClass: "c-headImage__rate__date" }, [
                _vm._v(
                  " " + _vm._s(_vm.$utilities.localizeDate(_vm.action.at)) + " "
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.mapZone
        ? _c(
            "div",
            { staticClass: "c-headImage__map" },
            [
              _c("Icon", {
                attrs: { name: "coffeeMap" + _vm.mapZone, color: "white01" },
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }