import Repository from './Repository';
const resource = '/mileage';

export default {
  /**
   * @param {string} [queryString] クエリ文字列 ?page=2
   */
  getRewards(queryString = '') {
    return Repository.get(`${resource}/reward${queryString}`);
  },

  /**
   * @param {string} [queryString] クエリ文字列 ?page=2
   */
  getHistories(queryString = '') {
    return Repository.get(`${resource}/history${queryString}`);
  },

  getProgressRank(lastMileValue = 0) {
    return Repository.post(`${resource}/progress-rank`, lastMileValue);
  }
};
