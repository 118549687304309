import { data } from './data/data';

export default {
  get({ values }) {
    return [200, data.data.find((event) => event.id === values.eventId)];
  },

  post() {
    return [200, {}];
  },

  put() {
    return [200, {}];
  }
};
