import Icons from './Icons.vue';

export default {
  title: 'SHARED/Icons',
  component: Icons,
  parameters: {
    chromatic: { disableSnapshot: true }
  }
};

export const List = () => ({
  components: { Icons },
  template: '<Icons />'
});
