var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "c-chip", class: _vm.classes }, [
    _c(
      "span",
      { staticClass: "c-chip__content" },
      [
        _vm._t("default", function () {
          return [_vm._v("Chip Component")]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }