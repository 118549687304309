import FormLabel from './FormLabel.vue';

export default {
  title: 'SHARED/FormLabel',
  component: FormLabel
};

const Template = (args, { argTypes }) => ({
  components: { FormLabel },
  props: Object.keys(argTypes),
  template: '<FormLabel v-bind="$props" />'
});

export const Default = Template.bind({});
Default.args = {
  required: true,
  label: 'LABEL',
  hint: 'HINT'
};
