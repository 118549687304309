import { data } from './data/recipes';

export default {
  get() {
    return [200, data];

    // error: タイムアウト
    // return [408, { message: 'タイムアウトしました' }];
  },

  post() {
    return [200, data];

    // error: タイムアウト
    // return [408, { message: 'タイムアウトしました' }];
  }
};
