export const data = {
  data: [
    {
      id: 1,
      image_url:
        'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/movie/9QZdUOtJfQE8tMuYFcjFpaC6Dy9NU8syGgiGWdWT.png',
      title: 'テスト',
      movie_url: 'https://youtu.be/OsAmtYGAoMk',
      description: '説明',
      baristas: [
        {
          id: '1',
          name: '中井千香子',
          image_url: 'https://placehold.jp/4bb/fff/640x200.png?text=VIDEO',
          name_alphabetical: 'Chikako Nakai',
          overview: '概要'
        }
      ]
    },
    {
      id: 2,
      image_url:
        'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/movie/1BXasheHocF220Hff005AUIaPcinINVcVxPvDjJP.png',
      title: 'テストtototoo',
      movie_url: 'https://youtu.be/NehwsYUBRQQ',
      description: '説明mememmemme',
      baristas: [
        {
          id: '1',
          name: '中井千香子',
          image_url: 'https://placehold.jp/4bb/fff/640x200.png?text=VIDEO',
          name_alphabetical: 'Chikako Nakai',
          overview:
            '概要概要概要概要概要概要概要概要概要概要概要概要概要概要概要'
        },
        {
          id: '2',
          name: '中井千香子',
          image_url: 'https://placehold.jp/4bb/fff/640x200.png?text=VIDEO',
          name_alphabetical: 'Chikako Nakai',
          overview:
            '概要\n概要概要\n概要概要概要\n概要概要概要概要概要概要概要概要概要'
        }
      ]
    }
  ]
};
