/**
 * コンテンツ(トップ)管理で使用するリンクカードタイプの定数
 *
 * @see https://bitbucket.org/sonicmoov/ucc_coffee_creation_api/src/develop/app/Enums/Content/LinkCardType.php
 */
export class LinkCardType {
  static BANNERLINK = 1; // バナーリンク
  static BIGTHUMBNAIL = 2; // 大サムネイル
  static SMALLTHUMBNAILV = 3; // 小サムネイル縦
  static SMALLTHUMBNAILH = 4; // 小サムネイル横

  /**
   * タイプIDからタイプ名を取得する
   * @param {number} type
   * @returns {string | undefined}
   */
  static getTypeName = (type) => {
    switch (type) {
      case this.BANNERLINK:
        return 'バナーリンク';
      case this.BIGTHUMBNAIL:
        return '大サムネイル';
      case this.SMALLTHUMBNAILV:
        return '小サムネイル縦';
      case this.SMALLTHUMBNAILH:
        return '小サムネイル横';
    }
  };
}
