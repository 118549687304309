export const data = [
  {
    id: 1,
    category_type: 'お知らせ',
    title: 'セールのお知らせ',
    image_url: 'https://example.com/image.jpg',
    body: 'お知らせ本文お知らせ本文お知らせ本文お知らせ本文',
    is_published: 0,
    publish_start_at: '1627298048',
    publish_end_at: '1627298048',
    created_at: '1627298048',
    updated_at: '1627298048',
    read: true
  },
  {
    id: 123,
    category_type: 'お知らせお知らせ',
    title: 'セールのお知らせセールのお知らせ',
    image_url: 'https://example.com/image.jpg',
    body: 'お知らせ本文お知らせ本文お知らせ本文お知らせ本文お知らせ本文お知らせ本文お知らせ本文お知らせ本文',
    is_published: 1,
    publish_start_at: '1627298048',
    publish_end_at: '1627298048',
    created_at: '1627298048',
    updated_at: '1627298048',
    read: true
  }
];
