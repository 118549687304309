const data = {
  recipes: [
    {
      id: 1,
      name: 'レモンミントコーヒー',
      appeal_word:
        'いつものアイスコーヒーにレモンとミントでさっぱりとした飲み口をお楽しみいただけます。',
      image_url:
        'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/dp-recipe/test01_m.png',
      is_publishable: true,
      publish_start_at: '2023-01-01 00:00:00',
      publish_end_at: '2023-01-01 00:00:00',
      capsule: {
        id: 12,
        name: 'アイスコーヒー',
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/capsule/img_capsule-7.png',
        description:
          'ダークチョコレートのような滑らかさとすっきりとしたコクのある味わい。 より良い地球環境、より良い生産者の生活に繋がるよう、サステナブルに調達されたコーヒー豆を50%以上使用しています。',
        map_position_id: 36,
        taste_parameter: {
          rich: 5,
          bitter: 5,
          acidity: 1
        },
        link_url: 'https://drip-pod.jp/products/1372',
        order: 989,
        recommend: false,
        is_list_publishable: true,
        is_publishable: true,
        publish_start_at: '2023-01-04 00:00:00',
        publish_end_at: '2024-01-04 00:00:00',
        created_at: '2023-02-02T03:39:03.000000Z',
        updated_at: '2023-02-02T03:39:03.000000Z'
      },
      barista: {
        id: 1,
        name: '栄 秀文',
        name_alphabetical: 'Hidefumi Sakae',
        overview:
          '・UCCコーヒーアドバイザー・(コロンビア)マイルドコーヒークオリティコントロールスペシャリスト',
        header: 0,
        short_message:
          'いつでもどこでも飲めるようになったコーヒー！\nその楽しさをもっとお伝えすることで受講される皆様にコーヒーを通じて笑顔になって頂きたいと講師一同願っております。\n是非、一緒に「GOOD COFFEE SMILE」になりましょう！',
        notice: '',
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/barista/president.jpg',
        is_publishable: true,
        created_at: '2023-02-02T03:38:53.000000Z',
        updated_at: '2023-02-02T03:38:53.000000Z'
      },
      tag: 'A'
    },
    {
      id: 2,
      name: 'アーモンドオレ',
      appeal_word:
        'いつものカフェオレの牛乳をアーモンドミルクに変えるだけでいつもと違った味わいに♪',
      image_url:
        'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/dp-recipe/test02_m.png',
      is_publishable: true,
      publish_start_at: '2023-01-02 00:00:00',
      publish_end_at: '2024-01-02 00:00:00',
      capsule: {
        id: 10,
        name: '鑑定士の誇り スペシャルブレンド',
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/capsule/img_capsule-8.png',
        description:
          'なじみがよく、メープルシロップのような甘みのある、まろやかな味わい。より良い地球環境、より良い生産者の生活に繋がるよう、サステナブルに調達されたコーヒー豆を50%以上使用しています。',
        map_position_id: 4,
        taste_parameter: {
          rich: 2,
          bitter: 3,
          acidity: 3
        },
        link_url: 'https://drip-pod.jp/products/1370',
        order: 991,
        recommend: false,
        is_list_publishable: true,
        is_publishable: true,
        publish_start_at: '2023-01-06 00:00:00',
        publish_end_at: '2024-01-06 00:00:00',
        created_at: '2023-02-02T03:39:03.000000Z',
        updated_at: '2023-02-02T03:39:03.000000Z'
      },
      barista: {
        id: 2,
        name: '藤井 昭彦',
        name_alphabetical: 'Teruhiko Fujii',
        overview:
          '・UCCコーヒーアドバイザー・UCCコーヒー鑑定士\n・(ブラジル)コーヒー鑑定士\n・UCCコーヒー抽出士\n・(アメリカ)CQI認定Qアラビカグレーダー\n・(コロンビア)マイルドコーヒークオリティコントロールスペシャリスト',
        header: 1,
        short_message:
          'コーヒーのおいしさと楽しみを知って一緒に笑顔になりませんか？',
        notice: '',
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/barista/instructor-1.jpg',
        is_publishable: true,
        created_at: '2023-02-02T03:38:53.000000Z',
        updated_at: '2023-02-02T03:38:53.000000Z'
      },
      tag: 'B'
    },
    {
      id: 4,
      name: 'あんこコーヒー',
      appeal_word: '粒あんの甘さがクセになるおいしさです。',
      image_url:
        'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/dp-recipe/test04_m.png',
      is_publishable: true,
      publish_start_at: '2023-01-04 00:00:00',
      publish_end_at: '2024-01-04 00:00:00',
      capsule: {
        id: 1,
        name: 'ブルーマウンテンブレンド',
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/capsule/img_capsule-4.png',
        description:
          '苦味・酸味・コクが見事に調和し、ブラウンシュガーのような上品な甘みが特長。コーヒーの王様の名にふさわしい、品格のある味わい。',
        map_position_id: 9,
        taste_parameter: {
          rich: 3,
          bitter: 3,
          acidity: 3
        },
        link_url: 'https://drip-pod.jp/products/1368',
        order: 1000,
        recommend: false,
        is_list_publishable: true,
        is_publishable: true,
        publish_start_at: '2023-01-15 00:00:00',
        publish_end_at: null,
        created_at: '2023-02-02T03:39:03.000000Z',
        updated_at: '2023-02-02T03:39:03.000000Z'
      },
      barista: {
        id: 4,
        name: '小山 勝宝',
        name_alphabetical: 'Katsutaka Koyama',
        overview:
          '・UCCコーヒーアドバイザー・UCCコーヒー鑑定士\n・(ブラジル)コーヒー鑑定士\n・(アメリカ)CQI認定Qアラビカグレーダー\n・(アメリカ)CQI認定Qロブスタグレーダー',
        header: 0,
        short_message:
          'コーヒーの「美味しさ」「楽しさ」を一緒になって体験しましょう。コーヒーをもっと好きになるお手伝いをさせて頂ければ幸いです。',
        notice: '',
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/barista/instructor-3.jpg',
        is_publishable: true,
        created_at: '2023-02-02T03:38:53.000000Z',
        updated_at: '2023-02-02T03:38:53.000000Z'
      },
      tag: 'C'
    },
    {
      id: 3,
      name: '氷コーヒー',
      appeal_word: '濃いめに抽出したコーヒーで氷にすると最後までおいしく。',
      image_url:
        'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/dp-recipe/test03_m.png',
      is_publishable: true,
      publish_start_at: '2023-01-03 00:00:00',
      publish_end_at: '2024-01-03 00:00:00',
      capsule: {
        id: 6,
        name: 'マンデリン & ブラジル',
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/capsule/img_capsule-0.png',
        description:
          'アジアと南米のコラボレーションブレンド。 ドライフルーツのような甘み、力強くコク深い味わい。',
        map_position_id: 23,
        taste_parameter: {
          rich: 4,
          bitter: 4,
          acidity: 3
        },
        link_url: 'https://drip-pod.jp/products/1373',
        order: 995,
        recommend: true,
        is_list_publishable: true,
        is_publishable: true,
        publish_start_at: '2023-01-10 00:00:00',
        publish_end_at: '2024-01-10 00:00:00',
        created_at: '2023-02-02T03:39:03.000000Z',
        updated_at: '2023-02-02T03:39:03.000000Z'
      },
      barista: {
        id: 3,
        name: '川口 雅也',
        name_alphabetical: 'Masaya Kawaguchi',
        overview:
          '・UCCコーヒーアドバイザー・UCCコーヒー抽出士\n・UCCコーヒー鑑定士\n・(ブラジル)コーヒー鑑定士\n・(アメリカ)CQI認定Qアラビカグレーダー',
        header: 2,
        short_message:
          'ハッピーな時間を過ごすには、コーヒーは欠かせません。おいしいコーヒーから生まれるスマイル。最高です！',
        notice: '',
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/barista/instructor-2.jpg',
        is_publishable: true,
        created_at: '2023-02-02T03:38:53.000000Z',
        updated_at: '2023-02-02T03:38:53.000000Z'
      },
      tag: 'C'
    }
  ],
  bookmarks: [
    {
      id: 16,
      created_at: '2023-02-15T15:00:00.000000Z',
      name: 'ベトナム風コーヒー_抽出工程が長い',
      image_url:
        'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/dp-recipe/test06_m.png',
      capsule: {
        id: 9,
        name: '炭焼珈琲',
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/capsule/img_capsule-10.png',
        description:
          '炭火で丹念に焙煎したスモーキーな香りとコク深く、甘い余韻のある味わい。',
        map_position_id: 11,
        taste_parameter: {
          rich: 4,
          bitter: 4,
          acidity: 2
        },
        link_url: 'https://drip-pod.jp/products/1374',
        order: 992,
        recommend: false,
        is_list_publishable: true,
        is_publishable: true,
        publish_start_at: '2023-01-07 00:00:00',
        publish_end_at: '2024-01-07 00:00:00',
        created_at: '2023-02-02T03:39:03.000000Z',
        updated_at: '2023-02-02T03:39:03.000000Z'
      },
      barista: null,
      recipe_id: 16,
      is_bookmark: true,
      is_publishable: true,
      publish_start_at: '2023-01-06 00:00:00',
      publish_end_at: '2024-01-06 00:00:00'
    },
    {
      id: 15,
      created_at: '2023-02-14T15:00:00.000000Z',
      name: 'ストロベリーオレ_サブタイトルが長い',
      image_url:
        'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/dp-recipe/test05_m.png',
      capsule: {
        id: 4,
        name: 'モカ ＆ キリマンジァロ',
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/capsule/img_capsule-2.png',
        description:
          'アフリカで育った相性の良いコーヒー豆をブレンド。フルーティーでなめらかな味わいです。',
        map_position_id: 7,
        taste_parameter: {
          rich: 3,
          bitter: 2,
          acidity: 4
        },
        link_url: 'https://drip-pod.jp/products/417',
        order: 997,
        recommend: true,
        is_list_publishable: true,
        is_publishable: true,
        publish_start_at: '2023-01-12 00:00:00',
        publish_end_at: '2024-01-12 00:00:00',
        created_at: '2023-02-02T03:39:03.000000Z',
        updated_at: '2023-02-02T03:39:03.000000Z'
      },
      barista: {
        id: 5,
        name: '香月 麻里',
        name_alphabetical: 'Mari Katsuki',
        overview:
          '・UCCコーヒーアドバイザー・UCCコーヒー鑑定士\n・(ブラジル)コーヒー鑑定士',
        header: 1,
        short_message: '新たなコーヒーの愉しみ方を見つけに来てください。',
        notice: '',
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/barista/instructor-4.jpg',
        is_publishable: true,
        created_at: '2023-02-02T03:38:53.000000Z',
        updated_at: '2023-02-02T03:38:53.000000Z'
      },
      recipe_id: 15,
      is_bookmark: true,
      is_publishable: true,
      publish_start_at: '2023-01-05 00:00:00',
      publish_end_at: '2024-01-05 00:00:00'
    },
    {
      id: 13,
      created_at: '2023-02-12T15:00:00.000000Z',
      name: '氷コーヒー_公開期間後',
      image_url:
        'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/dp-recipe/test03_m.png',
      capsule: {
        id: 6,
        name: 'マンデリン & ブラジル',
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/capsule/img_capsule-0.png',
        description:
          'アジアと南米のコラボレーションブレンド。 ドライフルーツのような甘み、力強くコク深い味わい。',
        map_position_id: 23,
        taste_parameter: {
          rich: 4,
          bitter: 4,
          acidity: 3
        },
        link_url: 'https://drip-pod.jp/products/1373',
        order: 995,
        recommend: true,
        is_list_publishable: true,
        is_publishable: true,
        publish_start_at: '2023-01-10 00:00:00',
        publish_end_at: '2024-01-10 00:00:00',
        created_at: '2023-02-02T03:39:03.000000Z',
        updated_at: '2023-02-02T03:39:03.000000Z'
      },
      barista: {
        id: 3,
        name: '川口 雅也',
        name_alphabetical: 'Masaya Kawaguchi',
        overview:
          '・UCCコーヒーアドバイザー・UCCコーヒー抽出士\n・UCCコーヒー鑑定士\n・(ブラジル)コーヒー鑑定士\n・(アメリカ)CQI認定Qアラビカグレーダー',
        header: 2,
        short_message:
          'ハッピーな時間を過ごすには、コーヒーは欠かせません。おいしいコーヒーから生まれるスマイル。最高です！',
        notice: '',
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/barista/instructor-2.jpg',
        is_publishable: true,
        created_at: '2023-02-02T03:38:53.000000Z',
        updated_at: '2023-02-02T03:38:53.000000Z'
      },
      recipe_id: 13,
      is_bookmark: true,
      is_publishable: true,
      publish_start_at: '2022-01-03 00:00:00',
      publish_end_at: '2023-01-03 00:00:00'
    }
  ],
  histories: [
    {
      id: 16,
      created_at: '2023-02-15T15:00:00.000000Z',
      name: 'ベトナム風コーヒー_抽出工程が長い',
      image_url:
        'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/dp-recipe/test06_m.png',
      capsule: {
        id: 9,
        name: '炭焼珈琲',
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/capsule/img_capsule-10.png',
        description:
          '炭火で丹念に焙煎したスモーキーな香りとコク深く、甘い余韻のある味わい。',
        map_position_id: 11,
        taste_parameter: {
          rich: 4,
          bitter: 4,
          acidity: 2
        },
        link_url: 'https://drip-pod.jp/products/1374',
        order: 992,
        recommend: false,
        is_list_publishable: true,
        is_publishable: true,
        publish_start_at: '2023-01-07 00:00:00',
        publish_end_at: '2024-01-07 00:00:00',
        created_at: '2023-02-02T03:39:03.000000Z',
        updated_at: '2023-02-02T03:39:03.000000Z'
      },
      barista: null,
      recipe_id: 16,
      is_bookmark: true,
      is_publishable: true,
      publish_start_at: '2023-01-06 00:00:00',
      publish_end_at: '2023-01-06 00:00:00'
    },
    {
      id: 15,
      created_at: '2023-02-14T15:00:00.000000Z',
      name: 'ストロベリーオレ_サブタイトルが長い',
      image_url:
        'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/dp-recipe/test05_m.png',
      capsule: {
        id: 4,
        name: 'モカ ＆ キリマンジァロ',
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/capsule/img_capsule-2.png',
        description:
          'アフリカで育った相性の良いコーヒー豆をブレンド。フルーティーでなめらかな味わいです。',
        map_position_id: 7,
        taste_parameter: {
          rich: 3,
          bitter: 2,
          acidity: 4
        },
        link_url: 'https://drip-pod.jp/products/417',
        order: 997,
        recommend: true,
        is_list_publishable: true,
        is_publishable: true,
        publish_start_at: '2023-01-12 00:00:00',
        publish_end_at: '2024-01-12 00:00:00',
        created_at: '2023-02-02T03:39:03.000000Z',
        updated_at: '2023-02-02T03:39:03.000000Z'
      },
      barista: {
        id: 5,
        name: '香月 麻里',
        name_alphabetical: 'Mari Katsuki',
        overview:
          '・UCCコーヒーアドバイザー・UCCコーヒー鑑定士\n・(ブラジル)コーヒー鑑定士',
        header: 1,
        short_message: '新たなコーヒーの愉しみ方を見つけに来てください。',
        notice: '',
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/barista/instructor-4.jpg',
        is_publishable: true,
        created_at: '2023-02-02T03:38:53.000000Z',
        updated_at: '2023-02-02T03:38:53.000000Z'
      },
      recipe_id: 15,
      is_bookmark: true,
      is_publishable: true,
      publish_start_at: '2023-01-05 00:00:00',
      publish_end_at: '2024-01-05 00:00:00'
    },
    {
      id: 14,
      created_at: '2023-02-13T15:00:00.000000Z',
      name: 'あんこコーヒー_タイトルが長いタイトルが長いタイトルが長いタイトルが長いタイトルが長い',
      image_url:
        'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/dp-recipe/test04_m.png',
      capsule: {
        id: 1,
        name: 'ブルーマウンテンブレンド',
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/capsule/img_capsule-4.png',
        description:
          '苦味・酸味・コクが見事に調和し、ブラウンシュガーのような上品な甘みが特長。コーヒーの王様の名にふさわしい、品格のある味わい。',
        map_position_id: 9,
        taste_parameter: {
          rich: 3,
          bitter: 3,
          acidity: 3
        },
        link_url: 'https://drip-pod.jp/products/1368',
        order: 1000,
        recommend: false,
        is_list_publishable: true,
        is_publishable: true,
        publish_start_at: '2023-01-15 00:00:00',
        publish_end_at: null,
        created_at: '2023-02-02T03:39:03.000000Z',
        updated_at: '2023-02-02T03:39:03.000000Z'
      },
      barista: {
        id: 4,
        name: '小山 勝宝',
        name_alphabetical: 'Katsutaka Koyama',
        overview:
          '・UCCコーヒーアドバイザー・UCCコーヒー鑑定士\n・(ブラジル)コーヒー鑑定士\n・(アメリカ)CQI認定Qアラビカグレーダー\n・(アメリカ)CQI認定Qロブスタグレーダー',
        header: 0,
        short_message:
          'コーヒーの「美味しさ」「楽しさ」を一緒になって体験しましょう。コーヒーをもっと好きになるお手伝いをさせて頂ければ幸いです。',
        notice: '',
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/barista/instructor-3.jpg',
        is_publishable: true,
        created_at: '2023-02-02T03:38:53.000000Z',
        updated_at: '2023-02-02T03:38:53.000000Z'
      },
      recipe_id: 14,
      is_bookmark: false,
      is_publishable: true,
      publish_start_at: '2023-01-04 00:00:00',
      publish_end_at: '2024-01-04 00:00:00'
    }
  ],
  links: [
    {
      id: 5,
      title: '冬時間満喫セット',
      image_url:
        'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/dp-top-banner/image+78.png',
      link_url:
        'https://drip-pod.jp/merchandises?merchandise_category_ids[]=73',
      order: 1004,
      is_publishable: true,
      publish_start_at: '2023-01-02 00:00:00',
      publish_end_at: '2024-01-02 00:00:00',
      created_at: '2023-02-02T03:39:03.000000Z',
      updated_at: '2023-02-02T03:39:03.000000Z'
    },
    {
      id: 6,
      title: 'カプセルお好み定期便',
      image_url:
        'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/dp-top-banner/image+79.png',
      link_url: 'https://drip-pod.jp/subsc/capsule/',
      order: 1003,
      is_publishable: true,
      publish_start_at: '2023-01-01 00:00:00',
      publish_end_at: '2024-01-01 00:00:00',
      created_at: '2023-02-02T03:39:03.000000Z',
      updated_at: '2023-02-02T03:39:03.000000Z'
    },
    {
      id: 3,
      title: '冬のカフェセット',
      image_url:
        'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/dp-top-banner/image+76.png',
      link_url: 'https://drip-pod.jp/products/1502',
      order: 1002,
      is_publishable: true,
      publish_start_at: '2023-01-04 00:00:00',
      publish_end_at: null,
      created_at: '2023-02-02T03:39:03.000000Z',
      updated_at: '2023-02-02T03:39:03.000000Z'
    },
    {
      id: 4,
      title: 'WINTER BLEND 2022',
      image_url:
        'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/dp-top-banner/image+77.png',
      link_url: 'https://drip-pod.jp/products/1507',
      order: 1001,
      is_publishable: true,
      publish_start_at: '2023-01-03 00:00:00',
      publish_end_at: '2024-01-03 00:00:00',
      created_at: '2023-02-02T03:39:03.000000Z',
      updated_at: '2023-02-02T03:39:03.000000Z'
    },
    {
      id: 2,
      title: 'ORIGAMI Aroma Cup',
      image_url:
        'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/dp-top-banner/image+75.png',
      link_url: 'https://drip-pod.jp/campaign/origami/',
      order: 1001,
      is_publishable: true,
      publish_start_at: '2023-01-05 00:00:00',
      publish_end_at: null,
      created_at: '2023-02-02T03:39:03.000000Z',
      updated_at: '2023-02-02T03:39:03.000000Z'
    },
    {
      id: 1,
      title: '公式アプリ新登場',
      image_url:
        'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/dp-top-banner/image+74.png',
      link_url: 'https://drip-pod.jp/dps_apri/',
      order: 1000,
      is_publishable: true,
      publish_start_at: '2023-01-06 00:00:00',
      publish_end_at: null,
      created_at: '2023-02-02T03:39:03.000000Z',
      updated_at: '2023-02-02T03:39:03.000000Z'
    }
  ]
};

export { data };
