import Repository from './Repository';
const resource = '/line';

export default {
  /**
   * LINE連携
   *
   * @param {Object} payload
   * @param {string} payload.token
   */
  link(payload) {
    return Repository.post(`${resource}/alignment`, payload);
  }
};
