/**
 * コンテンツ(トップ)管理新規作成画面等で使用するリンク先
 *
 * @see https://bitbucket.org/sonicmoov/ucc_coffee_creation_api/src/develop/app/Enums/Content/LinkTo.php
 */
export class LinkTo {
  static NOTHING = 1; // なし
  static EXTERNAL = 2; // 外部リンク
  static EVENT = 3; // イベント

  /**
   * タイプIDからタイプ名を取得する
   * @param {number} type
   * @returns {string | undefined}
   */
  static getTypeName = (type) => {
    switch (type) {
      case this.NOTHING:
        return 'なし';
      case this.EXTERNAL:
        return '外部リンク';
      case this.EVENT:
        return 'イベント';
    }
  };
}
