/**
 * カスタムURLスキーム（WebView → Native連携）
 * 要件定義スプレッドシートの「【アプリ/フロント】WebView→Native連携リスト」を参照
 */

const prefix = 'coffee-creation-app://';

/**
 * ページ遷移用カスタムURLスキーム
 * @param {string} typeValue
 * root/home/${コンテンツカテゴリId} 1:トップ, 2:読む, 3:学ぶ, 4:参加する
 * ec |standalone | detail
 * @param {string} paramsValue
 * @returns {string}
 * @example
 * $customUrlScheme.page('root/home/1')
 * $customUrlScheme.page('standalone', 'url=/gift')
 * $customUrlScheme.page('detail', 'url=/gift/detail/:id')
 * $customUrlScheme.page('ec', 'url=https://store.ucc.co.jp/~~~')
 * $customUrlScheme.page('dp/common', 'url=/pro-recipe')
 */
const page = (typeValue, paramsValue) => {
  const action = 'page';
  const type = typeValue;
  let params = encodeURI(paramsValue ? '/?' + paramsValue : '');

  // ページタイプが `ec` の場合、デフォルトでECトップに遷移
  if (typeValue === 'ec' && !paramsValue) {
    params = encodeURI(`/?url=${process.env.VUE_APP_EC_BASE_URL}`);
  }

  return prefix + action + '/' + type + params;
};

/**
 * DP共通テンプレート遷移用カスタムURLスキーム
 * @param {string} path
 * @returns {string}
 * @example
 * $customUrlScheme.dp('/pro-recipe')
 */
const dp = (path) => {
  return prefix + 'page/dp/common/?url=/drip-pod' + path;
};

/**
 * ダイアログ表示用カスタムURLスキーム
 * @param {string} typeValue gift | message | mile | taste-diagnose | coffeemap-tutorial | mile-tutorial
 * @param {string} paramsValue
 * @returns {string}
 * @example
 * $customUrlScheme.dialog('taste-diagnose', 'eclink=0')
 * $customUrlScheme.dialog('coffeemap-tutorial', 'eclink=0')
 * $customUrlScheme.dialog('mile-tutorial')
 * window.location.href = dialog('message', SystemDialogMessage.ERROR_NETWORK);
 */
const dialog = (typeValue, paramsValue) => {
  const action = 'dialog';
  const type = typeValue;
  let params = '';
  switch (typeValue) {
    // URLのパターンがあるものはURIエンコード
    case 'gift':
    case 'taste-diagnose':
    case 'coffeemap-tutorial':
      params = encodeURI(paramsValue ? '/?' + paramsValue : '');
      break;

    default:
      params = paramsValue ? '/' + paramsValue : '';
      break;
  }
  return prefix + action + '/' + type + params;
};

/**
 * 閉じる用カスタムURLスキーム（テンプレートのヘッダの「×」ボタン押下をシミュレート）
 * @returns {string}
 * @example
 * $customUrlScheme.close()
 */
const close = () => {
  return prefix + 'close';
};

/**
 * アプリの再起動用カスタムURLスキーム
 * @returns {string}
 * @example
 * $customUrlScheme.reboot()
 */
const reboot = () => {
  return prefix + 'reboot';
};

const customUrlScheme = (_, inject) =>
  inject('customUrlScheme', {
    page(typeValue, paramsValue) {
      return page(typeValue, paramsValue);
    },

    dialog(typeValue, paramsValue) {
      return dialog(typeValue, paramsValue);
    },

    close() {
      return close();
    },

    reboot() {
      return reboot();
    },

    dp(path) {
      return dp(path);
    }
  });

export { page, dialog, close, reboot, dp, customUrlScheme };
