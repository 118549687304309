import Repository from './Repository';
const resource = '/help';

export default {
  /**
   * ヘルプ一覧取得
   */
  getList() {
    return Repository.get(`${resource}`);
  }
};
