const customer = {
  id: 2184,
  mileage_detail: {
    rank_id: 3,
    rank_name: 'ジャマイカ',
    total: 42,
    subtotal: 23,
    next_goal: 9
  },
  line: {
    mileage_amount: 100,
    is_alignment: 0, // boolean
    is_past_alignment: 0, // boolean
    coupon: true,
    point: 140
  },
  ec: {
    mileage_amount: 100,
    is_alignment: 1, // boolean
    is_past_alignment: 1 // boolean
  },
  is_present_unread: 1, // boolean
  is_notice_unread: 1, // boolean
  is_tutorial_completed: 1, // boolean
  is_preference_test_completed: 1, // boolean
  dp_link: false
};

export { customer };
