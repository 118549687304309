<template>
  <div ref="carousel" class="c-carousel">
    <div class="swiper-wrapper">
      <slot />
    </div>

    <div ref="pagination" class="c-carousel-pagination" />
  </div>
</template>

<script>
// @see https://swiperjs.com/
import Swiper from 'swiper/swiper-bundle';
import lodash from 'lodash';

import {
  defineComponent,
  ref,
  onMounted
} from '@vue/composition-api';
export default defineComponent({
  props: {
    options: {
      type: Object,
      default: null
    }
  },

  setup: (props) => {
    const carousel = ref(null);
    const pagination = ref(null);
    const swiper = ref(null);
    const init = () => {
      const initOptions = {
        slidesPerView: 1,
        spaceBetween: 16,
        pagination: {
          clickable: true,
          el: pagination.value,
          type: 'bullets',
          bulletClass: 'c-carousel-pagination__bullet',
          bulletActiveClass: 'c-carousel-pagination__bullet--active'
        }
      };
      const mergedOptions = lodash.merge(initOptions, props.options);
      swiper.value = new Swiper(carousel.value, mergedOptions);
    };

    onMounted(async () => {
      init();
    });

    return {
      carousel,
      pagination
    };
  }
});
</script>
