import { RewardType } from '@/constants/enums';
import { whitelist } from '@/constants/whitelist';
import { page } from '@/plugins/customUrlScheme';

// import { useNativeConnection } from '@/composables/useNativeConnection';
// const { setMileage } = useNativeConnection();

const prdOnlineStoreDomain = 'https://store.ucc.co.jp';
const envOnlineStoreDomain = process.env.VUE_APP_EC_BASE_URL;

export default (_, inject) =>
  inject('contentList', {
    /**
     * URLの種類によって処理を切り替える
     * @param {string} url
     * @example
     * $contentList.getLink(url);
     */
    getLink(url, eventId) {
      if (!eventId && url === null) return false;
      const appRegex = new RegExp(/coffee-creation-app:\/\//);
      const isOnlineStore = !url
        ? ''
        : url.match(prdOnlineStoreDomain) || url.match(envOnlineStoreDomain);
      const isAppPage = appRegex.test(url);
      const isEvent = !!eventId;

      const matchedWhitelist = (item) => {
        item = item.replace(/\/$/, '');
        const itemRegex = new RegExp(item);
        return !url ? '' : url.match(itemRegex)?.length > 0;
      };
      const isWhiteListLink = whitelist.some(matchedWhitelist);

      let value = '';
      if (isEvent) {
        // 参加する（イベント）詳細
        value = `coffee-creation-app://page/detail/?url=/content/join/detail/${eventId}`;
      } else if (isOnlineStore) {
        // オンラインストア
        value = page('ec', 'url=' + url);
      } else if (isAppPage) {
        // アプリ内遷移
        value = url;
      } else if (isWhiteListLink) {
        // ホワイトリスト内
        value = page('standalone', 'url=' + url);
      } else {
        // 外部ブラウザ
        value = page('external', 'url=' + url);
      }
      return value;
    },

    /**
     * インセンティブを付与
     * @param {object|number} reward
     * @param {boolean} isStageUp ステージアップフラグ
     * @param {number} nowMileage 現在のマイル数
     * @param {string} stageNameBefore マイル取得前のステージ名
     * @param {string} stageNameAfter マイル取得後のステージ名
     * @param {string} stageNameNext マイル取得後の次のステージ名
     * @param {string} waitForLoadComplete マイル取得時に読み込み完了までダイアログの表示を待機するフラグ
     */
    async presentIncentive(
      /* eslint-disable no-unused-vars */
      reward,
      isStageUp = false,
      nowMileage = 0,
      isNotEmptyPresent = false,
      stageNameBefore = null,
      stageNameAfter = null,
      stageNameNext = null,
      waitForLoadComplete = true
      /* eslint-enable */
    ) {
      return;
      // const stageUpFlag = isStageUp;
      // if (!reward) return;
      // const plusMileage =
      //   typeof reward === 'object' ? reward?.amount : reward || 0;
      // if (typeof reward === 'object') {
      //   const type = reward?.type;
      //   if (type !== RewardType.MILEAGE) return;
      // }
      // const values = {
      //   plus_mileage: Number(plusMileage),
      //   is_stage_up: stageUpFlag,
      //   now_mileage: nowMileage,
      //   is_not_empty_present: isNotEmptyPresent,
      //   stage_name_before: stageNameBefore,
      //   stage_name_after: stageNameAfter,
      //   stage_name_next: stageNameNext,
      //   wait_for_load_complete: waitForLoadComplete
      // };
      // await setMileage(values);
    },

    /**
     * インセンティブの合計値を算出
     * @param {object} reward
     */
    incentiveValue(rewards) {
      if (!rewards) return;
      // type = 1 （マイル）のときのみ加算し合計値を返す
      if (Array.isArray(rewards)) {
        const amounts = rewards.map((reward) =>
          reward.type === 1 ? reward.amount : 0
        );
        const reducer = (prevValue, currentValue) => prevValue + currentValue;
        const values = amounts.reduce(reducer);
        return values || 0;
      } else {
        return rewards?.type === 1 ? rewards?.amount : 0;
      }
    },

    /**
     * インセンティブの単位を取得
     * @param {object} reward
     */
    incentiveUnit() {
      // ローンチ時はマイルのみなので固定
      return RewardType.getTypeUnit(1);
    }
  });
