const response = {
  id: 10,
  mileage_detail: {
    rank_id: 4,
    rank_name: 'キリマンジャロ',
    total: 500,
    subtotal: 150,
    next_goal: 50
  },
  line: {
    mileage_amount: 100,
    is_alignment: true,
    is_past_alignment: true
  },
  ec: {
    mileage_amount: 100,
    is_alignment: true,
    is_past_alignment: true
  },
  is_present_unread: true,
  is_notice_unread: true,
  is_tutorial_completed: true,
  is_preference_test_completed: true,
  mileage: {
    now_mileage: 301,
    plus_mileage: 21,
    rank_up: true
  },
  present: [
    {
      id: 10,
      name: '74ポイントプレゼント！',
      description: 'プレセント',
      warning: '注意事項',
      type: 2,
      amount: 74,
      coupon: 'coupon1',
      image_url: 'https://via.placeholder.com/640x480.png/008844?text=nihil'
    }
  ]
};

export default {
  post() {
    return [200, response];

    // error: タイムアウト
    // return [408, { message: 'タイムアウトしました' }];
  }
};
