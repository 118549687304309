import { data } from './data/data.json';

export default {
  get() {
    return [200, data];

    // error: タイムアウト
    // return [408, { message: 'タイムアウトしました' }];
  }
};
