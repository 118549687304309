<template>
  <button
    :class="classes"
    :disabled="isDisabled"
    v-ripple="$utilities.getRippleOptions('light')"
    @click="onClick">
    <template v-if="!isLoading">
      <Icon :name="iconName" color="primary01" />
      <span>{{ iconLabel }}</span>
    </template>
    <template v-else>
      <Spinner size="1em" />
    </template>
  </button>
</template>

<script>
import { SystemDialogMessage } from '@/constants/enums';
import { defineComponent, ref, computed } from '@vue/composition-api';

export default defineComponent({
  props: {
    id: {
      type: Number,
      default: 1
    },

    state: {
      type: Boolean,
      default: false
    }
  },

  setup: (props, context) => {
    const isLoading = ref(false);
    const isDisabled = ref(false);

    const isActive = computed(() => props.state);

    const sleep = (ms = 100) =>
      new Promise((resolve) => setTimeout(resolve, ms));

    const classes = computed(() => {
      return {
        'c-favoriteButton': true,
        'c-favoriteButton--active': isActive.value,
        'c-favoriteButton--loading': isLoading.value
      };
    });

    const iconName = computed(() => (isActive.value ? 'heartOn' : 'heartOff'));
    const iconLabel = computed(() =>
      isActive.value ? 'お気に入り中' : 'お気に入りする'
    );

    const onClick = () => {
      // ボタンを押せる状態の場合にのみ切り替え
      if (!isDisabled.value && !isLoading.value) {
        toggle();
      }
    };

    const postState = async (value = 0) => {
      const params = {
        favorite: value
      };
      try {
        await context.root
          .$repositories('barista')
          .favoritePro(props.id, params)
          .then((response) => {
            const { data } = response;
            context.emit('onClickFavorite', data);
          });
      } catch {
        window.location.href = context.root.$systemDialog(
          SystemDialogMessage.ERROR_COMMON
        );
      }
    };

    const toggle = async () => {
      isDisabled.value = true;
      isLoading.value = true;
      const value = props.state ? 0 : 1;
      postState(value);
      await sleep(400);
      isLoading.value = false;
      isDisabled.value = false;
    };

    return {
      isLoading,
      isActive,
      isDisabled,
      classes,
      iconName,
      iconLabel,
      onClick
    };
  }
});
</script>
