const data = [
  {
    title: 'コーヒーイベント',
    description:
      'レギュラーコーヒー100%、香りとコクを最大限引き出す高級豆を使用。ブラック無糖はUCC。香料は使用せず、コーヒー本来のおいしさを追求し、香りとキレが際立った無糖コーヒーにこだわりました。',
    image_url: 'https://placehold.jp/ccc/fff/640x200.png?text=イベント',
    type: 'イベント',
    event_id: 1,
    product_id: null,
    movie_id: null,
    publish_start_at: 1611627298048,
    barista: {
      id: 1,
      name: '中井 千香子',
      image_url: 'https://placehold.jp/4bb/fff/640x200.png?text=中井千香子'
    }
  },
  {
    title: 'コーヒーイベントコーヒーイベントコーヒーイベント',
    description:
      'レギュラーコーヒー100%、香りとコクを最大限引き出す高級豆を使用。ブラック無糖はUCC。香料は使用せず、コーヒー本来のおいしさを追求し、香りとキレが際立った無糖コーヒーにこだわりました。',
    image_url: 'https://placehold.jp/589/fff/640x200.png?text=動画',
    type: '動画',
    event_id: null,
    product_id: null,
    movie_id: 1,
    publish_start_at: 1611627298048,
    barista: {
      id: 1,
      name: '中井 千香子',
      image_url: 'https://placehold.jp/b65/fff/640x200.png?text=中井千香子'
    }
  },
  {
    title:
      'コーヒーイベントコーヒーイベントコーヒーイベントコーヒーイベントコーヒーイベントコーヒーイベント',
    description:
      'レギュラーコーヒー100%、香りとコクを最大限引き出す高級豆を使用。ブラック無糖はUCC。香料は使用せず、コーヒー本来のおいしさを追求し、香りとキレが際立った無糖コーヒーにこだわりました。',
    image_url: 'https://placehold.jp/7a4/fff/640x200.png?text=プロコーヒー',
    type: 'プロコーヒー',
    event_id: null,
    product_id: 2,
    movie_id: null,
    publish_start_at: 1611323098048,
    barista: {
      id: 1,
      name: '中井 千香子中井 千香子中井 千香子',
      image_url: 'https://placehold.jp/b65/fff/640x200.png?text=中井千香子'
    }
  },
  {
    title:
      'コーヒーイベントコーヒーイベントコーヒーイベントコーヒーイベントコーヒーイベントコーヒーイベント',
    description:
      'レギュラーコーヒー100%、香りとコクを最大限引き出す高級豆を使用。ブラック無糖はUCC。香料は使用せず、コーヒー本来のおいしさを追求し、香りとキレが際立った無糖コーヒーにこだわりました。',
    image_url: 'https://placehold.jp/b88/fff/640x200.png?text=プロコーヒー',
    type: 'プロコーヒー',
    event_id: null,
    product_id: 1,
    movie_id: null,
    publish_start_at: 1621323098048,
    barista: {
      id: 1,
      name: '中井 千香子',
      image_url: 'https://placehold.jp/ccc/fff/640x200.png?text=中井千香子'
    }
  }
];

export { data };
