import CoffeeChannelCard from './CoffeeChannelCard.vue';

export default {
  title: 'SHARED/CoffeeChannelCard',
  component: CoffeeChannelCard
};

const Template = (args, { argTypes }) => ({
  components: { CoffeeChannelCard },
  props: Object.keys(argTypes),
  template: '<CoffeeChannelCard v-bind="$props" />'
});

const args = {
  id: 1,
  href: '/',
  src: 'https://via.placeholder.com/686x340/0bc/fff',
  title: 'タイトルタイトル',
  reward: {
    type: 1,
    amount: 5
  }
};

export const Default = Template.bind({});
Default.args = {
  ...args
};

export const Tiled = Template.bind({});
Tiled.args = {
  ...args,
  tiled: true
};
