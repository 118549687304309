import Repository from './Repository';
const resource = '/products';

export default {
  /**
   * 統合製品詳細取得
   *
   * @param {number | string} productId
   */
  getProduct(productId) {
    return Repository.get(`${resource}/detail/${productId}`);
  },

  /**
   * 感想登録一覧取得
   *
   * @param {string} [queryString] クエリ文字列 ?page=2
   */
  getImpressionHistories(queryString = '') {
    return Repository.get(`${resource}/history/impression${queryString}`);
  },

  /**
   * 感想詳細取得
   *
   * @param {number | string} impressionId
   */
  getImpression(impressionId) {
    return Repository.get(`${resource}/impression/${impressionId}`);
  },

  /**
   * 感想登録
   *
   * @param {number | string} productId
   * @param {{
   *   is_favorable: boolean | null;
   *   memo: {
   *     aroma: number | null;
   *     bitterness: number | null;
   *     sourness: number | null;
   *     sweetness: number | null;
   *     richness: number | null;
   *     comment: string | null;
   *   };
   * }} payload
   */
  createImpression(productId, payload) {
    return Repository.post(`${resource}/detail/${productId}`, payload);
  },

  /**
   * 感想更新
   *
   * @param {number | string} impressionId
   * @param {{
   *   is_favorable: boolean | null;
   *   memo: {
   *     aroma: number | null;
   *     bitterness: number | null;
   *     sourness: number | null;
   *     sweetness: number | null;
   *     richness: number | null;
   *     comment: string | null;
   *   };
   * }} payload
   */
  updateImpression(impressionId, payload) {
    return Repository.post(`${resource}/impression/${impressionId}`, payload);
  }
};
