import ProAvatar from './ProAvatar.vue';

export default {
  title: 'SHARED/ProAvatar',
  component: ProAvatar
};

const Template = (args, { argTypes }) => ({
  components: { ProAvatar },
  props: Object.keys(argTypes),
  template: '<ProAvatar v-bind="$props" />'
});

export const Default = Template.bind({});
Default.args = {
  src: '',
  size: 120,
  alt: '',
  isFavorite: false,
  isBordered: true
};

export const WithImageURL = Template.bind({});
WithImageURL.args = {
  src: 'https://via.placeholder.com/200x200/0bc/fff?text=PRO',
  size: 120,
  alt: '',
  isFavorite: false,
  isBordered: true
};

export const Favorite = Template.bind({});
Favorite.args = {
  src: '',
  size: 120,
  alt: '',
  isFavorite: true,
  isBordered: true
};

export const NoBorder = Template.bind({});
NoBorder.args = {
  src: '',
  size: 120,
  alt: '',
  isFavorite: false,
  isBordered: false
};
