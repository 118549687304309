import { histories } from './data/histories';
import { postResponse } from './data/post';

export default {
  get() {
    return [200, histories];

    // error: タイムアウト
    // return [408, { message: 'タイムアウトしました' }];
  },

  post() {
    return [200, postResponse];

    // error: タイムアウト
    // return [408, { message: 'タイムアウトしました' }];
  }
};
