import Carousel from './Carousel.vue';
import Card from './Card.vue';

export default {
  title: 'SHARED/Carousel',
  component: Carousel
};

const Template = (args, { argTypes }) => ({
  components: { Carousel, Card },
  props: Object.keys(argTypes),
  template: `
    <Carousel v-bind="$props">
      <Card class="swiper-slide">A</Card>
      <Card class="swiper-slide">B</Card>
      <Card class="swiper-slide">C</Card>
    </Carousel>
  `
});

export const Default = Template.bind({});
Default.args = {};
