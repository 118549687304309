var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-baseSelect c-form__item" },
    [
      _c("FormLabel", {
        attrs: { label: _vm.label, hint: _vm.hint, required: _vm.required },
      }),
      _c(
        "ValidationProvider",
        _vm._b(
          {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var errors = ref.errors
                  var failed = ref.failed
                  return [
                    _c(
                      "div",
                      {
                        staticClass:
                          "c-baseSelect__controlWrapper c-formControl",
                      },
                      [
                        _c(
                          "select",
                          {
                            staticClass: "c-baseSelect__control",
                            class: { "has-error": failed },
                            domProps: { value: _vm.value },
                            on: {
                              change: function ($event) {
                                return _vm.$emit("input", $event.target.value)
                              },
                            },
                          },
                          [
                            _c("option", { attrs: { value: "", hidden: "" } }, [
                              _vm._v("選択してください"),
                            ]),
                            _vm._l(_vm.items, function (item, i) {
                              return _c(
                                "option",
                                { key: i, domProps: { value: item.value } },
                                [_vm._v(" " + _vm._s(item.label) + " ")]
                              )
                            }),
                          ],
                          2
                        ),
                      ]
                    ),
                    errors[0]
                      ? _c("div", { staticClass: "c-errorMessage" }, [
                          _vm._v(_vm._s(errors[0])),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          },
          "ValidationProvider",
          _vm.veeProps,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }