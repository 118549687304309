var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.label || _vm.required || _vm.hint
    ? _c("label", { staticClass: "c-formLabel" }, [
        _c("span", { staticClass: "c-formLabel__text" }, [
          _vm._v(_vm._s(_vm.label)),
        ]),
        _vm.required
          ? _c("span", { staticClass: "c-formLabel__required" }, [
              _vm._v("必須"),
            ])
          : _vm._e(),
        _c("span", { staticClass: "c-formLabel__hint" }, [
          _vm._v(_vm._s(_vm.hint)),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }