import Vue from 'vue';
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  setInteractionMode
} from 'vee-validate';
import { required, max, is, email } from 'vee-validate/dist/rules';

setInteractionMode('eager');

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

extend('required', {
  ...required,
  message: '{_field_}への入力は必須です。'
});
extend('max', {
  ...max,
  message: '{_field_}の文字数制限は{length}文字までです。'
});
extend('is', {
  ...is,
  message: '{_field_}が一致しません。'
});
extend('email', {
  ...email,
  message: 'メールアドレスの形式が正しくありません。'
});
