export class WORDS {
  // ブランド・独自の用語
  static APP_NAME = process.env.VUE_APP_NAME;
  static UCC = 'UCC';
  static UCC_CATCHPHRASE = 'ひと粒と、世界に、愛を';
  static OFFICIAL = `${this.UCC}公式`;
  static DRIP_POD = 'DRIP POD 4';

  static PRO = 'プロ';
  static COFFEE = 'コーヒー';
  static PRO_COFFEE = `${this.PRO}のおすすめ${this.COFFEE}`;
  static PRO_COFFEE_S = `${this.PRO}${this.COFFEE}`;
  static IMPRESSION = '感想';
  static RECIPE = 'レシピ';

  // コンテンツタブ
  static TOP = 'トップ';
  static READ = '読む';
  static JOIN = '参加する';
  static STUDY = '学ぶ';

  // インセンティブ関連
  static STAGE = 'ステージ';
  static STAGE_UP = `${this.STAGE}アップ`;
  static MILE = 'マイル';
  static POINT = 'ポイント';
  static COUPON = 'クーポン';
  static ONLINESTORE = 'オンラインストア';
  static ONLINESTORE_POINT = `${this.ONLINESTORE}${this.POINT}`;
  static ONLINESTORE_COUPON = `${this.ONLINESTORE}${this.COUPON}`;

  // アカウント関連
  static ACCOUNT = 'アカウント';
  static ACCOUNT_LOGIN = 'ログイン';
  static ACCOUNT_CREATE = '新規登録';
  static ACCOUNT_TAKEOVER = '引継';
  static ACCOUNT_INFO = '会員情報';
  static ACCOUNT_LINK = '連携';
  static ACCOUNT_LINE = 'LINEアカウント';
  static FAVORITE = 'お気に入り';
  static FAVORITE_ACTIVE = `${this.FAVORITE}中`;
  static FAVORITE_ACTIVE = `${this.FAVORITE}する`;
  static MYPAGE = 'マイページ';
  static PRESENT = 'プレゼント';
  static PRESENT_BOX = `${this.PRESENT}BOX`;
  static LINK = '連携';
  static LINK_CANCEL = '解除';
  static PROCESSING = '処理中...';

  // LINE
  static LINE = 'LINE';
  static LINE_ID = `${this.LINE} ID`;
  static LINE_ADD_FRIEND = '友だち追加';
  static LINE_LINK_ID = `ID${this.LINK}`;
  static OFFICIAL_LINE = `${this.OFFICIAL}${this.LINE}${this.ACCOUNT}`;

  // その他
  static NOTICE = 'お知らせ';
  static WHATS_NEW = '新着情報';
  static VIDEO = '動画';
  static TUTORIAL = 'チュートリアル';
  static MILE_REWARD = `${this.MILE}特典`;
  static MILE_HISTORY = `${this.MILE}履歴`;
  static EVENT = 'イベント';
  static EVENT_SPEAKER = '登壇';
  static EVENT_TOBE = '予定';
  static MAINTENANCE = 'メンテナンス';

  static SEE = 'を見る';
  static MORE = 'をもっと見る';
  static BACK_TOP = 'トップへ戻る';
  static BACK_SERVICE = 'サービスへ戻る';
  static BACK_HOME_MILE = `ホームで${this.MILE}を貯める`;

  static CONTENTS_TOP = {
    STATUS_BUTTON_IMPRESSION: `${this.IMPRESSION}登録で\n${this.MILE}獲得`,
    TITLE_PRO_COFFEE: `${this.PRO}のおすすめ${this.COFFEE}`,
    TITLE_VIDEO: 'Coffee Channel',
    TITLE_COFFEE_TIPS: 'Coffee Tips',
    TITLE_PICK_UP: 'Pick Up'
  };

  static PRO_DETAIL = {
    TITLE_VIDEO: this.VIDEO,
    TITLE_PRO_COFFEE: this.PRO_COFFEE,
    TITLE_EVENT: `${this.EVENT}${this.EVENT_SPEAKER}${this.EVENT_TOBE}`,

    MORE_VIDEO: `${this.VIDEO}${this.MORE}`,
    MORE_PRO_COFFEE: `${this.EVENT_SPEAKER}${this.EVENT}${this.MORE}`,
    MORE_EVENT: `${this.PRO_COFFEE_S}${this.MORE}`
  };

  static FAVORITE_LIST = {
    TITLE: this.WHATS_NEW,
    DESCRIPTION: `フォローした${this.PRO}の${this.WHATS_NEW}をお知らせします`,
    EMPTY: `現在${this.WHATS_NEW}はありません`
  };

  static MILE_PROGRESS = {
    NOW: `現在の${this.MILE}`,
    NEXT: `次の${this.STAGE}`,
    COMPLETED: '収穫完了',
    COMPLETED_DESCRIPTION: '新たなステージでコーヒーを育てよう！',
    NEXT_BUTTON: `次の${this.STAGE}へ進む`,
    POINT_HISTORY: `${this.POINT}履歴`,
    ONLINESTORE_POINT: `${this.ONLINESTORE}${this.POINT}`
  };

  static LINK_LINE = {
    TITLE: `${this.LINE}${this.LINE_ADD_FRIEND} & ${this.LINE_LINK_ID}でお得に`,
    DESCRIPTION: `アプリと${this.OFFICIAL}${this.ACCOUNT}を連携すると、お得なセール情報やお買い物に便利な情報をお届けします !`
  };

  static PRODUCT = {
    SUPPLIER: `この${this.COFFEE}の提供者`,
    COFFEE_BEANS: `使用している${this.COFFEE}豆`,
    SEND_BUTTON_IMPRESSION: `今の${this.IMPRESSION}を記録する`,
    PAY_ONLINESTORE: `${this.ONLINESTORE}で購入`
  };

  static DRIP_POD_MENU = {
    TITLE: 'Drip\nMenu',
    ITEMS: {
      CAPSULE: 'カプセルから選ぶ',
      RECIPE: 'レシピから選ぶ',
      HISTORY: '抽出履歴',
      MYRECIPE: '保存したレシピ'
    }
  };

  static DRIP_POD_TOP = {
    MENU: this.DRIP_POD_MENU.TITLE,
    MOVIE_UNSUPPORTED:
      '申し訳ありませんが、お使いの環境で動画を再生できません。',
    CLOSED: 'このプロレシピは現在非公開です。',

    PRO_RECIPE: {
      // eslint-disable-next-line quotes
      TITLE: "PROFESSIONAL's RECIPE",
      SUBTITLE: `${this.PRO}の味を、ボタンひとつで。`,
      MORE: 'もっと見る',
      LINK_PRO_RECIPE: 'プロレシピをすべて見る',
      LINK_CAPSULE: 'カプセルからレシピを選ぶ'
    },

    MYRECIPE: {
      TITLE_EN: 'My Recipe',
      TITLE_JP: '保存したレシピ',
      LINK_LABEL: 'もっと見る',
      EMPTY: {
        TITLE: '保存したレシピはありません。'
      }
    },

    HISTORY: {
      TITLE_EN: 'History',
      TITLE_JP: '抽出履歴',
      LINK_LABEL: 'もっと見る'
    },

    FAVORITE: {
      TITLE_EN: 'Favorite',
      TITLE_JP: '本体登録レシピ',
      LEAD: '接続中のマシン本体に登録されているレシピが表示されます。',
      EMPTY_TITLE: '登録中のレシピがありません。',
      EMPTY_TEXT:
        '好きなレシピを本体に登録すると、ボタン一つで抽出が可能です。',
      EMPTY_LINK_LABEL: '本体登録レシピとは？',
      INACTIVE_BLUETOOTH_TITLE: '本体に接続されていません。',
      INACTIVE_BLUETOOTH_TEXT:
        'DRIP POD YOUBIと接続すると、登録されているレシピが確認できます。',
      INACTIVE_BLUETOOTH_LINK_LABEL: 'DRIP POD YOUBIに接続する'
    },

    HOW_TO_USE: {
      TITLE_EN: 'How To Use',
      TITLE_JP: 'DRIP PODの使い方',
      LEAD: 'すべての人に最高の一杯と、より豊かな時間を。'
    }
  };

  static DRIP_POD_PRO_RECIPE = {
    SELECTED: 'SELECTED',
    STORE_LINK_CAPSULE: 'このカプセルを購入する',
    SELECTED_CAPSULE_RECIPE: 'このカプセルを使ったプロレシピ',
    EMPTY_TEXT: '登録されたレシピはありません。',
    EMPTY_SUBTEXT: '他のカプセルを選択してください。',
    RECIPE: 'RECIPE',
    STEPS: 'STEPS',
    TASTE: 'TASTE',
    POINT: 'このレシピのポイント'
  };

  static DRIP_POD_PRO_RECIPE_DETAIL = {
    REQUIRED_TIME: '所要時間',
    SET_BODY: '本体に登録する',
    USE_CAPSULE: 'このレシピで使用するカプセル',
    DRIP: 'このレシピでドリップする'
  };

  static DRIP_POD_CAPSULE = {
    LEAD: '一覧から使用するカプセルを選択してください。',
    RECENT: {
      EN: 'RECENT',
      JP: '最近使ったカプセル'
    },
    RECOMMEND: {
      EN: 'RECOMMEND',
      JP: 'おすすめのカプセル'
    },
    TASTE: {
      EN: 'TASTE',
      JP: 'カプセルの味わい'
    },

    SELECT_CAPSULE: 'このカプセルを選択する',
    STORE_LINK_CAPSULE: 'このカプセルを購入する'
  };

  static DRIP_POD_HISTORY_LIST = {
    DESCRIPTION: '最大10件までの抽出履歴をご確認いただけます。',
    EMPTY_TEXT: '抽出履歴はありません。',
    EMPTY_SUBTEXT: 'コーヒーやお茶を淹れると\n抽出履歴が記録されます！',
    MORE: 'プロレシピをすべて見る',
    CLOSED: 'このプロレシピは現在非公開です。'
  };

  static DRIP_POD_MYRECIPE_LIST = {
    EMPTY_TEXT: '保存したレシピはありません。',
    EMPTY_SUBTEXT:
      'コーヒーやお茶を淹れて\nお好きなレシピを登録してみましょう！',
    MORE: 'プロレシピをすべて見る',
    CLOSED: 'このプロレシピは現在非公開です。'
  };

  static DRIP_POD_INTRODUCTION = {
    BUTTON: `${this.DRIP_POD}と${this.LINK}する`
  };

  static TASTE_MAP = {
    LEGEND_CAPSULE: 'カプセルの基本設定で淹れたときの味わい',
    LEGEND_RECIPE: 'このレシピで淹れたときの味わい'
  };
}
