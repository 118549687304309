/**
 * JSから呼び出すネイティブ側の関数リスト
 */
const FUNCTIONS = {
  GET_ACCESS_TOKEN: 'getAccessToken',
  SET_ACCESS_TOKEN: 'setAccessToken',
  GET_VERSION: 'getVersion',
  GET_MILEAGE: 'getMileage',
  SET_MILEAGE: 'setMileage',
  SET_CLIPBOARD: 'setClipboard',
  GET_NOTIFICATION_STATUS: 'getNotificationStatus',
  OPEN_NOTIFICATION_SETTINGS: 'openNotificationSettings',
  SET_BREW_PARAMETERS: 'setBrewParameters',
  SET_FAVORITE_RECIPE_PARAMETERS: 'setFavoriteRecipeParameters',
  SET_BREW_ERROR: 'setBrewError',
  SET_HIDE_BREWING_VIEW: 'setHideBrewingView'
};

export const useNativeConnection = () => {
  const isIOS = /Macintosh|iPad|iPhone|iPod/.test(window.navigator.userAgent);
  const isAndroid = /android/i.test(window.navigator.userAgent);

  /**
   * ネイティブアプリ側の関数を実行する
   * @param {string} functionName
   * @param {Object} params JSON
   * @returns {Promise<Object>}
   */
  const _callNativeAppFunction = async (functionName, params) => {
    let res;
    if (isIOS) {
      res = await window.webkit.messageHandlers[functionName].postMessage(
        JSON.stringify(params) || ''
      );
    } else {
      res =
        // eslint-disable-next-line eqeqeq
        params == null
          ? await window.Android[functionName]()
          : await window.Android[functionName](JSON.stringify(params));
    }
    return JSON.parse(res || '{}');
  };

  /**
   * 認証トークンを取得する
   *
   * @returns {Promise<{access_token: string; token_type: string}>}
   */
  const getAccessToken = async () => {
    try {
      return await _callNativeAppFunction(FUNCTIONS.GET_ACCESS_TOKEN);
    } catch {
      return {
        // web開発時向けのフォールバック
        // FIXME: デバッグ用のアクセストークンなので最終的に修正
        access_token:
          process.env.VUE_APP_DEBUG_ACCESS_TOKEN_USER_1 || 'ACCESS_TOKEN',
        token_type: 'bearer'
      };
    }
  };

  /**
   * ネイティブアプリ側が保持している認証トークンを更新する
   *
   * @param {{ access_token: string; token_type: string; expires_in: number; }} auth
   * @returns {Promise<void>}
   */
  const setAccessToken = async (auth) => {
    try {
      return await _callNativeAppFunction(FUNCTIONS.SET_ACCESS_TOKEN, auth);
    } catch {
      // noop
    }
  };

  /**
   * インストールされているアプリのバージョンを返す
   *
   * @returns {Promise<{ version: string; }>} e.g. 1.0.0
   */
  const getInstalledAppVersion = async () => {
    try {
      return await _callNativeAppFunction(FUNCTIONS.GET_VERSION);
    } catch {
      // web開発時向けのフォールバック
      return { version: '1.0.1' };
    }
  };

  /**
   * 直近のマイルページ表示時のマイル値を返す
   * @returns {Promise<{ mileage_last: number; }>}
   */
  const getMileage = async () => {
    try {
      return await _callNativeAppFunction(FUNCTIONS.GET_MILEAGE);
    } catch {
      // 開発用
      return {
        mileage_last: 0
      };
    }
  };

  /**
   * WebViewでマイルを取得した際の情報を返す
   * @param {{
   *   plus_mileage: number,
   *   is_stage_up: boolean,
   *   now_mileage: number,
   *   is_not_empty_present: boolean,
   *   stage_name_before: string | null,
   *   stage_name_after: string | null,
   *   stage_name_next: string | null,
   *   wait_for_load_complete: boolean,
   * }} values
   * @returns {Promise<void>}
   */
  const setMileage = async (values) => {
    try {
      return await _callNativeAppFunction(FUNCTIONS.SET_MILEAGE, values);
    } catch {
      // noop
    }
  };

  /**
   * 指定したテキストを端末のクリップボードにコピーする
   *
   * @param {string} value クリップボードにコピーするテキスト
   * @returns {Promise<{ success: boolean; }>}
   */
  const setClipboard = async (value) => {
    try {
      return await _callNativeAppFunction(FUNCTIONS.SET_CLIPBOARD, { value });
    } catch {
      // web開発時向けのフォールバック
      return { success: true };
    }
  };

  /**
   * プッシュ通知の許可フラグを取得する
   *
   * @returns {Promise<{ is_allowed: boolean; }>}
   */
  const getNotificationStatus = async () => {
    try {
      return await _callNativeAppFunction(FUNCTIONS.GET_NOTIFICATION_STATUS);
    } catch {
      // 開発用
      return {
        is_allowed: true
      };
    }
  };

  /**
   * プッシュ通知の設定画面を開く
   */
  const openNotificationSettings = async () => {
    try {
      return await _callNativeAppFunction(FUNCTIONS.OPEN_NOTIFICATION_SETTINGS);
    } catch {
      // noop
    }
  };

  /**
   * 抽出のパラメータを返す
   * @param {{
   *   pro_recipe_id: number,
   *   step_message_extract1: string,
   *   step_message_extract2?: string,
   *   step_message_steaming: string,
   *   is_bookmarked: boolean,
   *   pre_value: number,
   *   pause_time: number,
   *   front_temperature: number,
   *   front_value: number,
   *   front_pump_pwm: number,
   *   back_temperature: number,
   *   back_value: number,
   *   back_pump_pwm: number,
   *   all_value: number,
   * }} values
   */
  const setBrewParameters = async (values) => {
    try {
      // eslint-disable-next-line no-console
      console.debug('Brew parameters :>> ', values);
      return await _callNativeAppFunction(
        FUNCTIONS.SET_BREW_PARAMETERS,
        values
      );
    } catch {
      // noop
    }
  };

  /*
   * ネイティブ側で抽出フロー中にエラーダイアログの表示が起きた場合、そのダイアログを閉じる処理でネイティブが呼び出す。
   * 現在表示している抽出中画面と同様のプロレシピIDのプロレシピ詳細に遷移する。
   */
  const setBrewError = async () => {
    try {
      // eslint-disable-next-line no-console
      return await _callNativeAppFunction(FUNCTIONS.SET_BREW_ERROR);
    } catch {
      // noop
    }
  };

  /**
   * 本体へ登録するプロレシピのIDとパラメータを返す
   * @param {{
   *   pro_recipe_id: number,
   *   pre_value: number,
   *   pause_time: number,
   *   front_temperature: number,
   *   front_value: number,
   *   front_pump_pwm: number,
   *   back_temperature: number,
   *   back_value: number,
   *   back_pump_pwm: number,
   *   all_value: number,
   * }} values
   */
  const setFavoriteRecipeParameters = async (values) => {
    try {
      // eslint-disable-next-line no-console
      console.debug('Favorite recipe parameters :>> ', values);
      return await _callNativeAppFunction(
        FUNCTIONS.SET_FAVORITE_RECIPE_PARAMETERS,
        values
      );
    } catch {
      // noop
    }
  };

  /*
   * 抽出が終わって詳細画面に遷移する場合、抽出中のモジュールを閉じる処理でネイティブが呼び出す。
   * 現在表示している抽出中画面と同じプロレシピIDのプロレシピ詳細に遷移する。
   */
  const setHideBrewingView = async () => {
    try {
      // eslint-disable-next-line no-console
      return await _callNativeAppFunction(FUNCTIONS.SET_HIDE_BREWING_VIEW);
    } catch {
      // noop
    }
  };

  return {
    isIOS,
    isAndroid,

    getAccessToken,
    setAccessToken,
    getInstalledAppVersion,
    getMileage,
    setMileage,
    setClipboard,
    getNotificationStatus,
    openNotificationSettings,
    setBrewParameters,
    setBrewError,
    setFavoriteRecipeParameters,
    setHideBrewingView
  };
};
