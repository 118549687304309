import Button from './Button.vue';
import Icon from './Icon.vue';

export default {
  title: 'SHARED/Button',
  component: Button,
  argTypes: {
    styleType: {
      options: ['primary', 'secondary', 'tertiary', 'quaternary', 'disabled'],
      control: { type: 'select' }
    }
  }
};

const Template = (args, { argTypes }) => ({
  components: { Button },
  props: Object.keys(argTypes),
  template: '<Button v-bind="$props">Button</Button>'
});

const StyleTypeTemplate = (args, { argTypes }) => ({
  components: { Button },
  props: Object.keys(argTypes),
  template: `
    <div>
      <Button style-type="primary" v-bind="$props">Primary</Button>
      <Button style-type="secondary" v-bind="$props">Secondary</Button>
      <Button style-type="tertiary" v-bind="$props">Tertiary</Button>
      <Button style-type="quaternary" v-bind="$props">Quaternary</Button>
      <Button style-type="disabled" v-bind="$props">Disabled</Button>
    </div>
  `
});

const SizeTemplate = (args, { argTypes }) => ({
  components: { Button },
  props: Object.keys(argTypes),
  template: `
    <div>
      <Button v-bind="$props">Default</Button>
      <Button size="xs" v-bind="$props">Extra Small</Button>
      <Button size="sm" v-bind="$props">Small</Button>
      <Button size="lg" v-bind="$props">Large</Button>
    </div>
  `
});

const ElevationTemplate = (args, { argTypes }) => ({
  components: { Button },
  props: Object.keys(argTypes),
  template: `
    <div>
      <Button v-bind="$props">Default</Button>
      <Button flat v-bind="$props">Flat</Button>
      <Button elevation v-bind="$props">Elevation</Button>
    </div>
  `
});

const IconTemplate = (args, { argTypes }) => ({
  components: { Button, Icon },
  props: Object.keys(argTypes),
  template: `
    <div>
      <Button v-bind="$props">
        <template #iconPrepend>
          <Icon name="arrowLeft" />
        </template>
        Button
      </Button>

      <Button v-bind="$props">
        <template #iconAppend>
          <Icon name="arrowRight" />
        </template>
        Button
      </Button>
    </div>
  `
});

export const Default = Template.bind({});
Default.args = {
  type: 'button',
  href: '',
  to: '',
  styleType: 'tertiary',
  fluid: false,
  size: '',
  elevation: false,
  bordered: false,
  squared: false,
  action: false,
  disabled: false,
  flat: false
};

export const StyleType = StyleTypeTemplate.bind({});

export const Size = SizeTemplate.bind({});
Size.args = {
  styleType: 'quaternary'
};

export const Fluid = Template.bind({});
Fluid.args = {
  fluid: true,
  styleType: 'quaternary'
};

export const Squared = Template.bind({});
Squared.args = {
  squared: true,
  styleType: 'quaternary'
};

export const Bordered = Template.bind({});
Bordered.args = {
  bordered: true,
  styleType: 'quaternary'
};

export const Elevation = ElevationTemplate.bind({});
Elevation.args = {
  styleType: 'quaternary'
};

export const WithIcon = IconTemplate.bind({});
WithIcon.args = {
  action: true,
  styleType: 'quaternary'
};
