import { useEnvironment } from '@/composables/useEnvironment';
import { VIEW_TEMPLATE } from '@/constants/ViewTemplate';

const { isPrd } = useEnvironment();

let routes = [];

export const COFFEE_CREATION_PAGES = {
  path: '/',
  component: () => import('@/layouts/Default.vue'),
  children: [
    {
      name: 'ContentTop',
      path: '/content/top',
      component: () => import('@/views/content/top/Index.vue'),
      meta: {
        title: 'コンテンツ一覧 トップ',
        template: VIEW_TEMPLATE.COMMON
      }
    },
    {
      name: 'ContentRead',
      path: '/content/read',
      component: () => import('@/views/content/read/Index.vue'),
      meta: {
        title: 'コンテンツ一覧 読む',
        template: VIEW_TEMPLATE.COMMON
      }
    },
    {
      name: 'ContentJoin',
      path: '/content/join',
      component: () => import('@/views/content/join/Index.vue'),
      meta: {
        title: 'コンテンツ一覧 参加する',
        template: VIEW_TEMPLATE.COMMON
      }
    },
    {
      name: 'ContentJoinDetail',
      path: '/content/join/detail/:id',
      component: () => import('@/views/content/join/detail/Index.vue'),
      meta: {
        title: '参加する 詳細',
        hasHeadImage: true
      }
    },
    {
      name: 'ContentStudy',
      path: '/content/study',
      component: () => import('@/views/content/study/Index.vue'),
      meta: {
        title: 'コンテンツ一覧 学ぶ',
        hasHeadImage: true,
        template: VIEW_TEMPLATE.COMMON
      }
    },
    {
      name: 'CoffeeDetail',
      path: '/coffee/detail/:productId',
      component: () => import('@/views/coffee/detail/Index.vue'),
      meta: {
        title: 'コーヒー詳細',
        hasHeadImage: true,
        template: VIEW_TEMPLATE.DETAIL
      }
    },
    {
      name: 'MypageCoffeeMap',
      path: '/mypage/coffeemap',
      component: () => import('@/views/mypage/coffeemap/Index.vue'),
      meta: {
        title: 'コーヒーマップ',
        bgColor: 'beige03Bg',
        template: VIEW_TEMPLATE.COMMON
      }
    },
    {
      name: 'MypageCoffeeMapPreview',
      path: '/mypage/coffeemap/preview',
      component: () => import('@/views/mypage/coffeemap/Preview.vue'),
      meta: {
        title: 'コーヒーマップ',
        bgColor: 'beige03Bg',
        template: VIEW_TEMPLATE.DETAIL
      }
    },
    {
      name: 'MypageCoffeeHistory',
      path: '/mypage/coffee-history',
      component: () => import('@/views/mypage/coffee-history/Index.vue'),
      meta: {
        title: 'コーヒー履歴（感想）',
        template: VIEW_TEMPLATE.COMMON
      }
    },
    {
      name: 'MypageCoffeeHistoryDetail',
      path: '/mypage/coffee-history/:impressionId',
      component: () => import('@/views/mypage/coffee-history/detail/Index.vue'),
      meta: {
        title: 'コーヒー履歴（感想） 詳細',
        hasHeadImage: true,
        template: VIEW_TEMPLATE.DETAIL
      }
    },
    {
      name: 'MypageMile',
      path: '/mypage/mile',
      component: () => import('@/views/mypage/mile/Index.vue'),
      meta: {
        title: 'マイル'
      }
    },
    {
      name: 'Notice',
      path: '/notice',
      component: () => import('@/views/notice/Index.vue'),
      meta: {
        title: 'お知らせ',
        template: VIEW_TEMPLATE.SINGLE
      }
    },
    {
      name: 'NoticeDetail',
      path: '/notice/detail/:id',
      component: () => import('@/views/notice/detail/Index.vue'),
      meta: {
        title: 'お知らせ詳細',
        bgColor: 'white01Bg',
        template: VIEW_TEMPLATE.SINGLE
      }
    },
    {
      name: 'MypageGift',
      path: '/mypage/gift',
      component: () => import('@/views/mypage/gift/Index.vue'),
      meta: {
        title: 'プレゼントBOX',
        template: VIEW_TEMPLATE.SINGLE
      }
    },
    {
      name: 'MypageGiftDetail',
      path: '/mypage/gift/detail/:id',
      component: () => import('@/views/mypage/gift/detail/Index.vue'),
      meta: {
        title: 'プレゼント詳細',
        template: VIEW_TEMPLATE.DETAIL
      }
    },
    {
      name: 'MypageMileAchives',
      path: '/mypage/mile/achives',
      component: () => import('@/views/mypage/mile/achives/Index.vue'),
      meta: {
        title: 'マイル特典',
        template: VIEW_TEMPLATE.SINGLE
      }
    },
    {
      name: 'MypageMileHistory',
      path: '/mypage/mile/history',
      component: () => import('@/views/mypage/mile/history/Index.vue'),
      meta: {
        title: 'マイル履歴',
        template: VIEW_TEMPLATE.SINGLE
      }
    },
    {
      name: 'Menu',
      path: '/menu',
      component: () => import('@/views/menu/Index.vue'),
      meta: {
        title: 'メニュー',
        template: VIEW_TEMPLATE.SINGLE
      }
    },
    {
      name: 'Help',
      path: '/help',
      component: () => import('@/views/help/Index.vue'),
      meta: {
        title: 'ヘルプ',
        template: VIEW_TEMPLATE.SINGLE
      }
    },
    {
      name: 'Version',
      path: '/version',
      component: () => import('@/views/version/Index.vue'),
      meta: {
        title: 'バージョン情報',
        bgColor: 'white01Bg',
        template: VIEW_TEMPLATE.SINGLE
      }
    },
    {
      name: 'Terms',
      path: '/terms',
      component: () => import('@/views/terms/Index.vue'),
      meta: {
        title: '利用規約',
        bgColor: 'white01Bg',
        template: VIEW_TEMPLATE.SINGLE
      }
    },
    {
      name: 'Takeover',
      path: '/takeover',
      component: () => import('@/views/takeover/Index.vue'),
      meta: {
        title: 'アカウント引継',
        bgColor: 'white01Bg',
        template: VIEW_TEMPLATE.SINGLE
      }
    },
    {
      name: 'Contact',
      path: '/contact',
      component: () => import('@/views/contact/Index.vue'),
      meta: {
        title: 'お問い合わせ',
        bgColor: 'white01Bg',
        template: VIEW_TEMPLATE.SINGLE
      }
    },
    {
      name: 'LinkOnlinestore',
      path: '/link/onlinestore',
      component: () => import('@/views/link/onlinestore/Index.vue'),
      meta: {
        title: 'オンラインストア連携',
        bgColor: 'white01Bg',
        template: VIEW_TEMPLATE.SINGLE
      }
    },
    {
      name: 'LinkLine',
      path: '/link/line',
      component: () => import('@/views/link/line/Index.vue'),
      meta: {
        title: 'LINE連携',
        bgColor: 'white01Bg',
        template: VIEW_TEMPLATE.SINGLE
      }
    },
    {
      name: 'LinkLineError',
      path: '/link/line/error',
      component: () => import('@/views/link/line/error/Index.vue'),
      meta: {
        title: 'LINE連携 エラー',
        bgColor: 'white01Bg',
        template: VIEW_TEMPLATE.SINGLE
      }
    },
    {
      name: 'LinkLineErrorBlock',
      path: '/link/line/error_block',
      component: () => import('@/views/link/line/error-block/Index.vue'),
      meta: {
        title: 'LINE連携 友だちを追加',
        bgColor: 'white01Bg',
        template: VIEW_TEMPLATE.SINGLE
      }
    },
    {
      name: 'Stage',
      path: '/stage',
      component: () => import('@/views/stage/Index.vue'),
      meta: {
        title: 'ステージ一覧',
        bgColor: 'accent01Bg',
        template: VIEW_TEMPLATE.DETAIL
      }
    },
    {
      name: 'ProDetail',
      path: '/pro/detail/:id',
      component: () => import('@/views/pro/detail/Index.vue'),
      meta: {
        title: 'プロ詳細',
        bgColor: 'white01Bg',
        template: VIEW_TEMPLATE.DETAIL
      }
    },
    {
      name: 'VideoDetail',
      path: '/video/detail/:id',
      component: () => import('@/views/video/detail/Index.vue'),
      meta: {
        title: '動画詳細',
        template: VIEW_TEMPLATE.DETAIL
      }
    },
    {
      name: 'MypageFavorite',
      path: '/mypage/favorite',
      component: () => import('@/views/mypage/favorite/Index.vue'),
      meta: {
        title: 'お気に入り',
        template: VIEW_TEMPLATE.SINGLE
      }
    }
  ]
};

const DP_BREW_NAVIGATION = [
  {
    label: 'History',
    to: 'DripPodBrewHistory'
  },
  {
    label: 'My Recipe',
    to: 'DripPodBrewMyrecipe'
  }
];

export const DRIP_POD_PAGES = {
  path: '/drip-pod',
  component: () => import('@/layouts/DripPod.vue'),
  children: [
    {
      name: 'DripPodIndex',
      path: '/drip-pod',
      component: () => import('@/views/drip-pod/Index.vue'),
      meta: {
        title: 'DRIP POD',
        isActiveDetailTitle: false,
        template: VIEW_TEMPLATE.DP_TOP,
        isActiveHeaderBackButton: false,
        isActiveHeaderCloseButton: false
      }
    },
    {
      name: 'DripPodIntroduction',
      path: '/drip-pod/introduction',
      component: () => import('@/views/drip-pod/introduction/Index.vue'),
      meta: {
        title: 'DRIP POD YOUBI',
        isActiveDetailTitle: false,
        template: VIEW_TEMPLATE.SINGLE,
        isActiveHeaderBackButton: false,
        isActiveHeaderCloseButton: false
      }
    },
    {
      name: 'DripPodProRecipe',
      path: '/drip-pod/pro-recipe',
      component: () => import('@/views/drip-pod/pro-recipe/Index.vue'),
      meta: {
        title: 'レシピを選ぶ',
        isActiveDetailTitle: false,
        template: VIEW_TEMPLATE.DP_COMMON,
        isActiveHeaderBackButton: true,
        isActiveHeaderCloseButton: true
      }
    },
    {
      name: 'DripPodProRecipeByCapsuleId',
      path: '/drip-pod/pro-recipe/capsule/:capsuleId',
      component: () => import('@/views/drip-pod/pro-recipe/Index.vue'),
      meta: {
        title: 'レシピを選ぶ',
        isActiveDetailTitle: false,
        template: VIEW_TEMPLATE.DP_COMMON,
        isActiveHeaderBackButton: true,
        isActiveHeaderCloseButton: true
      }
    },
    {
      name: 'DripPodProRecipeDetail',
      path: '/drip-pod/pro-recipe/detail/:proRecipeId',
      component: () => import('@/views/drip-pod/pro-recipe/detail/Index.vue'),
      meta: {
        title: '',
        isActiveDetailTitle: true,
        template: VIEW_TEMPLATE.DP_COMMON,
        isActiveHeaderBackButton: true,
        isActiveHeaderCloseButton: true
      }
    },
    {
      name: 'DripPodProRecipeBrewing',
      path: '/drip-pod/pro-recipe/brewing/:proRecipeId',
      component: () => import('@/views/drip-pod/pro-recipe/brewing/Index.vue'),
      meta: {
        title: '抽出中',
        isActiveDetailTitle: true,
        template: VIEW_TEMPLATE.DP_COMMON,
        isActiveHeaderBackButton: false,
        isActiveHeaderCloseButton: true
      }
    },
    {
      name: 'DripPodProRecipeTasteMapOverview',
      path: '/drip-pod/pro-recipe/taste-map-overview',
      component: () =>
        import('@/views/drip-pod/pro-recipe/taste-map-overview/Index.vue'),
      meta: {
        title: 'What is TASTE MAP?',
        isActiveDetailTitle: false,
        template: VIEW_TEMPLATE.DP_COMMON,
        isActiveHeaderBackButton: true,
        isActiveHeaderCloseButton: true
      }
    },
    {
      name: 'DripPodProFavoriteOverview',
      path: '/drip-pod/favorite-overview',
      component: () => import('@/views/drip-pod/favorite-overview/Index.vue'),
      meta: {
        title: '本体登録レシピとは？',
        isActiveDetailTitle: false,
        template: VIEW_TEMPLATE.DP_COMMON,
        isActiveHeaderBackButton: false,
        isActiveHeaderCloseButton: true
      }
    },
    {
      name: 'DripPodCapsule',
      path: '/drip-pod/capsule',
      component: () => import('@/views/drip-pod/capsule/Index.vue'),
      meta: {
        title: 'カプセルから選ぶ',
        isActiveDetailTitle: false,
        template: VIEW_TEMPLATE.DP_COMMON,
        isActiveHeaderBackButton: false,
        isActiveHeaderCloseButton: true
      }
    },
    {
      name: 'DripPodBrew',
      path: '/drip-pod/brew',
      component: () => import('@/views/drip-pod/brew/Index.vue'),
      meta: {
        title: '抽出履歴・保存したレシピ',
        isActiveDetailTitle: false,
        template: VIEW_TEMPLATE.DP_COMMON,
        isActiveHeaderBackButton: false,
        isActiveHeaderCloseButton: true,
        navigation: [...DP_BREW_NAVIGATION]
      }
    },
    {
      name: 'DripPodBrewHistory',
      path: '/drip-pod/brew/history',
      component: () => import('@/views/drip-pod/brew/Index.vue'),
      meta: {
        title: '抽出履歴・保存したレシピ',
        isActiveDetailTitle: false,
        template: VIEW_TEMPLATE.DP_COMMON,
        isActiveHeaderBackButton: false,
        isActiveHeaderCloseButton: true,
        navigation: [...DP_BREW_NAVIGATION]
      }
    },
    {
      name: 'DripPodBrewMyrecipe',
      path: '/drip-pod/brew/my-recipe',
      component: () => import('@/views/drip-pod/brew/Index.vue'),
      meta: {
        title: '抽出履歴・保存したレシピ',
        isActiveDetailTitle: false,
        template: VIEW_TEMPLATE.DP_COMMON,
        isActiveHeaderBackButton: false,
        isActiveHeaderCloseButton: true,
        navigation: [...DP_BREW_NAVIGATION]
      }
    }
  ]
};

// debug
const DEBUG_PAGE = {
  name: 'Debug',
  path: '/debug',
  component: () => import('@/views/Debug.vue'),
  meta: {
    title: 'デバッグ'
  }
};

// ページリスト
const PAGE_LIST = {
  name: 'PageList',
  path: '/page-list',
  component: () => import('@/views/PageList.vue'),
  meta: {
    title: 'ページリスト'
  }
};

// 404 Not found
const NOT_FOUND = {
  name: 'NotFound',
  path: '*',
  component: () => import('@/views/NotFound.vue'),
  meta: {
    title: '404 Not Found'
  }
};

// productionモード以外で出力
if (!isPrd)
  routes = [
    ...routes,
    COFFEE_CREATION_PAGES,
    DRIP_POD_PAGES,
    DEBUG_PAGE,
    PAGE_LIST,
    NOT_FOUND
  ];
else routes = [...routes, COFFEE_CREATION_PAGES, DRIP_POD_PAGES, NOT_FOUND];

const devPages = [];
devPages.push(DEBUG_PAGE, PAGE_LIST);
export const DEV_PAGES = devPages;

export const ROUTES = routes;
