var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-coffeeCard" }, [
    _c(
      "a",
      {
        directives: [
          {
            name: "ripple",
            rawName: "v-ripple",
            value: _vm.isLinked,
            expression: "isLinked",
          },
        ],
        style: !_vm.isLinked ? "pointer-events: none" : "",
        attrs: { href: _vm.href },
      },
      [
        _c("div", {
          staticClass: "c-coffeeCard__img",
          style: "background-image: url(" + _vm.src + ")",
        }),
        _c("div", { staticClass: "c-coffeeCard__content" }, [
          _vm.barista
            ? _c("div", { staticClass: "c-coffeeCard__contentHead" }, [
                _vm.barista["image_url"]
                  ? _c(
                      "div",
                      { staticClass: "c-coffeeCard__contentHead__img" },
                      [
                        _c("ProAvatar", {
                          attrs: {
                            size: 24,
                            src: _vm.barista["image_url"],
                            alt: "",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("p", { staticClass: "c-coffeeCard__contentHead__name" }, [
                  _vm._v(" " + _vm._s(_vm.barista.name) + " "),
                ]),
              ])
            : _vm._e(),
          _vm.title
            ? _c(
                "div",
                {
                  staticClass: "c-coffeeCard__contentTitle",
                  class: _vm.titleClasses,
                },
                [_vm._v(" " + _vm._s(_vm.title) + " ")]
              )
            : _vm._e(),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }