const data = [
  {
    id: 1,
    name: 'ブラック無糖コーヒー（PRO）',
    image_url: 'https://via.placeholder.com/750x460/0bc/fff?text=PRO',
    map_position: 36,
    map_zone: 'D',
    description:
      'レギュラーコーヒー100%、香りとコクを最大限引き出す高級豆を使用。ブラック無糖はUCC。香料は使用せず、コーヒー本来のおいしさを追求し、香りとキレが際立った無糖コーヒーにこだわりました。',
    ec_link_url: 'https://example.com',
    ingredients: [
      {
        id: 1,
        name: 'PROコーヒーに使用されているコーヒー名PROコーヒーに使用されているコーヒー名',
        description: '説明',
        image_url:
          'https://via.placeholder.com/550x460/ff7a00/fff?text=PRO%20COFFEE',
        ec_link_url: 'https://store.ucc.co.jp/category/ITEM_60/GSP1301006.html'
      },
      {
        id: 2,
        name: 'PROコーヒーに使用されているコーヒー名２PROコーヒーに使用されているコーヒー名２',
        description: '説明',
        image_url:
          'https://via.placeholder.com/550x460/5a9d1d/fff?text=PRO%20COFFEE2',
        ec_link_url: 'https://store.ucc.co.jp/category/ITEM_60/GSP1301006.html'
      },
      {
        id: null,
        name: '手入力されたコーヒー名',
        description: null,
        image_url: null,
        ec_link_url: null
      },
      {
        id: null,
        name: '手入力されたコーヒー名２',
        description: null,
        image_url: null,
        ec_link_url: null
      }
    ],
    baristas: [
      {
        id: 6,
        name: '木次 日向子',
        name_alphabetical: 'Hinako Kitsugi',
        overview: '',
        image_url: 'https://www.ufs.co.jp/professionals/img/kitsugi.png'
      },
      {
        id: 9,
        name: 'テストのプロテストのプロテストのプロテストのプロテストのプロテストのプロテストのプロ',
        name_alphabetical:
          'test no protest no protest no protest no protest no protest no protest no protest no protest no pro',
        overview:
          '経歴ですね\r\n経歴ですが\r\n経歴の経歴です\r\n\r\nとてつも長く果てしない地平線の如く続き時には疾く時にはゆっくりと展開する不死鳥のように止まることを知らない呼吸が辛そうな経歴のひとつ',
        image_url: null
      }
    ],
    barista_message:
      'ほんのり苦味があって、コクを感じる風味深いオリジナルブレンド。ほんのり苦味があって、コクを感じる風味深いオリジナルブレンド。\n深い味わいをぜひお楽しみください^^',
    impressions: [
      {
        id: 2,
        is_favorable: true,
        created_at: 1627298048,
        memo: {
          aroma: 100,
          bitterness: 200,
          sourness: 300,
          sweetness: 100,
          richness: 200,
          comment: '美味しかった'
        }
      },
      {
        id: 1,
        is_favorable: false,
        created_at: 1627298048,
        memo: {
          aroma: 100,
          bitterness: 200,
          sourness: 300,
          sweetness: 100,
          richness: 200,
          comment: 'うーん...'
        }
      }
    ],
    impression_count: {
      is_like: 3,
      is_hate: 0
    }
  },
  {
    id: 2,
    name: 'ブラック無糖コーヒー',
    image_url: 'https://via.placeholder.com/750x460/0bc/fff',
    map_position: 36,
    map_zone: 'D',
    description:
      'レギュラーコーヒー100%、香りとコクを最大限引き出す高級豆を使用。ブラック無糖はUCC。香料は使用せず、コーヒー本来のおいしさを追求し、香りとキレが際立った無糖コーヒーにこだわりました。',
    ec_link_url: 'https://example.com',
    ingredients: [],
    baristas: [],
    barista_message: null,
    impressions: [],
    impression_count: {
      is_like: 0,
      is_hate: 0
    }
  },
  {
    id: 3,
    name: 'ブラック無糖コーヒー（PRO・マップなし）',
    image_url: 'https://via.placeholder.com/750x460/0bc/fff?text=PRO',
    map_position: null,
    map_zone: null,
    description:
      'レギュラーコーヒー100%、香りとコクを最大限引き出す高級豆を使用。ブラック無糖はUCC。香料は使用せず、コーヒー本来のおいしさを追求し、香りとキレが際立った無糖コーヒーにこだわりました。',
    ec_link_url: 'https://example.com',
    ingredients: [
      {
        id: 1,
        name: 'PROコーヒーに使用されているコーヒー名PROコーヒーに使用されているコーヒー名',
        description: '説明',
        image_url:
          'https://via.placeholder.com/550x460/ff7a00/fff?text=PRO%20COFFEE',
        ec_link_url: 'https://store.ucc.co.jp/category/ITEM_60/GSP1301006.html'
      },
      {
        id: null,
        name: '手入力されたコーヒー名',
        description: null,
        image_url: null,
        ec_link_url: null
      }
    ],
    baristas: [
      {
        id: 1,
        name: '中井 千香子',
        name_alphabetical: 'Chikako Nakai',
        overview: 'ジャパン サイフォニスト チャンピオンシップ2019 優勝',
        image_url: 'https://via.placeholder.com/128x128/3c4/fff'
      }
    ],
    barista_message:
      'ほんのり苦味があって、コクを感じる風味深いオリジナルブレンド。ほんのり苦味があって、コクを感じる風味深いオリジナルブレンド。\n深い味わいをぜひお楽しみください^^',
    impressions: [
      {
        id: 2,
        is_favorable: null,
        created_at: 1627298048,
        memo: {
          aroma: 100,
          bitterness: 200,
          sourness: 300,
          sweetness: 100,
          richness: 200,
          comment: '美味しかった'
        }
      },
      {
        id: 1,
        is_favorable: null,
        created_at: 1627298048,
        memo: {
          aroma: 100,
          bitterness: 200,
          sourness: 300,
          sweetness: 100,
          richness: 200,
          comment: 'うーん...'
        }
      }
    ],
    impression_count: {
      is_like: 1,
      is_hate: 5
    }
  },
  {
    id: 4,
    name: 'ブラック無糖コーヒー（マップなし）',
    image_url: 'https://via.placeholder.com/750x460/0bc/fff',
    map_position: null,
    map_zone: null,
    description:
      'レギュラーコーヒー100%、香りとコクを最大限引き出す高級豆を使用。ブラック無糖はUCC。香料は使用せず、コーヒー本来のおいしさを追求し、香りとキレが際立った無糖コーヒーにこだわりました。',
    ec_link_url: 'https://example.com',
    ingredients: [],
    baristas: [],
    barista_message: null,
    impressions: [],
    impression_count: {
      is_like: 0,
      is_hate: 0
    }
  }
];

export { data };
