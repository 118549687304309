var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "c-card" }, [
      _c(
        "ul",
        { staticClass: "icons" },
        _vm._l(_vm.$icons, function (item, i) {
          return _c(
            "li",
            { key: i },
            [
              _c("Icon", { attrs: { name: item.name } }),
              _c("p", [_vm._v(_vm._s(item.name))]),
            ],
            1
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }