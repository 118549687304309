import { orderBy, random, sample, cloneDeep } from 'lodash';

const ITEM_MASTER = [
  {
    id: 1,
    is_favorable: 1,
    product: {
      id: 101,
      name: 'COFFEE CREATION スペシャル',
      image_url: 'https://placehold.jp/5a2727/ffffff/88x88.png',
      description:
        '沖縄県産黒糖をふんだんに使用したミルク珈琲。すっきりとした黒糖の甘みが沖縄県産黒糖をふんだんに使用したミルク珈琲。すっきりとした黒糖の甘みが'
    },
    barista: {
      name: '中井 千香子 (Chikako Nakai)',
      image_url: 'https://placehold.jp/46aecf/ffffff/50x50.png?text=PRO'
    }
  },
  {
    id: 2,
    is_favorable: 1,
    product: {
      id: 102,
      name: 'UCC MOUNTAIN MIST ペルー インカワシ組合（豆）200g',
      image_url: 'https://placehold.jp/44a4fc/ffffff/150x100.png',
      description:
        'マチュピチュの玄関口であるクスコ地区のインカワシ組合に属するケチュワ族マチュピチュの玄関口であるクスコ地区のインカワシ組合に属するケチュワ族'
    }
  },
  {
    id: 3,
    is_favorable: 0,
    product: {
      id: 104,
      name: 'UCC おいしいカフェインレスコーヒー コーヒーバッグ 水出しアイスコーヒー',
      image_url: 'https://placehold.jp/56b98a/ffffff/100x150.png',
      description:
        'コーヒー好きを満足させる、おいしいカフェインレスコーヒー、原材料製法にこだわりました。厳選アラビカ豆100%使用、超臨界二酸化炭素抽出法にてカフェインを安全に97%カットしました。コーヒーバッグをポットに一晩浸けるだけでカフェインレスながらも風味豊かでコクのある味わいの本格アイスコーヒーを実現、微粉を通しにくいフィルターを使用していますので、クリアでなめらかな喉ごしのよさも実感頂けます。'
    }
  }
];

const factory = (id, isFavorable, createdAt) => {
  const item = cloneDeep(sample(ITEM_MASTER));
  item.id = id;
  item.is_favorable = isFavorable;
  item.created_at = createdAt;
  return item;
};

const DATA_LENGTH = 35;
const RANGE_FROM = new Date(2021, 9, 1).getTime();
const RANGE_TO = new Date(2021, 11, 31).getTime();

const data = orderBy(
  [...new Array(DATA_LENGTH)].map((_, i) =>
    factory(i + 1, random(0, 1), random(RANGE_FROM, RANGE_TO))
  ),
  ['created_at'],
  'desc'
);

export { data };
