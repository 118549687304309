import Banner from './Banner.vue';

export default {
  title: 'SHARED/Banner',
  component: Banner
};

const Template = (args, { argTypes }) => ({
  components: { Banner },
  props: Object.keys(argTypes),
  template: '<Banner v-bind="$props" />'
});

export const Default = Template.bind({});
Default.args = {
  alt: 'DRIP PODアプリはこちら',
  src: '/assets/img/banner_drippod01.png',
  href: 'coffee-creation-app://page/standalone/?url=/drip-pod/introduction',
  disabled: false
};
