import { dialog } from '@/plugins/customUrlScheme';

/**
 * @example
 * $onlineStoreDialog('taste-diagnose', 0)
 */
export default (_, inject) =>
  inject('onlineStoreDialog', {
    /**
     * オンラインストアの連携状態
     * @param {string} type 'taste-diagnose' | 'coffeemap-tutorial'
     * @param {number} status 0 = 未連携 | 1 = 連携済み
     * @returns {string}
     */
    connect(type, status) {
      if (type !== 'taste-diagnose' && type !== 'coffeemap-tutorial') {
        _.$log.error('onlineStoreDialog: type が正しくありません');
        return;
      }
      if (status !== 0 && status !== 1) {
        _.$log.error('onlineStoreDialog: status が正しくありません');
        return;
      }
      return dialog(type, 'eclink=' + status);
    }
  });
