/**
 * ステージ一覧
 */
export class MileStageType {
  static HAWAII = 1;
  static MEXICO = 2;
  static JAMAICA = 3;
  static GUATEMALA = 4;
  static EL_SALVADOR = 5;
  static HONDURAS = 6;
  static COSTA_RICA = 7;
  static PANAMA = 8;
  static COLUMBIA = 9;
  static PERU = 10;
  static BRAZIL = 11;
  static TANZANIA = 12;
  static RWANDA = 13;
  static KENYA = 14;
  static ETHIOPIA = 15;
  static YEMEN = 16;
  static REUNION = 17;
  static INDIA = 18;
  static CHINA = 19;
  static THAILAND = 20;
  static VIETNAM = 21;
  static INDONESIA = 22;
  static TAIWAN = 23;
  static JAPAN = 24;

  /**
   * ユニークステージ数
   */
  static BASE_STAGE_SIZE = 24;

  /**
   * タイプIDからタイプ名を取得する
   * @param {number} type
   * @param {string} [lang='en']
   * @returns {string | undefined}
   */
  static getTypeName = (type, lang = 'en') => {
    if (lang === 'ja') {
      switch (type) {
        case this.HAWAII:
          return 'ハワイ';
        case this.MEXICO:
          return 'メキシコ';
        case this.JAMAICA:
          return 'ジャマイカ';
        case this.GUATEMALA:
          return 'グアテマラ';
        case this.EL_SALVADOR:
          return 'エルサルバドル';
        case this.HONDURAS:
          return 'ホンジュラス';
        case this.COSTA_RICA:
          return 'コスタリカ';
        case this.PANAMA:
          return 'パナマ';
        case this.COLUMBIA:
          return 'コロンビア';
        case this.PERU:
          return 'ペルー';
        case this.BRAZIL:
          return 'ブラジル';
        case this.TANZANIA:
          return 'タンザニア';
        case this.RWANDA:
          return 'ルワンダ';
        case this.KENYA:
          return 'ケニア';
        case this.ETHIOPIA:
          return 'エチオピア';
        case this.YEMEN:
          return 'イエメン';
        case this.REUNION:
          return 'レ・ユニオン島';
        case this.INDIA:
          return 'インド';
        case this.CHINA:
          return '中国';
        case this.THAILAND:
          return 'タイ';
        case this.VIETNAM:
          return 'ベトナム';
        case this.INDONESIA:
          return 'インドネシア';
        case this.TAIWAN:
          return '台湾';
        case this.JAPAN:
          return '日本';
      }
    }

    switch (type) {
      case this.HAWAII:
        return 'Hawaii';
      case this.MEXICO:
        return 'Mexico';
      case this.JAMAICA:
        return 'Jamaica';
      case this.GUATEMALA:
        return 'Guatemala';
      case this.EL_SALVADOR:
        return 'El Salvador';
      case this.HONDURAS:
        return 'Honduras';
      case this.COSTA_RICA:
        return 'Costa Rica';
      case this.PANAMA:
        return 'Panama';
      case this.COLUMBIA:
        return 'Columbia';
      case this.PERU:
        return 'Peru';
      case this.BRAZIL:
        return 'Brazil';
      case this.TANZANIA:
        return 'Tanzania';
      case this.RWANDA:
        return 'Rwanda';
      case this.KENYA:
        return 'Kenya';
      case this.ETHIOPIA:
        return 'Ethiopia';
      case this.YEMEN:
        return 'Yemen';
      case this.REUNION:
        return 'Reunion';
      case this.INDIA:
        return 'India';
      case this.CHINA:
        return 'China';
      case this.THAILAND:
        return 'Thailand';
      case this.VIETNAM:
        return 'Vietnam';
      case this.INDONESIA:
        return 'Indonesia';
      case this.TAIWAN:
        return 'Taiwan';
      case this.JAPAN:
        return 'Japan';
    }
  };

  /**
   * マイルステージのIDからKeyを取得する
   * マイルステージのIDは周回分の数も含むため、2周目のハワイであってもKeyは1とする
   * @param {number} id
   */
  static getMileStageTypeKeyById = (id) => {
    if (!id) return;
    return id % this.BASE_STAGE_SIZE || this.BASE_STAGE_SIZE;
  };
}
