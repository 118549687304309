// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "", ""]);
// Exports
exports.locals = {
	"brand-primary01": "#d10000",
	"brand-secondary01": "#2e5851",
	"brand-accent01": "#46aecf",
	"brand-accent02": "#ff7a00",
	"brand-error": "#ff0014",
	"common-blue01": "#46aecf",
	"common-green01": "#5a9d1d",
	"common-green02": "#56b98a",
	"common-orange01": "#ff7a00",
	"common-lightenorange01": "#ffebd9",
	"common-beige01": "#696458",
	"common-beige02": "#cec8ba",
	"common-beige03": "#f2ecde",
	"common-grey01": "#7f808b",
	"common-grey02": "#9d9da8",
	"common-grey03": "#cdcdcf",
	"common-grey04": "#f0f0f0",
	"common-black01": "#373841",
	"common-white01": "#fcfcfc",
	"dp-primary": "#df6800",
	"dp-error": "#ff0014",
	"dp-red01": "#c90011",
	"dp-black01": "#000",
	"dp-grey01": "#444",
	"dp-grey02": "#666",
	"dp-grey03": "#888",
	"dp-grey04": "#aaa",
	"dp-grey05": "#ccc",
	"dp-grey06": "#eaeaea",
	"dp-white01": "#fff"
};
module.exports = exports;
