import Tag from './Tag.vue';

export default {
  title: 'SHARED/Tag',
  component: Tag
};

const Template = (args, { argTypes }) => ({
  components: { Tag },
  props: Object.keys(argTypes),
  template: '<Tag v-bind="$props" />'
});

export const Default = Template.bind({});
Default.args = {
  tag: 'タグ'
};

export const Ellipsis = Template.bind({});
Ellipsis.args = {
  tag: 'とても長いタグ'
};
