import Vue from 'vue';

Vue.mixin({
  computed: {
    /**
     * ローディング状態
     * @returns {boolean}
     */
    $_loading_state() {
      return this.$root.$data.isLoading;
    }
  },

  methods: {
    /**
     * ローディングを開始する
     */
    $_loading_start() {
      this.$root.$data.isLoading = true;
    },

    /**
     * ローディングを終了する
     */
    $_loading_stop() {
      this.$root.$data.isLoading = false;
    }
  }
});
