var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pro-avatar", style: _vm.blockStyle }, [
    _c("div", { staticClass: "pro-avatar__wrapper", style: _vm.wrapperStyle }, [
      _vm.src
        ? _c("div", { staticClass: "pro-avatar__img", style: _vm.imgStyle }, [
            _c("img", { attrs: { src: _vm.src, alt: _vm.alt } }),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "pro-avatar__bg", style: _vm.bgStyle },
        [
          _c("Icon", {
            attrs: {
              name: "person",
              color: "white01",
              width: _vm.size,
              height: _vm.size,
            },
          }),
        ],
        1
      ),
    ]),
    _vm.isFavorite
      ? _c(
          "div",
          { staticClass: "pro-avatar__favorite", style: _vm.favoriteStyle },
          [
            _c("Icon", {
              attrs: {
                name: "heartCircleOn",
                color: "primary01",
                width: _vm.favoriteSize,
                height: _vm.favoriteSize,
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }