export const data = {
  data: {
    banner_area: [
      {
        id: 1,
        image_url: 'https://placehold.jp/4bb/fff/686x200.png?text=WHITELIST',
        title: '【vol.12】サンフランシスコの進化系カフェ＆レストラン！',
        link_url: 'https://www.ucc.co.jp/',
        subtitle:
          'My COFFEE STYLE MAGAZINEMy COFFEE STYLE MAGAZINEMy COFFEE STYLE MAGAZINEMy COFFEE STYLE MAGAZINE'
      },
      {
        id: 2,
        image_url: 'https://via.placeholder.com/686x200.png?text=EVENT',
        title: '【vol.12】サンフランシスコの進化系カフェ＆レストラン！',
        link_url:
          'coffee-creation-app://page/detail/?url=/coffee/detail/11?event=1',
        subtitle:
          'My COFFEE STYLE MAGAZINEMy COFFEE STYLE MAGAZINEMy COFFEE STYLE MAGAZINEMy COFFEE STYLE MAGAZINE'
      }
    ],
    big_area: [
      {
        id: 1,
        image_url: 'https://via.placeholder.com/710x348/0bc/fff',
        title:
          '【vol.12】サンフランシスコの進化系カフェ＆レストラン！【vol.12】サンフランシスコの進化系カフェ＆レストラン！',
        link_url: 'coffee-creation-app://page/detail/?url=/coffee/detail/2',
        subtitle: 'My COFFEE STYLE MAGAZINE',
        tag: '飲み物飲み物',
        reward: [
          {
            name: 'マイルゲット',
            description: 'マイルプレゼント',
            warning: '注意事項',
            type: 1,
            amount: 5,
            coupon_code: 'a302306ea8bb727711819772c974071b',
            image_url: 'https://example.com/example.jpg'
          },
          {
            name: 'マイルゲット',
            description: 'マイルプレゼント',
            warning: '注意事項',
            type: 1,
            amount: 10,
            coupon_code: 'a302306ea8bb727711819772c974071b',
            image_url: 'https://example.com/example.jpg'
          }
        ]
      },
      {
        id: 2,
        image_url: 'https://via.placeholder.com/200x200',
        title: '【vol.12】サンフランシスコの',
        link_url: 'https://example.com/article/1/',
        subtitle: 'My COFFEE STYLE MAGAZINE',
        tag: '読み物',
        reward: [
          {
            name: 'マイルゲット',
            description: 'マイルプレゼント',
            warning: '注意事項',
            type: 1,
            amount: 5,
            coupon_code: 'a302306ea8bb727711819772c974071b',
            image_url: 'https://example.com/example.jpg'
          }
        ]
      },
      {
        id: 3,
        image_url: 'https://via.placeholder.com/340x686/da3/fff',
        title: '【vol.12】サンフランシスコの進化系カフェ＆レストラン！',
        link_url: '',
        subtitle: 'My COFFEE STYLE MAGAZINE',
        tag: 'イベント',
        reward: [
          {
            name: 'マイルゲット',
            description: 'マイルプレゼント',
            warning: '注意事項',
            type: 2,
            amount: 5,
            coupon_code: 'a302306ea8bb727711819772c974071b',
            image_url: 'https://example.com/example.jpg'
          },
          {
            name: 'マイルゲット',
            description: 'マイルプレゼント',
            warning: '注意事項',
            type: 1,
            amount: 10,
            coupon_code: 'a302306ea8bb727711819772c974071b',
            image_url: 'https://example.com/example.jpg'
          }
        ],
        event_id: 1
      },
      {
        id: 4,
        image_url: 'https://via.placeholder.com/710x348',
        title: 'PRD オンラインストア',
        link_url: 'https://store.ucc.co.jp',
        subtitle: 'My COFFEE STYLE MAGAZINE',
        tag: '読み物',
        reward: [
          {
            name: 'マイルゲット',
            description: 'マイルプレゼント',
            warning: '注意事項',
            type: 1,
            amount: 5,
            coupon_code: 'a302306ea8bb727711819772c974071b',
            image_url: 'https://example.com/example.jpg'
          },
          {
            name: 'マイルゲット',
            description: 'マイルプレゼント',
            warning: '注意事項',
            type: 1,
            amount: 10,
            coupon_code: 'a302306ea8bb727711819772c974071b',
            image_url: 'https://example.com/example.jpg'
          }
        ]
      }
    ],
    pro_coffee_area: [
      {
        id: 82,
        name: 'プロ複数人のプロコーヒー',
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/product/pro-coffee/0IVc1OoS14q7I1q8kzq2mQiZWu9wfbv5htjRcLvu.png',
        description: '説明です',
        ec_link_url: null,
        barista_message: null,
        map_position: 0,
        map_zone: '',
        baristas: [
          {
            id: 15,
            name: '床本 佳織',
            name_alphabetical: 'Kaori Tokomoto',
            overview:
              '・UCCコーヒーアドバイザー・UCCコーヒー抽出士\n・(アメリカ)CQI認定Qアラビカグレーダー',
            image_url:
              'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/barista/instructor-14.jpg',
            is_favorite: true
          },
          {
            id: 16,
            name: '中井千香子',
            name_alphabetical: 'Chikako Nakai',
            overview:
              '・UCCコーヒーマスターズ2016　サイフォン部門　優勝・ジャパンブリューワーズカップ2018　優勝\n・ワールドブリューワーズカップ2019　第4位\n・ジャパンサイフォニストチャンピオンシップ2019　優勝\n・(アメリカ)CQI認定Qアラビカグレーダー',
            image_url:
              'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/barista/instructor-15.jpg',
            is_favorite: true
          },
          {
            id: 13,
            name: '清水 美保',
            name_alphabetical: 'Miho Shimizu',
            overview:
              '・UCCコーヒーアドバイザー・UCCコーヒー抽出士\n・(アメリカ)CQI認定Qアラビカグレーダー',
            image_url:
              'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/barista/instructor-12.jpg',
            is_favorite: false
          },
          {
            id: 11,
            name: '宮前 みゆき',
            name_alphabetical: 'Miyuki Miyamae',
            overview:
              '・ジャパンバリスタチャンピオンシップ2007　優勝・ワールドバリスタチャンピオンシップ2007　4位\n・ベストカプチーノ賞　受賞',
            image_url:
              'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/barista/instructor-10.jpg',
            is_favorite: false
          },
          {
            id: 29,
            name: 'てすと2',
            name_alphabetical: 'aaa',
            overview: 'www\r\negw',
            image_url:
              'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/barista/qi9oJW7kpBmOBA592Sa4nKWYUJdCy2aE0D7rYFA1.jpg',
            is_favorite: false
          }
        ],
        impressions: [],
        impression_count: {
          is_like: 0,
          is_hate: 0
        },
        ingredients: [
          {
            id: 59,
            name: '上島珈琲店 炒り豆 W cracking Deep AP140g',
            description:
              '深炒りの合図である、第2クラックを待って、じっくりと深く焙煎。',
            image_url:
              'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/product/ueshima-roasted-beans-w-cracking-deep-ap-140g.jpg',
            ec_link_url: 'https://store.ucc.co.jp/item/UCT1202005.html'
          },
          {
            id: 53,
            name: 'CAFE＠HOME グッドナイトカフェインレス 10ｇ',
            description:
              'コーヒー本来の味わいや香りはそのままに、特別な製法でカフェインを除去。おやすみ前でも安心して楽しめる、心地よい苦みと深いコクのコーヒー。',
            image_url:
              'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/product/cah0102004_01_1_0_large.jpg',
            ec_link_url:
              'https://coffeestyle.jp/ja/show?product_code=cah0102004'
          },
          {
            id: 51,
            name: 'CAFE＠HOME スタートザデイ 10ｇ',
            description:
              '1日のはじまりに。しっかりとした苦味とフルーティな風味が清々しい目覚めを誘う、平日の朝にぴったりのコーヒー。ミルクと合わせてもおいしく楽しめます。',
            image_url:
              'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/product/cah0102002_01_1_0_large.jpg',
            ec_link_url:
              'https://coffeestyle.jp/ja/show?product_code=cah0102002'
          }
        ]
      },
      {
        id: 81,
        name: '中平 尚己プロコーヒー（ダミー）',
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/product/tankyu-charcoal-roast-pack.jpg',
        description:
          'すっきりとした苦味に、ハーブやスパイスのような香り。夕刻のリラックスタイムに、気持ちやすらぐ味わいです。ほんのりと漂うベルガモットは、食欲のわいてくる香りです。',
        ec_link_url: 'https://store.ucc.co.jp/item/UCT1202006.html',
        barista_message:
          '冬にぴったりのコーヒーレシピです！今回はいつも飲んでいるドリップコーヒーに一手間加えるだけで完成する簡単なアレンジコーヒーで暖まってくださいね。ダミー',
        map_position: 17,
        map_zone: 'B',
        baristas: [
          {
            id: 17,
            name: '中平 尚己',
            name_alphabetical: 'Naomi Nakahira',
            overview:
              '・UCCコーヒーアドバイザー・UCCコーヒー鑑定士\n・(ブラジル)コーヒー鑑定士\n・(アメリカ)CQI認定Qアラビカグレーダー',
            image_url:
              'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/barista/adjunct-instructor-1.jpg',
            is_favorite: false
          }
        ],
        impressions: [
          {
            id: 405,
            is_favorable: true,
            created_at: '2022-11-16T06:42:48.000000Z',
            memo: {
              aroma: '10',
              comment: '美味しかったです。',
              richness: '10',
              sourness: '10',
              sweetness: '10'
            }
          },
          {
            id: 404,
            is_favorable: true,
            created_at: '2022-11-16T06:42:48.000000Z',
            memo: {
              aroma: '10',
              comment: '美味しかったです。',
              richness: '10',
              sourness: '10',
              sweetness: '10'
            }
          },
          {
            id: 403,
            is_favorable: false,
            created_at: '2022-11-16T06:42:48.000000Z',
            memo: {
              aroma: '10',
              comment: '美味しかったです。',
              richness: '10',
              sourness: '10',
              sweetness: '10'
            }
          },
          {
            id: 402,
            is_favorable: true,
            created_at: '2022-11-16T06:42:48.000000Z',
            memo: {
              aroma: '10',
              comment: '美味しかったです。',
              richness: '10',
              sourness: '10',
              sweetness: '10'
            }
          },
          {
            id: 401,
            is_favorable: false,
            created_at: '2022-11-16T06:42:48.000000Z',
            memo: {
              aroma: '10',
              comment: '美味しかったです。',
              richness: '10',
              sourness: '10',
              sweetness: '10'
            }
          }
        ],
        impression_count: {
          is_like: 3,
          is_hate: 2
        },
        ingredients: [
          {
            id: 8,
            name: '職人の珈琲 深いコクのスペシャルブレンド',
            description:
              '豊かなコクのリッチな味わい。ブラックだけでなくミルクとも\nよく合うブレンド。',
            image_url:
              'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/product/meister-special-blend-pack.jpg',
            ec_link_url: 'https://store.ucc.co.jp/item/SHO1601005.html'
          },
          {
            id: null,
            name: 'マイブレンド体験　アロマ1',
            description: null,
            image_url: null,
            ec_link_url: null
          }
        ]
      },
      {
        id: 80,
        name: '中井千香子プロコーヒー（ダミー）',
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/product/tankyu-charcoal-roast-pack.jpg',
        description:
          'すっきりとした苦味に、ハーブやスパイスのような香り。夕刻のリラックスタイムに、気持ちやすらぐ味わいです。ほんのりと漂うベルガモットは、食欲のわいてくる香りです。',
        ec_link_url: 'https://store.ucc.co.jp/item/UCT1202006.html',
        barista_message:
          '冬にぴったりのコーヒーレシピです！今回はいつも飲んでいるドリップコーヒーに一手間加えるだけで完成する簡単なアレンジコーヒーで暖まってくださいね。ダミー',
        map_position: 16,
        map_zone: 'B',
        baristas: [
          {
            id: 16,
            name: '中井千香子',
            name_alphabetical: 'Chikako Nakai',
            overview:
              '・UCCコーヒーマスターズ2016　サイフォン部門　優勝・ジャパンブリューワーズカップ2018　優勝\n・ワールドブリューワーズカップ2019　第4位\n・ジャパンサイフォニストチャンピオンシップ2019　優勝\n・(アメリカ)CQI認定Qアラビカグレーダー',
            image_url:
              'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/barista/instructor-15.jpg',
            is_favorite: true
          }
        ],
        impressions: [
          {
            id: 400,
            is_favorable: true,
            created_at: '2022-11-16T06:42:48.000000Z',
            memo: {
              aroma: '10',
              comment: '美味しかったです。',
              richness: '10',
              sourness: '10',
              sweetness: '10'
            }
          },
          {
            id: 399,
            is_favorable: true,
            created_at: '2022-11-16T06:42:48.000000Z',
            memo: {
              aroma: '10',
              comment: '美味しかったです。',
              richness: '10',
              sourness: '10',
              sweetness: '10'
            }
          },
          {
            id: 398,
            is_favorable: false,
            created_at: '2022-11-16T06:42:48.000000Z',
            memo: {
              aroma: '10',
              comment: '美味しかったです。',
              richness: '10',
              sourness: '10',
              sweetness: '10'
            }
          },
          {
            id: 397,
            is_favorable: true,
            created_at: '2022-11-16T06:42:48.000000Z',
            memo: {
              aroma: '10',
              comment: '美味しかったです。',
              richness: '10',
              sourness: '10',
              sweetness: '10'
            }
          },
          {
            id: 396,
            is_favorable: true,
            created_at: '2022-11-16T06:42:48.000000Z',
            memo: {
              aroma: '10',
              comment: '美味しかったです。',
              richness: '10',
              sourness: '10',
              sweetness: '10'
            }
          }
        ],
        impression_count: {
          is_like: 4,
          is_hate: 1
        },
        ingredients: [
          {
            id: 7,
            name: '職人の珈琲 まろやか味のマイルドブレンド',
            description:
              'ブラック派にもおすすめの調和のとれた味わいのブレンド。',
            image_url:
              'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/product/meister-mild-blend-pack.jpg',
            ec_link_url: 'https://store.ucc.co.jp/item/SHO1601004.html'
          },
          {
            id: null,
            name: 'マイブレンド体験　アロマ1',
            description: null,
            image_url: null,
            ec_link_url: null
          }
        ]
      },
      {
        id: 79,
        name: '床本 佳織プロコーヒー（ダミー）',
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/product/tankyu-charcoal-roast-pack.jpg',
        description:
          'すっきりとした苦味に、ハーブやスパイスのような香り。夕刻のリラックスタイムに、気持ちやすらぐ味わいです。ほんのりと漂うベルガモットは、食欲のわいてくる香りです。',
        ec_link_url: 'https://store.ucc.co.jp/item/UCT1202006.html',
        barista_message:
          '冬にぴったりのコーヒーレシピです！今回はいつも飲んでいるドリップコーヒーに一手間加えるだけで完成する簡単なアレンジコーヒーで暖まってくださいね。ダミー',
        map_position: 15,
        map_zone: 'A',
        baristas: [
          {
            id: 15,
            name: '床本 佳織',
            name_alphabetical: 'Kaori Tokomoto',
            overview:
              '・UCCコーヒーアドバイザー・UCCコーヒー抽出士\n・(アメリカ)CQI認定Qアラビカグレーダー',
            image_url:
              'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/barista/instructor-14.jpg',
            is_favorite: true
          }
        ],
        impressions: [
          {
            id: 395,
            is_favorable: false,
            created_at: '2022-11-16T06:42:48.000000Z',
            memo: {
              aroma: '10',
              comment: '美味しかったです。',
              richness: '10',
              sourness: '10',
              sweetness: '10'
            }
          },
          {
            id: 394,
            is_favorable: true,
            created_at: '2022-11-16T06:42:48.000000Z',
            memo: {
              aroma: '10',
              comment: '美味しかったです。',
              richness: '10',
              sourness: '10',
              sweetness: '10'
            }
          },
          {
            id: 393,
            is_favorable: false,
            created_at: '2022-11-16T06:42:48.000000Z',
            memo: {
              aroma: '10',
              comment: '美味しかったです。',
              richness: '10',
              sourness: '10',
              sweetness: '10'
            }
          },
          {
            id: 392,
            is_favorable: false,
            created_at: '2022-11-16T06:42:48.000000Z',
            memo: {
              aroma: '10',
              comment: '美味しかったです。',
              richness: '10',
              sourness: '10',
              sweetness: '10'
            }
          },
          {
            id: 391,
            is_favorable: true,
            created_at: '2022-11-16T06:42:48.000000Z',
            memo: {
              aroma: '10',
              comment: '美味しかったです。',
              richness: '10',
              sourness: '10',
              sweetness: '10'
            }
          }
        ],
        impression_count: {
          is_like: 2,
          is_hate: 3
        },
        ingredients: [
          {
            id: 9,
            name: '職人の珈琲 あまい香りのリッチブレンド',
            description: 'あまい香りと、豊かな風味が特長のブレンド。',
            image_url:
              'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/product/meister-mocha-blend-pack.jpg',
            ec_link_url: 'https://store.ucc.co.jp/item/SHO1601006.html'
          },
          {
            id: null,
            name: 'マイブレンド体験　アロマ1',
            description: null,
            image_url: null,
            ec_link_url: null
          }
        ]
      }
    ],
    movie_area: [
      {
        id: 25,
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/movie/9xMB0XJo7yxLN8Szgd8KlKR7OCNlbDgBbCtVrgQ7.png',
        title:
          '【特別ゲスト第2弾】UCCアカデミーを体験！目指せ好みの味～オリジナルブレンドコーヒーを作ってみよう～',
        movie_url: 'https://youtu.be/WOVd5dkNWIo',
        description:
          '必見！池上彰さん、増田ユリヤさんの第2弾！\r\n今回は、各コーヒー単体での味を飲み比べてから、液体でのブレンドづくりに挑戦していただきました。さておふたりは、自分の好みの味が作れたのでしょうか？',
        baristas: [
          {
            id: 9,
            name: '村田 果穂',
            name_alphabetical: 'Miho Murata',
            overview:
              '・UCCコーヒーアドバイザー・ジャパン　ブリューワーズカップ　2014／2015　準優勝\r\n・(アメリカ)CQI認定Qアラビカグレーダー\r\n・(コロンビア)マイルドコーヒークオリティコントロールスペシャリスト\r\n・COE国際審査員',
            header: 2,
            short_message:
              '楽しくコーヒーを勉強してみませんか？体験を通じてわかりやすく説明いたします。是非、お気軽にお越しください！',
            notice: null,
            image_url: null,
            is_publishable: true,
            created_at: '2022-11-16T06:42:46.000000Z',
            updated_at: '2022-12-05T02:31:25.000000Z',
            pivot: {
              movie_id: 25,
              barista_id: 9
            }
          },
          {
            id: 28,
            name: 'てすと',
            name_alphabetical: 'test',
            overview: 'あああ\r\nいいい\r\nううう',
            header: 0,
            short_message: 'あああ',
            notice: 'あああ',
            image_url:
              'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/barista/eOcpQATnjeu6KLCebqkuU5VBDVoIfYO0CvlOL892.jpg',
            is_publishable: true,
            created_at: '2022-12-05T02:37:47.000000Z',
            updated_at: '2022-12-05T02:40:06.000000Z',
            pivot: {
              movie_id: 25,
              barista_id: 28
            }
          },
          {
            id: 29,
            name: 'てすと2',
            name_alphabetical: 'aaa',
            overview: 'www\r\negw',
            header: 0,
            short_message: 'wew',
            notice: 'eww',
            image_url:
              'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/barista/qi9oJW7kpBmOBA592Sa4nKWYUJdCy2aE0D7rYFA1.jpg',
            is_publishable: true,
            created_at: '2022-12-05T02:44:50.000000Z',
            updated_at: '2022-12-05T02:44:50.000000Z',
            pivot: {
              movie_id: 25,
              barista_id: 29
            }
          }
        ]
      },
      {
        id: 24,
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/movie/IbPzcOZKz7ARQtU5kbejSpmciTnP2P4tNvyfTca1.png',
        title: '【検証】コーヒーの淹れ方　お湯の温度で味は変わる？',
        movie_url: 'https://youtu.be/Ko0XwVpH5X0',
        description:
          'コーヒーを淹れるお湯の温度で味がどう変わるか、村田講師が検証します。\r\n注目は、温度で変わる酸味・苦味・甘さのバランス。\r\n温度計なしで最適温度にする、ちょっとしたコツも披露します！',
        baristas: [
          {
            id: 9,
            name: '村田 果穂',
            name_alphabetical: 'Miho Murata',
            overview:
              '・UCCコーヒーアドバイザー・ジャパン　ブリューワーズカップ　2014／2015　準優勝\r\n・(アメリカ)CQI認定Qアラビカグレーダー\r\n・(コロンビア)マイルドコーヒークオリティコントロールスペシャリスト\r\n・COE国際審査員',
            header: 2,
            short_message:
              '楽しくコーヒーを勉強してみませんか？体験を通じてわかりやすく説明いたします。是非、お気軽にお越しください！',
            notice: null,
            image_url: null,
            is_publishable: true,
            created_at: '2022-11-16T06:42:46.000000Z',
            updated_at: '2022-12-05T02:31:25.000000Z',
            pivot: {
              movie_id: 24,
              barista_id: 9
            }
          }
        ]
      },
      {
        id: 23,
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/movie/SVp9z7Dpz9iknJpJbbgcZsZzgZL7rLcn6jBnRV15.png',
        title: '【検証】ドリップは全体？１点集中？コーヒーの味どう変わる？',
        movie_url: 'https://youtu.be/CJeCUL9OSwg',
        description:
          '“おうちコーヒー”をもっと美味しくしたい方へ、簡単にできるコツを伝授。\r\n今回は、ペーパードリップが美味しくなる注ぎ方に注目！\r\n村田講師が、３つの方法を実践し、味の違いを解説します。',
        baristas: []
      }
    ],
    coffee_tips_area: [
      {
        id: 191,
        image_url:
          'https://mystyle.ucc.co.jp/magazine/wp-content/uploads/2022/11/KV-5.jpg',
        title: 'ハワイ育ちの「ローリナ」コーヒー　−UCCハワイ農園から−',
        link_url: 'https://mystyle.ucc.co.jp/magazine/a_16315/',
        subtitle: 'あなたのあたらしい一杯をさがしに',
        already_read: 0,
        reward: [
          {
            id: 2,
            name: 'デフォルトコンテンツマイル',
            description: 'デフォルトコンテンツマイル',
            warning: null,
            type: 1,
            amount: 2,
            coupon_code: null,
            image_url: 'https://via.placeholder.com/640x480.png/0099cc?text=id'
          }
        ]
      },
      {
        id: 188,
        image_url:
          'https://mystyle.ucc.co.jp/magazine/wp-content/uploads/2022/11/kv-12.jpg',
        title:
          '“ちょっと”の工夫でコーヒーをもっと魅力的に［COFFEE CREATOR’S FILE 11 清水美保］',
        link_url: 'https://mystyle.ucc.co.jp/magazine/a_16510/',
        subtitle: 'あなたのあたらしい一杯をさがしに',
        already_read: 1,
        reward: [
          {
            id: 2,
            name: 'デフォルトコンテンツマイル',
            description: 'デフォルトコンテンツマイル',
            warning: null,
            type: 1,
            amount: 2,
            coupon_code: null,
            image_url: 'https://via.placeholder.com/640x480.png/0099cc?text=id'
          }
        ]
      },
      {
        id: 192,
        image_url:
          'https://mystyle.ucc.co.jp/magazine/wp-content/uploads/2022/11/ssreal_202211k.jpg',
        title:
          'ストアスタッフ　今月のリアル買い！『上島珈琲店 ワンドリップコーヒー 5STORIESアソートセット 5杯分（数量限定）』',
        link_url: 'https://mystyle.ucc.co.jp/magazine/a_16448/',
        subtitle: 'あなたのあたらしい一杯をさがしに',
        already_read: 0,
        reward: [
          {
            id: 2,
            name: 'デフォルトコンテンツマイル',
            description: 'デフォルトコンテンツマイル',
            warning: null,
            type: 1,
            amount: 2,
            coupon_code: null,
            image_url: 'https://via.placeholder.com/640x480.png/0099cc?text=id'
          }
        ]
      },
      {
        id: 190,
        image_url:
          'https://mystyle.ucc.co.jp/magazine/wp-content/uploads/2022/11/kv-7.jpg',
        title:
          'コーヒーの、おいしい恋活 vol.11 青森県「薄紅」×「ゴールドスペシャル　リッチブレンド」',
        link_url: 'https://mystyle.ucc.co.jp/magazine/a_16340/',
        subtitle: 'あなたのあたらしい一杯をさがしに',
        already_read: 1,
        reward: [
          {
            id: 2,
            name: 'デフォルトコンテンツマイル',
            description: 'デフォルトコンテンツマイル',
            warning: null,
            type: 1,
            amount: 2,
            coupon_code: null,
            image_url: 'https://via.placeholder.com/640x480.png/0099cc?text=id'
          }
        ]
      }
    ],
    middle_area: [
      {
        id: 1,
        image_url: 'https://via.placeholder.com/120x328',
        title: 'DEV オンラインストア',
        link_url: 'https://dev-service.ebisumart.com/UCC/ext/guide.html',
        subtitle:
          'My COFFEE STYLE MAGAZINEMy COFFEE STYLE MAGAZINEMy COFFEE STYLE MAGAZINE',
        tag: 'イベント',
        reward: [
          {
            name: 'マイルゲット',
            description: 'マイルプレゼント',
            warning: '注意事項',
            type: 1,
            amount: 5,
            coupon_code: 'a302306ea8bb727711819772c974071b',
            image_url: 'https://example.com/example.jpg'
          },
          {
            name: 'マイルゲット',
            description: 'マイルプレゼント',
            warning: '注意事項',
            type: 1,
            amount: 10,
            coupon_code: 'a302306ea8bb727711819772c974071b',
            image_url: 'https://example.com/example.jpg'
          }
        ],
        created_at: '1627298048',
        updated_at: '1627298048'
      },
      {
        id: 2,
        image_url: 'https://via.placeholder.com/328x120',
        title: '【vol.12】サンフランシスコの',
        link_url: 'https://example.com/article/1/',
        subtitle: 'My COFFEE STYLE MAGAZINE',
        tag: '読み物読み物',
        reward: [
          {
            name: 'マイルゲット',
            description: 'マイルプレゼント',
            warning: '注意事項',
            type: 1,
            amount: 5,
            coupon_code: 'a302306ea8bb727711819772c974071b',
            image_url: 'https://example.com/example.jpg'
          },
          {
            name: 'マイルゲット',
            description: 'マイルプレゼント',
            warning: '注意事項',
            type: 1,
            amount: 10,
            coupon_code: 'a302306ea8bb727711819772c974071b',
            image_url: 'https://example.com/example.jpg'
          }
        ],
        created_at: '1627298048',
        updated_at: '1627298048'
      },
      {
        id: 3,
        image_url: 'https://via.placeholder.com/100x100',
        title: '【vol.12】サンフランシスコの進化系カフェ＆レストラン！',
        link_url: 'https://example.com/article/1/',
        subtitle: 'My COFFEE STYLE MAGAZINE',
        tag: '読み物読み物',
        reward: [
          {
            name: 'マイルゲット',
            description: 'マイルプレゼント',
            warning: '注意事項',
            type: 1,
            amount: 5,
            coupon_code: 'a302306ea8bb727711819772c974071b',
            image_url: 'https://example.com/example.jpg'
          },
          {
            name: 'マイルゲット',
            description: 'マイルプレゼント',
            warning: '注意事項',
            type: 1,
            amount: 10,
            coupon_code: 'a302306ea8bb727711819772c974071b',
            image_url: 'https://example.com/example.jpg'
          }
        ],
        created_at: '1627298048',
        updated_at: '1627298048'
      },
      {
        id: 4,
        image_url: 'https://via.placeholder.com/328x328',
        title:
          '【vol.12】サンフランシスコの進化系カフェ＆レストラン！【vol.12】サンフランシスコの進化系カフェ＆レストラン！【vol.12】サンフランシスコの進化系カフェ＆レストラン！',
        link_url: 'https://example.com/article/1/',
        subtitle: 'My COFFEE STYLE MAGAZINE',
        tag: '読み物',
        reward: [
          {
            name: 'マイルゲット',
            description: 'マイルプレゼント',
            warning: '注意事項',
            type: 1,
            amount: 5,
            coupon_code: 'a302306ea8bb727711819772c974071b',
            image_url: 'https://example.com/example.jpg'
          }
        ],
        created_at: '1627298048',
        updated_at: '1627298048'
      }
    ],
    small_area: [
      {
        id: 1,
        image_url: 'https://via.placeholder.com/686x200',
        title: '【vol.12】サンフランシスコの進化系カフェ＆レストラン！',
        link_url: 'https://example.com/article/1/',
        subtitle:
          'My COFFEE STYLE MAGAZINEMy COFFEE STYLE MAGAZINEMy COFFEE STYLE MAGAZINE',
        tag: '読み物読み物',
        reward: [
          {
            name: 'マイルゲット',
            description: 'マイルプレゼント',
            warning: '注意事項',
            type: 1,
            amount: 5,
            coupon_code: 'a302306ea8bb727711819772c974071b',
            image_url: 'https://example.com/example.jpg'
          },
          {
            name: 'マイルゲット',
            description: 'マイルプレゼント',
            warning: '注意事項',
            type: 1,
            amount: 10,
            coupon_code: 'a302306ea8bb727711819772c974071b',
            image_url: 'https://example.com/example.jpg'
          }
        ],
        created_at: '1627298048',
        updated_at: '1627298048'
      },
      {
        id: 2,
        image_url: 'https://via.placeholder.com/176x176',
        title: '【vol.12】サンフランシスコの進化系カフェ＆レストラン！',
        link_url: 'https://example.com/article/1/',
        subtitle: 'My COFFEE STYLE MAGAZINEMy COFFEE STYLE MAGAZINE',
        tag: '読み物',
        reward: [
          {
            name: 'マイルゲット',
            description: 'マイルプレゼント',
            warning: '注意事項',
            type: 1,
            amount: 5,
            coupon_code: 'a302306ea8bb727711819772c974071b',
            image_url: 'https://example.com/example.jpg'
          },
          {
            name: 'マイルゲット',
            description: 'マイルプレゼント',
            warning: '注意事項',
            type: 1,
            amount: 10,
            coupon_code: 'a302306ea8bb727711819772c974071b',
            image_url: 'https://example.com/example.jpg'
          }
        ],
        created_at: '1627298048',
        updated_at: '1627298048'
      },
      {
        id: 3,
        image_url: 'https://via.placeholder.com/88x176',
        title: '【vol.12】サンフランシスコの進化系カフェ＆レストラン！',
        link_url: 'https://example.com/article/1/',
        subtitle: 'My COFFEE STYLE MAGAZINE',
        tag: '読み物',
        reward: [
          {
            name: 'マイルゲット',
            description: 'マイルプレゼント',
            warning: '注意事項',
            type: 1,
            amount: 5,
            coupon_code: 'a302306ea8bb727711819772c974071b',
            image_url: 'https://example.com/example.jpg'
          },
          {
            name: 'マイルゲット',
            description: 'マイルプレゼント',
            warning: '注意事項',
            type: 1,
            amount: 10,
            coupon_code: 'a302306ea8bb727711819772c974071b',
            image_url: 'https://example.com/example.jpg'
          }
        ],
        created_at: '1627298048',
        updated_at: '1627298048'
      }
    ]
  },
  next_page_url: 'http://localhost?page=2',
  prev_page_url: 'null'
};
