<template>
  <div :class="classObject" @click="$emit('click', $event)">
    <slot />
  </div>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
  props: {
    // @values 0 | 12 | 30
    radius: {
      type: [String, Number],
      default: 0
    },

    elevation: {
      type: Boolean,
      default: false
    },

    tiled: {
      type: Boolean,
      default: false
    },

    link: {
      type: Boolean,
      default: false
    },

    noticed: {
      type: Boolean,
      default: false
    },

    skeleton: {
      type: Boolean,
      default: false
    },

    // サムネイルを角丸にしない（芸能人が入る可能性のある箇所）
    squaredThumbnail: {
      type: Boolean,
      default: false
    }
  },

  setup: (props, context) => {
    const BLOCK_NAME = 'c-card';
    const radiusClass = computed(
      () => BLOCK_NAME + '--' + 'radius' + props.radius || ''
    );
    const elevationClass = computed(() =>
      props.elevation ? BLOCK_NAME + '--' + 'elevationLg' : ''
    );
    const tiledClass = computed(() =>
      props.tiled ? BLOCK_NAME + '--' + 'tiled' : ''
    );
    const linkClass = computed(() =>
      props.link ? BLOCK_NAME + '--' + 'link' : ''
    );
    const noticedClass = computed(() =>
      props.noticed ? BLOCK_NAME + '--' + 'noticed' : ''
    );
    const skeletonClass = computed(() =>
      context.root.$_loading_state && !props.skeleton
        ? BLOCK_NAME + '--' + 'skeleton'
        : ''
    );
    const squaredThumbnailClass = computed(() =>
      props.squaredThumbnail ? BLOCK_NAME + '--' + 'squared-thumbnail' : ''
    );
    const classObject = computed(() => {
      return [
        BLOCK_NAME,
        radiusClass.value,
        elevationClass.value,
        tiledClass.value,
        linkClass.value,
        noticedClass.value,
        skeletonClass.value,
        squaredThumbnailClass.value
      ];
    });

    return {
      classObject
    };
  }
});
</script>
