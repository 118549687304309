import Empty from './Empty.vue';

export default {
  title: 'SHARED/Empty',
  component: Empty
};

const Template = (args, { argTypes }) => ({
  components: { Empty },
  props: Object.keys(argTypes),
  template: '<Empty v-bind="$props" />'
});

export const Default = Template.bind({});
Default.args = {
  iconName: 'information',
  text: 'データがありません',
  subtext: ''
};

export const Subtext = Template.bind({});
Subtext.args = {
  iconName: 'comment',
  text: '登録されている感想はありません',
  subtext:
    '画面右上のQRコードリーダーを起動して\n製品バーコードやイベント会場に設置されている\nQRコードを読み取って感想登録しましょう'
};
