const CATEGORY = [
  'アプリの使い方',
  'とても長いカテゴリ名とても長いカテゴリ名とても長いカテゴリ名とても長いカテゴリ名とても長いカテゴリ名とても長いカテゴリ名',
  'オンラインストア',
  'アカウント連携',
  'よくある質問'
];

const questions = [
  {
    id: 1,
    question: 'このアプリでできること',
    answer:
      'コーヒーの感想登録情報をもとに今の味覚傾向をマップで確認することができます！'
  },
  {
    id: 2,
    question: 'コーヒーマップとは',
    answer:
      'コーヒーの感想登録情報をもとに今の味覚傾向をマップで確認することができます！'
  },
  {
    id: 3,
    question:
      'とても長いクエスチョンとても長いクエスチョンとても長いクエスチョンとても長いクエスチョン',
    answer:
      'とても長いアンサーとても長いアンサーとても長いアンサーとても長いアンサーとても長いアンサーとても長いアンサーとても長いアンサーとても長いアンサーとても長いアンサー'
  },
  {
    id: 4,
    question: 'マイル/クーポンについて',
    answer:
      'コーヒーの感想登録情報をもとに今の味覚傾向をマップで確認することができます！'
  }
];

const category = CATEGORY.map((item, i) => ({
  id: i + 1,
  name: item
}));

const list = category.map((item) => ({
  ...item,
  questions
}));

const data = { category, list };

export { data };
