import CoffeeCard from './CoffeeCard.vue';

export default {
  title: 'SHARED/CoffeeCard',
  component: CoffeeCard
};

const Template = (args, { argTypes }) => ({
  components: { CoffeeCard },
  props: Object.keys(argTypes),
  template: '<CoffeeCard v-bind="$props" />'
});

const args = {
  href: '',
  src: 'https://via.placeholder.com/128x128/b35/fff',
  title:
    '製品タイトル製品タイトル製品タイトル製品タイトル製品タイトル製品タイトル製品タイトル製品タイトル',
  titleClamp: 2,
  barista: {
    name: '田中 太郎',
    image_url: 'https://via.placeholder.com/128x128/3c4/fff'
  }
};

export const Default = Template.bind({});
Default.args = {
  ...args
};

export const NoBarista = Template.bind({});
NoBarista.args = {
  ...args,
  barista: null
};
