<template>
  <div :class="classes">
    <Card :radius="0" :tiled="tiled" link @click="$emit('click', $event)">
      <a
        :href="href ? href : false"
        :style="!isLinked ? 'pointer-events: none' : ''"
        v-ripple="isLinked">
        <div class="c-coffee-channel-card__img">
          <img :src="src" :alt="title" />
        </div>

        <div class="c-coffee-channel-card__content">
          <div v-if="title" class="c-coffee-channel-card-content__title">
            {{ title }}
          </div>

          <!-- <div v-if="reward" class="c-coffee-channel-card-content__bottom">
            <Incentive :reward="reward" />
          </div> -->
        </div>
      </a>
    </Card>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from '@vue/composition-api';

export default defineComponent({
  props: {
    href: {
      type: String,
      default: ''
    },

    src: {
      type: String,
      default: ''
    },

    title: {
      type: String,
      default: ''
    },

    subtitle: {
      type: String,
      default: ''
    },

    reward: {
      type: [Object, Array],
      default: undefined
    },

    tag: {
      type: String,
      default: ''
    },

    schedules: {
      type: [Object, Array],
      default: undefined
    },

    tiled: {
      type: Boolean,
      default: false
    },

    contentType: {
      type: String,
      default: ''
    }
  },

  setup: (props) => {
    const latestDateTimeStart = ref('');
    const latestDateTimeEnd = ref('');

    const isLinked = computed(() => props.href.length > 0);
    const classes = computed(() => {
      return {
        'c-coffee-channel-card': true,
        'c-coffee-channel-card--tiled': props.tiled
      };
    });

    return {
      classes,
      latestDateTimeStart,
      latestDateTimeEnd,
      isLinked
    };
  }
});
</script>
