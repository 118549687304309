var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      directives: [
        {
          name: "ripple",
          rawName: "v-ripple",
          value: _vm.$utilities.getRippleOptions("light"),
          expression: "$utilities.getRippleOptions('light')",
        },
      ],
      class: _vm.classes,
      attrs: { disabled: _vm.isDisabled },
      on: { click: _vm.onClick },
    },
    [
      !_vm.isLoading
        ? [
            _c("Icon", { attrs: { name: _vm.iconName, color: "primary01" } }),
            _c("span", [_vm._v(_vm._s(_vm.iconLabel))]),
          ]
        : [_c("Spinner", { attrs: { size: "1em" } })],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }