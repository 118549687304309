var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    { staticClass: "c-expantionPanel" },
    [
      _c(
        "button",
        {
          staticClass: "c-expantionPanel__header",
          class: { "is-active": _vm.isExpanded },
          attrs: { type: "button" },
          on: { click: _vm.handleToggle },
        },
        [
          _c(
            "span",
            { staticClass: "c-expantionPanel__header__text" },
            [
              _vm._t("header"),
              !_vm.$slots.header ? [_vm._v(_vm._s(_vm.header))] : _vm._e(),
            ],
            2
          ),
          _c("Icon", {
            staticClass: "c-expantionPanel__header__icon",
            attrs: { name: "arrowBottom", width: "20", height: "20" },
          }),
        ],
        1
      ),
      _c(
        "transition",
        {
          attrs: { name: "topSlide" },
          on: {
            "before-enter": _vm.beforeEnter,
            enter: _vm.enter,
            "before-leave": _vm.beforeLeave,
            leave: _vm.leave,
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isExpanded,
                  expression: "isExpanded",
                },
              ],
              staticClass: "c-expantionPanel__content topSlide",
            },
            [
              _vm._t("default"),
              !_vm.$slots.default ? [_vm._v(_vm._s(_vm.content))] : _vm._e(),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }