import Repository from './Repository';
const resource = '/notice';

export default {
  /**
   * お知らせ一覧取得
   *
   * @param {string} [queryString] クエリ文字列 ?page=2
   */
  getNotices(queryString = '') {
    return Repository.get(`${resource}${queryString}`);
  },

  /**
   * お知らせ詳細取得
   *
   * @param {number | string} noticeId
   */
  getNotice(noticeId) {
    return Repository.get(`${resource}/${noticeId}`);
  }
};
