export const useEnvironment = () => {
  const MODE = process.env.VUE_APP_MODE;

  const isLocal = MODE === 'LOCAL';
  const isDev = MODE === 'DEV';
  const isStg = MODE === 'STG';
  const isPrd = MODE === 'PRD';

  const isDebug = isLocal || isDev;
  const GTM_CONTAINER_ID = process.env.VUE_APP_GTM_CONTAINER_ID;

  return {
    MODE,
    isLocal,
    isDev,
    isStg,
    isPrd,
    isDebug,
    GTM_CONTAINER_ID
  };
};
