var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-incentive" }, [
    !_vm.isCompleted
      ? _c(
          "span",
          { staticClass: "c-incentive__state" },
          [
            _c("Icon", {
              attrs: {
                name: "checkCircleFilled",
                width: "14",
                height: "14",
                color: "grey03",
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _c("strong", { staticClass: "c-incentive__amount" }, [
      _vm._v(" " + _vm._s(_vm.$contentList.incentiveValue(_vm.reward)) + " "),
    ]),
    _c("span", { staticClass: "c-incentive__unit" }, [
      _vm._v(_vm._s(_vm.$contentList.incentiveUnit())),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }