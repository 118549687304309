/**
 * デフォルトマイル取得方法種別
 *
 * @see https://bitbucket.org/sonicmoov/ucc_coffee_creation_api/src/develop/app/Enums/MileageMissionType.php
 */
export class MileageMissionType {
  static READ_CONTENT = 1; // 記事コンテンツを読む
  static MAKE_IMPRESSION = 2; // コーヒーの感想登録
  static JAN_PRODUCT_ACCESS = 3; // コーヒーJAN
  static QR_PRODUCT_ACCESS = 4; // コーヒーQR
  static EVENT_CONTENT = 5; // イベント参加
  static CAMPAIGN = 6; // キャンペーン
  static LOGIN_BONUS = 7; // ログインボーナス
  static VIDEO = 8; // 動画視聴
  static GRANTED_BY_ADMIN = 99; // 運営による直接付与

  /**
   * タイプIDからタイプ名を取得する
   * @param {number} type
   * @returns {string | undefined}
   */
  static getTypeName = (type) => {
    switch (type) {
      case this.READ_CONTENT:
        return '記事コンテンツを読んだ';
      case this.MAKE_IMPRESSION:
        return 'コーヒーの感想を登録した';
      case this.JAN_PRODUCT_ACCESS:
        return 'コーヒーのバーコードを読みとった';
      case this.QR_PRODUCT_ACCESS:
        return 'コーヒーのQRを読みとった';
      case this.EVENT_CONTENT:
        return 'イベントに参加した';
      case this.CAMPAIGN:
        return 'キャンペーンで獲得した';
      case this.LOGIN_BONUS:
        return 'ログインボーナスを獲得した';
      case this.VIDEO:
        return '動画を視聴した';
      case this.GRANTED_BY_ADMIN:
        return '運営から付与された';
    }
  };
}
