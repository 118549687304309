var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classes },
    [
      _c(
        "Card",
        {
          attrs: { radius: 0, tiled: _vm.tiled, link: "" },
          on: {
            click: function ($event) {
              return _vm.$emit("click", $event)
            },
          },
        },
        [
          _c(
            "a",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple",
                  value: _vm.isLinked,
                  expression: "isLinked",
                },
              ],
              style: !_vm.isLinked ? "pointer-events: none" : "",
              attrs: { href: _vm.href ? _vm.href : false },
            },
            [
              _c("div", { staticClass: "c-coffee-channel-card__img" }, [
                _c("img", { attrs: { src: _vm.src, alt: _vm.title } }),
              ]),
              _c("div", { staticClass: "c-coffee-channel-card__content" }, [
                _vm.title
                  ? _c(
                      "div",
                      { staticClass: "c-coffee-channel-card-content__title" },
                      [_vm._v(" " + _vm._s(_vm.title) + " ")]
                    )
                  : _vm._e(),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }