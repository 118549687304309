var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.wrapper,
    {
      directives: [
        {
          name: "ripple",
          rawName: "v-ripple",
          value:
            _vm.to || _vm.href
              ? _vm.$utilities.getRippleOptions("light")
              : false,
          expression:
            "to || href ? $utilities.getRippleOptions('light') : false",
        },
      ],
      tag: "component",
      staticClass: "c-banner",
      attrs: { type: _vm.type, href: _vm.hrefLink, to: _vm.toLink },
      on: {
        click: function ($event) {
          !_vm.disabled && _vm.$emit("click", $event)
        },
      },
    },
    [_c("img", { attrs: { src: _vm.src, alt: _vm.alt } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }