/* eslint-disable */
import mockServer from 'axios-mock-server'
import mock0 from './products/impression/_id'
import mock1 from './products/history/impression/index'
import mock2 from './products/detail/index'
import mock3 from './products/detail/_id'
import mock4 from './present/index'
import mock5 from './present/_id'
import mock6 from './notice/index'
import mock7 from './notice/_id'
import mock8 from './movie/index'
import mock9 from './movie/_id'
import mock10 from './mileage/reward/index'
import mock11 from './mileage/progress-rank/index'
import mock12 from './mileage/history/index'
import mock13 from './line/alignment/index'
import mock14 from './help/index'
import mock15 from './dp/top/index'
import mock16 from './dp/recipe/index'
import mock17 from './dp/recipe/_id'
import mock18 from './dp/extract-history/index'
import mock19 from './dp/capsule/index'
import mock20 from './dp/bookmark/index'
import mock21 from './dp/bookmark/_id'
import mock22 from './customer/take-over/index'
import mock23 from './customer/index'
import mock24 from './customer/ec/index'
import mock25 from './content/top/notice/index'
import mock26 from './content/top/index'
import mock27 from './content/tab-order/index'
import mock28 from './content/feature/event/index'
import mock29 from './content/feature/article/index'
import mock30 from './content/event/index'
import mock31 from './content/event/_eventId'
import mock32 from './content/article/index'
import mock33 from './content/article/banner/index'
import mock34 from './content/already-read/index'
import mock35 from './contact/index'
import mock36 from './coffee-map/product/recommend'
import mock37 from './coffee-map/index'
import mock38 from './barista/index'
import mock39 from './barista/favorite/index'
import mock40 from './barista/_id'
import mock41 from './banners/page/point/index'

export default (client) => mockServer([
  {
    path: '/products/impression/_id',
    methods: mock0
  },
  {
    path: '/products/history/impression',
    methods: mock1
  },
  {
    path: '/products/detail',
    methods: mock2
  },
  {
    path: '/products/detail/_id',
    methods: mock3
  },
  {
    path: '/present',
    methods: mock4
  },
  {
    path: '/present/_id',
    methods: mock5
  },
  {
    path: '/notice',
    methods: mock6
  },
  {
    path: '/notice/_id',
    methods: mock7
  },
  {
    path: '/movie',
    methods: mock8
  },
  {
    path: '/movie/_id',
    methods: mock9
  },
  {
    path: '/mileage/reward',
    methods: mock10
  },
  {
    path: '/mileage/progress-rank',
    methods: mock11
  },
  {
    path: '/mileage/history',
    methods: mock12
  },
  {
    path: '/line/alignment',
    methods: mock13
  },
  {
    path: '/help',
    methods: mock14
  },
  {
    path: '/dp/top',
    methods: mock15
  },
  {
    path: '/dp/recipe',
    methods: mock16
  },
  {
    path: '/dp/recipe/_id',
    methods: mock17
  },
  {
    path: '/dp/extract-history',
    methods: mock18
  },
  {
    path: '/dp/capsule',
    methods: mock19
  },
  {
    path: '/dp/bookmark',
    methods: mock20
  },
  {
    path: '/dp/bookmark/_id',
    methods: mock21
  },
  {
    path: '/customer/take-over',
    methods: mock22
  },
  {
    path: '/customer',
    methods: mock23
  },
  {
    path: '/customer/ec',
    methods: mock24
  },
  {
    path: '/content/top/notice',
    methods: mock25
  },
  {
    path: '/content/top',
    methods: mock26
  },
  {
    path: '/content/tab-order',
    methods: mock27
  },
  {
    path: '/content/feature/event',
    methods: mock28
  },
  {
    path: '/content/feature/article',
    methods: mock29
  },
  {
    path: '/content/event',
    methods: mock30
  },
  {
    path: '/content/event/_eventId',
    methods: mock31
  },
  {
    path: '/content/article',
    methods: mock32
  },
  {
    path: '/content/article/banner',
    methods: mock33
  },
  {
    path: '/content/already-read',
    methods: mock34
  },
  {
    path: '/contact',
    methods: mock35
  },
  {
    path: '/coffee-map/product/recommend',
    methods: mock36
  },
  {
    path: '/coffee-map',
    methods: mock37
  },
  {
    path: '/barista',
    methods: mock38
  },
  {
    path: '/barista/favorite',
    methods: mock39
  },
  {
    path: '/barista/_id',
    methods: mock40
  },
  {
    path: '/banners/page/point',
    methods: mock41
  }
], client, '')
