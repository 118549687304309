/**
 * システムメッセージダイアログ メッセージIDはダイアログリストを参照
 * @see https://docs.google.com/spreadsheets/d/1_x07tESCdD8q0K_6luq3zwiqDXPjVSCHn8vouoorKJc/edit#gid=458392161
 */
export class SystemDialogMessage {
  static EXTERNAL_BROWSER_OPEN = 4; // 外部ブラウザ使用許可
  static ONLINE_STORE_CONNECT_CANCEL = 6; // オンラインストア連携 解除
  static LINE_CONNECT_CANCEL = 8; // LINE連携 解除
  static TAKEOVER_CODE_COPY = 9; // 引継コードコピー
  static TAKEOVER_ERROR = 10; // 引き継ぎ失敗
  static TAKEOVER_SUCCESS = 11; // 引き継ぎ成功
  static CAMERA_ACCEPT = 12; // カメラ使用許可
  static VERSION_UPDATE_ACCEPT = 14; // 強制バージョンアップ許可
  static ERROR_NETWORK = 18; // ネットワーク通信エラー
  static ERROR_COMMON = 20; // 汎用エラー
  static ERROR_CODE_READING = 21; // JAN、QR読み取り不可

  static BLUETOOTH_INTRODUCTION = 101; // Bluetooth連携導入
  static BLUETOOTH_RECONNECT = 103; // Bluetooth再接続案内
  static BLUETOOTH_DISCONNECT = 104; // Bluetooth接続解除
  static BLUETOOTH_BREW_START = 105; // 抽出開始
  static BLUETOOTH_BOOKMARK_SAVE_CONFIRM = 106; // BOOKMARK保存確認
  static BLUETOOTH_BODY_SAVE_CONFIRM = 108; // BOOKMARK保存確認
  static BLUETOOTH_CONNECT = 110; // Bluetooth接続
}
