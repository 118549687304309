var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-noimage" }, [
    _c("img", { attrs: { src: _vm.src, alt: _vm.alt } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }