import { random, sample, sampleSize, cloneDeep } from 'lodash';

const ITEM_MASTER = [
  {
    id: 1,
    name: 'UCC MOUNTAIN MIST ケニア ニエリ ムティトゥ ファクトリー（豆）200g',
    image_url: 'https://placehold.jp/5a2727/ffffff/88x88.png',
    ec_link_url: 'https://store.ucc.co.jp/category/BRAND_23/HLS1515003.html'
  },
  {
    id: 2,
    name: 'UCC MOUNTAIN MIST ペルー インカワシ組合（豆）200g',
    image_url: 'https://placehold.jp/44a4fc/ffffff/150x100.png',
    ec_link_url: ''
  },
  {
    id: 3,
    name: '炒り豆 ゴールドスペシャル スペシャルブレンド 360g（豆）',
    image_url: 'https://placehold.jp/2E5851/ffffff/150x100.png',
    ec_link_url: 'https://store.ucc.co.jp/category/BRAND_2/GSP1301017.html'
  },
  {
    id: 4,
    name: 'UCC MOUNTAIN MIST ホンジュラス カスカダ農園（豆）200g',
    image_url: 'https://placehold.jp/696458/ffffff/150x100.png',
    ec_link_url: 'https://store.ucc.co.jp/category/BRAND_10/UCT1201002.html'
  },
  {
    id: 5,
    name: 'UCC おいしいカフェインレスコーヒー コーヒーバッグ 水出しアイスコーヒー 4袋',
    image_url: 'https://placehold.jp/56b98a/ffffff/100x150.png',
    ec_link_url: 'https://store.ucc.co.jp/category/BRAND_13/CAL0601004.html'
  }
];

// ダミーデータ生成
const _generateMapCellData = (mapPosition) => {
  let mapZone;
  if (mapPosition <= 18) {
    // 象限A or B
    mapZone = [1, 2, 3].includes(mapPosition % 6) ? 1 : 2;
  } else {
    // 象限C or D
    mapZone = [1, 2, 3].includes(mapPosition % 6) ? 3 : 4;
  }
  return {
    map_position: mapPosition,
    map_zone: mapZone,
    is_like: random(0, 100),
    is_hate: random(0, 100),
    val: random(0, 1, true) > 0.1 ? random(0, 1, true) : -1 /* disabled */,
    products: sampleSize(ITEM_MASTER, random(1, ITEM_MASTER.length))
  };
};

const coffeeMap = [...Array(36)].map((_, i) => _generateMapCellData(i + 1));

const factory = (id) => {
  const item = cloneDeep(sample(ITEM_MASTER));
  item.id = id;
  return item;
};

const PRODUCTS_LENGTH = 13;
const recommendedProducts = [...new Array(PRODUCTS_LENGTH)].map((_, i) =>
  factory(i + 1)
);

const stylistMessage = {
  name: 'バランス＆メリハリタイプ',
  message:
    '今のあなたは、<span>苦味が強くコク深いコーヒー</span>がお好きなようです。<br />おすすめのコーヒーを見てみましょう'
};

export { coffeeMap, recommendedProducts, stylistMessage };
