import { MileStageType } from '@/constants/enums';
import { STAGE_LIST } from '@/constants/MileStage';

const STAGE_SIZE = 30;

const rewardPoint = {
  name: '500ポイント（500円分）',
  description: 'DESC',
  type: 2,
  amount: 500,
  coupon_code: 'CODE',
  image_url: 'https://placehold.jp/5a9d1d/ffffff/200x150.png?text=POINT'
};

const rewardCoupon = {
  name: 'コーヒー豆100円OFF',
  description: 'DESC',
  type: 3,
  amount: 100,
  coupon_code: 'CODE',
  image_url: 'https://placehold.jp/46aecf/ffffff/200x150.png?text=COUPON'
};

const data = [...new Array(STAGE_SIZE)].map((_, i) => {
  const stageIndex = i + 1;
  const stageId = stageIndex;
  return {
    rank_id: stageId,
    rank_name:
      STAGE_LIST[
        (stageId % MileStageType.BASE_STAGE_SIZE ||
          MileStageType.BASE_STAGE_SIZE) - 1
      ].nameEn,
    present: [
      {
        acquired: stageIndex < 2,
        threshold: stageIndex * 50,
        reward: rewardPoint
      },
      {
        acquired: stageIndex < 2,
        threshold: stageIndex * 50 + 25,
        reward: rewardCoupon
      }
    ]
  };
});

export { data };
