<template>
  <div>
    <div class="c-card">
      <ul class="icons">
        <li v-for="(item, i) in $icons" :key="i">
          <Icon :name="item.name" />
          <p>{{ item.name }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
  setup: (props, context) => {
    const icons = computed(() => {
      if (!context.root.$icons) return {};
      return context.root.$icons;
    });

    return {
      icons
    };
  }
});
</script>

<style lang="scss" scoped>
.icons {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin: -1.2rem 0 0 -1.2rem;

  > li {
    padding: 1.2rem 0 0 1.2rem;
    flex-shrink: 1;
    width: 20%;
    font-size: 1.2rem;
    text-align: center;
  }

  .c-icon {
    width: 4rem;
    height: 4em;
  }
}
</style>
