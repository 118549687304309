<template>
  <ContentCard
    v-if="content"
    class="event-card c-card--mb20"
    :src="content['image_url']"
    :title="content['title']"
    :subtitle="content['venue_name']"
    :schedules="content['schedules'][0] || []"
    content-type="join"
    @click="onClickContentLink(content['id'])" />
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  components: {
    ContentCard: () => import('@/components/content/ContentCard.vue')
  },

  props: {
    content: {
      type: Object,
      default: undefined
    },

    src: {
      type: String,
      default: ''
    },

    title: {
      type: String,
      default: ''
    },

    subtitle: {
      type: String,
      default: ''
    },

    reward: {
      type: Object,
      default: null
    },

    schedules: {
      type: Array,
      default: null
    }
  },

  setup: () => {
    /**
     * コンテンツ押下時の処理
     * @param {number} eventId
     */
    const onClickContentLink = (eventId) => {
      const contentUrl = `coffee-creation-app://page/detail/?url=/content/join/detail/${eventId}`;
      window.location.href = contentUrl;
    };

    return {
      onClickContentLink
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.event-card {
  .c-card__img {
    @include mixin.imageAspectRatio(343, 170);
  }
}
</style>
