import { usePagination } from '@/composables/usePagination';
import { recommendedProducts } from '../data/data';

export default {
  get({ params }) {
    const { paginate } = usePagination(recommendedProducts, 5);
    const response = { ...paginate(params.page) };
    return [200, response];

    // error: タイムアウト
    // return [408, { message: 'タイムアウトしました' }];
  }
};
