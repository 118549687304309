import { data } from './data/presents.js';

export default {
  get({ values }) {
    const res = data.find((item) => item.id === Number(values.id)) || data[0];
    return [200, res];

    // error: タイムアウト
    // return [408, { message: 'タイムアウトしました' }];
  },

  post() {
    return [200, { succeed: true, message: null }];
  }
};
