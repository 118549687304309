<template>
  <label v-if="label || required || hint" class="c-formLabel">
    <span class="c-formLabel__text">{{ label }}</span>
    <span v-if="required" class="c-formLabel__required">必須</span>
    <span class="c-formLabel__hint">{{ hint }}</span>
  </label>
</template>

<script>
export default {
  props: {
    required: {
      type: Boolean,
      default: false
    },

    label: {
      type: String,
      default: ''
    },

    hint: {
      type: String,
      default: ''
    }
  }
};
</script>
