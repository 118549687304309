import BaseTextarea from './BaseTextarea.vue';

export default {
  title: 'SHARED/BaseTextarea',
  component: BaseTextarea
};

const Template = (args, { argTypes }) => ({
  components: { BaseTextarea },
  props: Object.keys(argTypes),
  template: '<BaseTextarea v-bind="$props" />'
});

export const Default = Template.bind({});
Default.args = {
  value: '',
  placeholder: 'placeholder',
  label: 'LABEL',
  hint: 'HINT',
  required: true,
  readonly: false
};
