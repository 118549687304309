// ステージアップせずマイル値が上昇するパターン
export const data = {
  now_mileage: 8,
  rank: [
    {
      id: 1,
      threshold: 16,
      name: 'ハワイ',
      image_urls: {
        stageup: 'https://placehold.jp/666/fff/750x658.png?text=Mexico_stageup',
        completed:
          'https://placehold.jp/2e5851/fff/750x1334.png?text=Hawaii_completed',
        background: 'https://placehold.jp/e2e2e2/555/750x658.png?text=Hawaii_bg'
      },
      start_progress: 29,
      end_progress: 50
    }
  ]
};

// ステージアップするパターン
// export const data = {
//   now_mileage: 42,
//   rank: [
//     {
//       id: 2,
//       threshold: 33,
//       name: 'メキシコ',
//       image_urls: {
//         stageup:
//           'https://placehold.jp/666/fff/750x658.png?text=Jamaica_stageup',
//         completed:
//           'https://placehold.jp/2e5851/fff/750x1334.png?text=Mexico_completed',
//         background: 'https://placehold.jp/e2e2e2/555/750x658.png?text=Mexico_bg'
//       },
//       start_progress: 0,
//       end_progress: 100
//     },
//     {
//       id: 3,
//       threshold: 51,
//       name: 'ジャマイカ',
//       image_urls: {
//         stageup:
//           'https://placehold.jp/666/fff/750x658.png?text=Guatemala_stageup',
//         completed:
//           'https://placehold.jp/2e5851/fff/750x1334.png?text=Jamaica_completed',
//         background:
//           'https://placehold.jp/e2e2e2/555/750x658.png?text=Jamaica_bg'
//       },
//       start_progress: 0,
//       end_progress: 50
//     }
//   ]
// };

// // ステージをまたいでアップするパターン
// export const data = {
//   now_mileage: 42,
//   rank: [
//     {
//       id: 1,
//       threshold: 16,
//       name: 'ハワイ',
//       image_urls: {
//         stageup: 'https://placehold.jp/666/fff/750x658.png?text=Mexico_stageup',
//         completed:
//           'https://placehold.jp/2e5851/fff/750x1334.png?text=Hawaii_completed',
//         background: 'https://placehold.jp/e2e2e2/555/750x658.png?text=Hawaii_bg'
//       },
//       start_progress: 29,
//       end_progress: 100
//     },
//     {
//       id: 2,
//       threshold: 33,
//       name: 'メキシコ',
//       image_urls: {
//         stageup:
//           'https://placehold.jp/666/fff/750x658.png?text=Jamaica_stageup',
//         completed:
//           'https://placehold.jp/2e5851/fff/750x1334.png?text=Mexico_completed',
//         background: 'https://placehold.jp/e2e2e2/555/750x658.png?text=Mexico_bg'
//       },
//       start_progress: 0,
//       end_progress: 100
//     },
//     {
//       id: 3,
//       threshold: 51,
//       name: 'ジャマイカ',
//       image_urls: {
//         stageup:
//           'https://placehold.jp/666/fff/750x658.png?text=Guatemala_stageup',
//         completed:
//           'https://placehold.jp/2e5851/fff/750x1334.png?text=Jamaica_completed',
//         background:
//           'https://placehold.jp/e2e2e2/555/750x658.png?text=Jamaica_bg'
//       },
//       start_progress: 0,
//       end_progress: 50
//     }
//   ]
// };
