<template>
  <svg
    :class="classes"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewBox"
    :aria-label="icon.name"
    role="img">
    <g v-html="icon.path" />
  </svg>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
  props: {
    name: {
      type: String,
      default: 'arrowRight',
      required: true
    },

    color: {
      type: String,
      default: 'black01'
    },

    width: {
      type: [Number, String],
      default: 16
    },

    height: {
      type: [Number, String],
      default: 16
    }
  },

  setup: (props, context) => {
    const blockName = 'c-icon';
    const icon = computed(() => {
      if (!context.root.$icons || !context.root.$icons[props.name]) return {};
      return context.root.$icons[props.name];
    });

    const viewBox = computed(() => icon.value?.viewBox || '0 0 100 100');

    const colorClass = computed(() => {
      if (!props.color) return '';
      return 'c-icon--' + props.color || '';
    });

    const classes = computed(() => {
      return [blockName, colorClass.value];
    });

    return {
      viewBox,
      classes,
      icon
    };
  }
});
</script>
