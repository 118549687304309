import BaseSelect from './BaseSelect.vue';

export default {
  title: 'SHARED/BaseSelect',
  component: BaseSelect
};

const Template = (args, { argTypes }) => ({
  components: { BaseSelect },
  props: Object.keys(argTypes),
  template: '<BaseSelect v-bind="$props" />'
});

export const Default = Template.bind({});
Default.args = {
  items: [
    { label: 'label1', value: 'value1' },
    { label: 'label2', value: 'value2' },
    { label: 'label3', value: 'value3' }
  ],
  label: 'LABEL',
  hint: 'HINT',
  required: true
};
