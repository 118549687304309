import { sample, cloneDeep } from 'lodash';

const presentCoupon = {
  id: 1,
  is_new: true,
  reward: {
    name: 'コーヒー豆100円OFF',
    description:
      'UCC公式オンラインストアで利用できるコーヒー豆10%OFFクーポンです。\n下記のクーポンコードをコピーしてオンラインストアでご利用ください。',
    type: 3,
    amount: 5,
    coupon_code: '1234-5678-9012',
    image_url: 'https://placehold.jp/46aecf/ffffff/200x150.png?text=COUPON',
    warning:
      '・注意事項が入ります注意事項が入ります\n・注意事項が入ります注意事項が入ります注意事項が入ります\n・注意事項が入ります注意事項が入ります注意事項が入ります\n・注意事項が入ります注意事項が入ります注意事項が入ります'
  },
  created_at: '1627298048',
  commence_at: '1627298048',
  expire_at: '1627298048'
};

const presentPoint = {
  id: 2,
  is_new: false,
  reward: {
    name: '500ポイント（500円分）',
    description:
      'UCC公式オンラインストアのポイント500円分です。\n下記のボタンからポイントをチャージしてご利用ください。',
    type: 2,
    amount: 5,
    coupon_code: null,
    image_url: 'https://placehold.jp/5a9d1d/ffffff/200x150.png?text=POINT',
    warning:
      '・注意事項が入ります注意事項が入ります\n・注意事項が入ります注意事項が入ります注意事項が入ります\n・注意事項が入ります注意事項が入ります注意事項が入ります\n・注意事項が入ります注意事項が入ります注意事項が入ります'
  },
  created_at: '1627298048',
  commence_at: '1627298048',
  expire_at: '1627298048'
};

const presentNoImage = {
  id: 3,
  is_new: true,
  reward: {
    name: 'コーヒー豆100円OFFコーヒー豆100円OFFコーヒー豆100円OFFコーヒー豆100円OFF',
    description:
      'UCC公式オンラインストアで利用できるコーヒー豆10%OFFクーポンです。\n下記のクーポンコードをコピーしてオンラインストアでご利用ください。',
    type: 3,
    amount: 5,
    coupon_code: '1234-5678-9012',
    image_url: null,
    warning: ''
  },
  created_at: '1627298048',
  commence_at: '1627298048',
  expire_at: '1627298048'
};

const ITEM_MASTER = [presentCoupon, presentPoint, presentNoImage];

const factory = (id, isNew) => {
  const item = cloneDeep(sample(ITEM_MASTER));
  item.id = id;
  item.is_new = isNew;
  return item;
};

const DATA_LENGTH = 20;
const data = [...ITEM_MASTER]; // 先頭3件は固定（表示確認簡便化）
data.push(
  ...[...new Array(DATA_LENGTH)].map((_, i) =>
    factory(i + ITEM_MASTER.length + 1, sample([true, false]))
  )
);

export { data };
