var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-baseCheckbox c-form__item" },
    [
      _c(
        "ValidationProvider",
        _vm._b(
          {
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (ref) {
                    var errors = ref.errors
                    var failed = ref.failed
                    return [
                      _c(
                        "div",
                        {
                          staticClass:
                            "c-baseCheckbox__controlWrapper c-formControl",
                        },
                        [
                          _c("input", {
                            staticClass: "c-baseCheckbox__control",
                            class: { "has-error": failed },
                            attrs: { id: "input-" + _vm.uid, type: "checkbox" },
                            domProps: {
                              value: _vm.value,
                              checked: _vm.checked,
                            },
                            on: { change: _vm.onChange },
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "c-baseCheckbox__label",
                              attrs: { for: "input-" + _vm.uid },
                            },
                            [
                              _vm.$slots.default
                                ? _vm._t("default")
                                : [_vm._v(" " + _vm._s(_vm.label) + " ")],
                            ],
                            2
                          ),
                        ]
                      ),
                      errors[0]
                        ? _c("div", { staticClass: "c-errorMessage" }, [
                            _vm._v(_vm._s(errors[0])),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          },
          "ValidationProvider",
          _vm.veeProps,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }