import { wordings } from '@/constants/wordings';

/**
 * キャンペーン種別
 *
 * @see https://bitbucket.org/sonicmoov/ucc_coffee_creation_api/src/develop/app/Enums/CampaignType.php
 */
export class CampaignType {
  static CONTENT = 1; // コンテンツ
  static PRODUCT = 2; // 製品
  static IMPRESSION = 3; // 感想登録
  static INSTALL = 4; // インストール
  static LINE_ACCOUNT_LINK = 5; // LINEアカウント連携
  static EC_ACCOUNT_LINK = 6; // オンラインストア連携

  /**
   * タイプIDからタイプ名を取得する
   * @param {number} type
   * @returns {string | undefined}
   */
  static getTypeName = (type) => {
    switch (type) {
      case this.CONTENT:
        return 'コンテンツ';
      case this.PRODUCT:
        return '製品';
      case this.IMPRESSION:
        return '感想登録';
      case this.INSTALL:
        return 'インストール';
      case this.LINE_ACCOUNT_LINK:
        return `${wordings.accountLine}${wordings.accountLink}`;
      case this.EC_ACCOUNT_LINK:
        return `${wordings.onlineStore}${wordings.accountLink}`;
    }
  };
}
