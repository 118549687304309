<template>
  <div class="c-bottomSheet" :data-open="state === 'open' ? 1 : 0">
    <div class="bg" :style="stylesBg" @click="() => setState('half')" />
    <div
      ref="card"
      class="c-bottomSheet__inner"
      :data-state="isMove ? 'move' : state"
      :style="stylesCard">
      <div
        ref="header"
        class="c-bottomSheet__header"
        :class="{ 'c-bottomSheet__header--noShadow': noShadow }">
        <div class="c-bottomSheet__panArea">
          <div ref="bar" class="c-bottomSheet__bar" :style="stylesHeader" />
        </div>
        <Icon
          v-if="showCloseIcon"
          name="close"
          @click.native="onClickCloseIcon" />
        <slot name="header" />
      </div>
      <div class="c-bottomSheet__contents" :style="stylesContents">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @extends vue-swipeable-bottom-sheet
 * @see https://github.com/atsutopia/vue-swipeable-bottom-sheet
 */

import Hammer from 'hammerjs';
import { colors } from '@/composables/useColors';

export default {
  props: {
    openY: {
      type: Number,
      default: 0.1
    },

    halfY: {
      type: Number,
      default: 0.65
    },

    defaultState: {
      type: String,
      default: 'half'
    },

    barColor: {
      type: String,
      default: null
    },

    overlayColor: {
      type: String,
      default: 'transparent'
    },

    showCloseIcon: {
      type: Boolean,
      default: false
    },

    noShadow: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      mc: null,
      y: 0,
      startY: 0,
      isMove: false,
      state: this.defaultState,
      rect: {},
      headerHeight: 0
    };
  },

  computed: {
    stylesBg() {
      return { backgroundColor: this.state === 'open' && this.overlayColor };
    },

    stylesCard() {
      return { top: `${this.isMove ? this.y : this.calcY()}px` };
    },

    stylesHeader() {
      return { backgroundColor: this.barColor || colors.common.grey03 };
    },

    stylesContents() {
      return {
        height: `calc(100vh - ${this.calcY()}px - ${this.headerHeight}px)`
      };
    }
  },

  watch: {
    state() {
      this.calcHeaderHeight();
    }
  },

  mounted() {
    window.onresize = () => {
      this.rect = this.$refs.card.getBoundingClientRect();
      this.calcHeaderHeight();
    };
    this.calcHeaderHeight();

    this.rect = this.$refs.card.getBoundingClientRect();
    this.mc = new Hammer(this.$refs.header);
    this.mc.get('pan').set({ direction: Hammer.DIRECTION_ALL });
    this.mc.on('panup pandown', (evt) => {
      this.y = evt.center.y - this.headerHeight / 2;
    });
    this.mc.on('panstart', (evt) => {
      this.startY = evt.center.y;
      this.isMove = true;
    });
    this.mc.on('panend', (evt) => {
      this.isMove = false;
      switch (this.state) {
        case 'close':
        case 'half':
          if (this.state === 'close') {
            if (this.startY - evt.center.y > 120) {
              this.state = 'half';
            }
            if (this.startY - evt.center.y > 320) {
              this.state = 'open';
            }
            break;
          }
          if (this.startY - evt.center.y > 120) {
            this.state = 'open';
          }
          // 下までスワイプしても閉じない
          // if (this.startY - evt.center.y < -50) {
          //   this.state = 'close';
          // }
          break;
        case 'open':
          if (this.startY - evt.center.y < -120) {
            this.state = 'half';
          }
          break;
      }
    });
  },

  beforeDestroy() {
    this.mc.destroy();
    window.onresize = null;
  },

  methods: {
    calcY() {
      switch (this.state) {
        case 'close':
          return this.rect.height;
        case 'open':
          // return this.rect.height * this.openY;
          return 16; // デザインに合わせて top: 16px
        case 'half':
          return this.rect.height * this.halfY;
        default:
          return this.y;
      }
    },

    /**
     * ヘッダーの高さを再計算する（内包コンテンツが変わった場合などに呼び出す）
     *
     * @public
     */
    calcHeaderHeight() {
      this.$nextTick(() => {
        this.headerHeight = this.$refs.header?.getBoundingClientRect()?.height;
      });
    },

    setState(state) {
      this.state = state;
    },

    onClickCloseIcon() {
      this.setState('half');
      this.$emit('close');
    }
  }
};
</script>
