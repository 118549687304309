var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "l-wrap" },
    [
      _c("ErrorBoundary", {
        attrs: { "stop-propagation": true },
        scopedSlots: _vm._u([
          {
            key: "error",
            fn: function () {
              return undefined
            },
            proxy: true,
          },
          {
            key: "content",
            fn: function () {
              return [_c("router-view")]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }