<template>
  <component
    :is="wrapper"
    :type="!href && !to ? type : false"
    :href="!to ? href : false"
    :to="!href ? to : false"
    :class="classes"
    v-ripple="$utilities.getRippleOptions(rippleTheme)"
    @click="!disabled && $emit('click', $event)">
    <span
      v-if="$scopedSlots.iconPrepend"
      class="c-button__icon c-button__icon--prepend">
      <slot name="iconPrepend" />
    </span>
    <slot />
    <span
      v-if="$scopedSlots.iconAppend"
      class="c-button__icon c-button__icon--append">
      <slot name="iconAppend" />
    </span>
  </component>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api';
export default defineComponent({
  props: {
    // @values button | submit | reset
    type: {
      type: String,
      default: 'button'
    },

    href: {
      type: String,
      default: ''
    },

    to: {
      type: String,
      default: ''
    },

    styleType: {
      type: String,
      default: ''
    },

    fluid: {
      type: Boolean,
      default: false
    },

    size: {
      // @values lg | sm | xs
      type: String,
      default: ''
    },

    elevation: {
      type: Boolean,
      default: false
    },

    bordered: {
      type: Boolean,
      default: false
    },

    squared: {
      type: Boolean,
      default: false
    },

    action: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    flat: {
      type: Boolean,
      default: false
    }
  },

  setup: (props) => {
    const blockName = 'c-button';
    const wrapper = computed(() => {
      if (props.href) {
        return 'a';
      } else if (props.to) {
        return 'router-link';
      }
      return 'button';
    });

    const styleTypeClass = computed(() =>
      props.styleType ? blockName + '--' + props.styleType : ''
    );
    const fluidClass = computed(() =>
      props.fluid ? blockName + '--' + 'fluid' : ''
    );
    const sizeClass = computed(() =>
      props.size ? blockName + '--' + props.size : ''
    );
    const elevationClass = computed(() =>
      props.elevation ? blockName + '--' + 'elevation' : ''
    );
    const borderedClass = computed(() =>
      props.bordered ? blockName + '--' + 'bordered' : ''
    );
    const squaredClass = computed(() =>
      props.squared ? blockName + '--' + 'squared' : ''
    );
    const actionClass = computed(() =>
      props.action ? blockName + '--' + 'action' : ''
    );
    const disabledClass = computed(() =>
      props.disabled ? blockName + '--' + 'inactive' : ''
    );
    const flatClass = computed(() =>
      props.flat ? blockName + '--' + 'flat' : ''
    );

    const classes = computed(() => {
      return [
        blockName,
        styleTypeClass.value,
        fluidClass.value,
        sizeClass.value,
        elevationClass.value,
        borderedClass.value,
        squaredClass.value,
        actionClass.value,
        disabledClass.value,
        flatClass.value
      ];
    });

    const rippleTheme = computed(() => {
      if (props.styleType === 'disabled') {
        return null;
      } else if (
        !props.styleType ||
        props.styleType.match(/tertiary/) ||
        props.styleType.match(/quaternary/)
      ) {
        return 'light';
      } else {
        return 'dark';
      }
    });

    return {
      wrapper,
      classes,
      rippleTheme
    };
  }
});
</script>
