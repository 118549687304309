const DUMMY_BASE_URL = 'https://example.com';

/**
 * ページネーションの機構を提供する（モックAPIでのみ利用）
 *
 * @param {any[]} dataArray ページネーション対象のデータ配列
 * @param {number} [itemPerPage=10] 1ページあたりのデータ件数
 */
export const usePagination = (dataArray, itemPerPage = 10) => {
  /**
   * 指定ページへのページネーションを行う
   *
   * @param {number} [page=1] ページ番号 1, 2, 3, ...
   * @returns {{data: any[]; next_page_url: string | null; prev_page_url: string | null}}
   */
  const paginate = (page = 1) => {
    const start = (page - 1) * itemPerPage;
    const end = start + itemPerPage;

    return {
      data: dataArray.slice(start, end),
      next_page_url:
        dataArray.length > end ? `${DUMMY_BASE_URL}?page=${page + 1}` : null,
      prev_page_url: null // 使わないので適当
    };
  };

  return {
    paginate
  };
};
