<template>
  <div class="tag">
    <span class="tag__inner">
      {{ tag }}
    </span>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    tag: {
      type: String,
      default: ''
    }
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.tag {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem 0.4rem;
  background-color: variables.$grey04;
  min-width: 3em;

  /**
   * * マイル付与表記の非表示に伴いコメントアウト
   */
  // max-width: 7em;

  color: variables.$grey02;
  font-size: 1rem;
  line-height: 1.33;
}

.tag__inner {
  @include mixin.clamp(1);
}
</style>
