import Chip from './Chip.vue';

export default {
  title: 'SHARED/Chip',
  component: Chip
};

const Template = (args, { argTypes }) => ({
  components: { Chip },
  props: Object.keys(argTypes),
  template: `
    <div>
      <Chip>Default</Chip>
      <Chip type="primary">Primary</Chip>
      <Chip type="secondary">Secondary</Chip>
      <Chip disabled>Disabled</Chip>
    </div>
  `
});

export const Default = Template.bind({});
Default.args = {};
