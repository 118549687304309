/**
 * アプリ側で表示するWebViewのテンプレート種別
 */
export const VIEW_TEMPLATE = {
  COMMON: 'common', // 共通テンプレート
  EC: 'ec', // ECテンプレート
  SINGLE: 'single', // 単体テンプレート
  DETAIL: 'detail', // 詳細テンプレート

  DP_COMMON: 'dp-common', // DP共通テンプレート
  DP_TOP: 'dp-top' // DPトップテンプレート
};
