<template>
  <div class="c-coffeeCard">
    <a
      :href="href"
      :style="!isLinked ? 'pointer-events: none' : ''"
      v-ripple="isLinked">
      <div
        class="c-coffeeCard__img"
        :style="'background-image: url(' + src + ')'" />

      <div class="c-coffeeCard__content">
        <div v-if="barista" class="c-coffeeCard__contentHead">
          <div
            v-if="barista['image_url']"
            class="c-coffeeCard__contentHead__img">
            <ProAvatar :size="24" :src="barista['image_url']" alt="" />
          </div>
          <p class="c-coffeeCard__contentHead__name">
            {{ barista.name }}
          </p>
        </div>
        <!-- /.c-coffeeCard__contentHead -->

        <div
          v-if="title"
          class="c-coffeeCard__contentTitle"
          :class="titleClasses">
          {{ title }}
        </div>
        <!-- /.c-coffeeCard__contentTitle -->
      </div>
      <!-- /.c-coffeeCard__content -->
    </a>
  </div>
  <!-- /.c-coffeeCard -->
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
  props: {
    href: {
      type: String,
      default: ''
    },

    src: {
      type: String,
      default: ''
    },

    title: {
      type: String,
      default: ''
    },

    subtitle: {
      type: String,
      default: ''
    },

    barista: {
      type: Object,
      default: null
    },

    titleClamp: {
      type: Number,
      default: 2
    }
  },

  setup: (props) => {
    const isLinked = computed(() => props.href.length > 0);
    const titleClasses = computed(() =>
      props.titleClamp
        ? `c-coffeeCard__contentTitle--clamp${props.titleClamp}`
        : ''
    );

    return {
      isLinked,
      titleClasses
    };
  }
});
</script>
