import variables from '@/assets/scss/_variables.scss';

const regex = {
  brand: /brand-.*?/,
  common: /common-.*?/,
  dp: /dp-.*?/
};

let _colors = {
  brand: {},
  common: {},
  dp: {}
};

Object.keys(variables).forEach((variable) => {
  if (variable.match(regex.brand)) {
    const color = variable.replace('brand-', '');
    _colors.brand[color] = variables[variable];
  } else if (variable.match(regex.dp)) {
    const color = variable.replace('dp-', '');
    _colors.dp[color] = variables[variable];
  } else {
    const color = variable.replace('common-', '');
    _colors.common[color] = variables[variable];
  }
});

export const colors = _colors;
