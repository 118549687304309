<template>
  <div :class="classes">
    <slot />
  </div>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api';
export default defineComponent({
  props: {
    direction: {
      type: String,
      default: 'col'
    },

    justifyContent: {
      type: String,
      default: undefined
    },

    alignItems: {
      type: String,
      default: undefined
    },

    spacing: {
      type: Number,
      default: 0,
      validator: (v) => !isNaN(v) && v >= 0 && v <= 12
    }
  },

  setup(props) {
    const classes = computed(() => ({
      flex: true,
      [`flex-${props.direction}`]: true,
      [`justify-${props.justifyContent}`]: Boolean(props.justifyContent),
      [`items-${props.alignItems}`]: Boolean(props.alignItems),
      [`gap-${props.spacing}`]: true
    }));

    return {
      classes
    };
  }
});
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
}

/* flex direction */
.flex {
  &-row {
    flex-direction: row;
  }
  &-row-reverse {
    flex-direction: row-reverse;
  }
  &-col {
    flex-direction: column;
  }
  &-col-reverse {
    flex-direction: column-reverse;
  }
}

/* justify content */
.justify {
  &-start {
    justify-content: flex-start;
  }
  &-end {
    justify-content: flex-end;
  }
  &-center {
    justify-content: center;
  }
  &-between {
    justify-content: space-between;
  }
  &-around {
    justify-content: space-around;
  }
  &-evenly {
    justify-content: space-evenly;
  }
}

/* align items */
.items {
  &-start {
    align-items: flex-start;
  }
  &-end {
    align-items: flex-end;
  }
  &-center {
    align-items: center;
  }
  &-baseline {
    align-items: baseline;
  }
  &-stretch {
    align-items: stretch;
  }
}

/* gap */
$base-gap: 4px;
@for $i from 0 through 12 {
  .gap-#{$i} {
    gap: $base-gap * $i;
  }
}
</style>
