<template>
  <div class="c-incentive">
    <span v-if="!isCompleted" class="c-incentive__state">
      <Icon name="checkCircleFilled" width="14" height="14" color="grey03" />
    </span>
    <strong class="c-incentive__amount">
      {{ $contentList.incentiveValue(reward) }}
    </strong>
    <span class="c-incentive__unit">{{ $contentList.incentiveUnit() }}</span>
  </div>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
  props: {
    reward: {
      type: [Object, Array],
      default: undefined
    }
  },

  setup: (props) => {
    const isCompleted = computed(() => (props.state ? props.state : false));

    return {
      isCompleted
    };
  }
});
</script>
