<template>
  <div class="c-baseCheckbox c-form__item">
    <ValidationProvider v-bind="veeProps" v-slot="{ errors, failed }">
      <div class="c-baseCheckbox__controlWrapper c-formControl">
        <input
          :id="`input-${uid}`"
          type="checkbox"
          :value="value"
          :checked="checked"
          class="c-baseCheckbox__control"
          :class="{ 'has-error': failed }"
          @change="onChange" />
        <label :for="`input-${uid}`" class="c-baseCheckbox__label">
          <slot v-if="$slots.default" />
          <template v-else>
            {{ label }}
          </template>
        </label>
      </div>
      <div v-if="errors[0]" class="c-errorMessage">{{ errors[0] }}</div>
    </ValidationProvider>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },

    label: {
      type: String,
      default: ''
    },

    veeProps: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    uid() {
      return this._uid;
    },

    checked() {
      return this.value;
    }
  },

  methods: {
    onChange(e) {
      this.$emit('input', e.target.checked);
    }
  }
};
</script>
