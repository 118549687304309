import HeadImage from './HeadImage.vue';

export default {
  title: 'SHARED/HeadImage',
  component: HeadImage,
  parameters: {
    layout: 'fullscreen'
  },
  argTypes: {
    mapZone: {
      options: ['A', 'B', 'C', 'D'],
      control: { type: 'select' }
    }
  }
};

const Template = (args, { argTypes }) => ({
  components: { HeadImage },
  props: Object.keys(argTypes),
  template: '<HeadImage v-bind="$props" />'
});

const src = 'https://via.placeholder.com/700x460/0bc/fff?text=IMAGE';

export const Default = Template.bind({});
Default.args = {
  action: null,
  contain: false,
  mapZone: '',
  src
};

export const Like = Template.bind({});
Like.args = {
  action: { type: 'like', at: '1640340430' },
  contain: false,
  mapZone: 'A',
  src
};

export const Hate = Template.bind({});
Hate.args = {
  action: { type: 'hate', at: '1640340430' },
  contain: false,
  mapZone: 'A',
  src
};

export const Memo = Template.bind({});
Memo.args = {
  action: { type: 'memo', at: '1640340430' },
  contain: false,
  mapZone: 'A',
  src
};
