const postResponse = [
  {
    id: 16,
    created_at: '2023-02-15T15:00:00.000000Z',
    name: 'ベトナム風コーヒー_抽出工程が長い',
    image_url:
      'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/dp-recipe/test06_m.png',
    capsule: {
      id: 9,
      name: '炭焼珈琲',
      image_url:
        'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/capsule/img_capsule-10.png',
      description:
        '炭火で丹念に焙煎したスモーキーな香りとコク深く、甘い余韻のある味わい。',
      map_position_id: 11,
      taste_parameter: {
        rich: 4,
        bitter: 4,
        acidity: 2
      },
      link_url: 'https://drip-pod.jp/products/1374',
      order: 992,
      recommend: false,
      is_list_publishable: true,
      is_publishable: true,
      publish_start_at: '2023-01-07 00:00:00',
      publish_end_at: '2024-01-07 00:00:00',
      created_at: '2023-02-02T03:39:03.000000Z',
      updated_at: '2023-02-02T03:39:03.000000Z'
    },
    barista: null,
    recipe_id: 16,
    is_bookmark: true,
    is_publishable: true,
    publish_start_at: '2023-01-06 00:00:00',
    publish_end_at: '2024-01-06 00:00:00'
  }
];

export { postResponse };
