import data from './data/notices.json';

export default {
  get({ values }) {
    const response =
      data.data.find((item) => item.id === values.id) || data.data[0];
    return [200, response];

    // error: タイムアウト
    // return [408, { message: 'タイムアウトしました' }];
  }
};
