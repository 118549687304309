/**
 * お知らせ種別
 *
 * @see https://bitbucket.org/sonicmoov/ucc_coffee_creation_api/src/develop/app/Enums/MessageCategoryType.php
 */
export class MessageCategoryType {
  static NOTICE = 1; // お知らせ
  static EVENT = 2; // イベント
  static MAINTENANCE = 3; // メンテナンス

  /**
   * タイプIDからタイプ名を取得する
   * @param {number} type
   * @returns {string | undefined}
   */
  static getTypeName = (type) => {
    switch (type) {
      case this.NOTICE:
        return 'Information';
      case this.EVENT:
        return 'Event';
      case this.MAINTENANCE:
        return 'Maintenance';
    }
  };
}
