import Balloon from './Balloon.vue';
import Stack from './Stack.vue';

export default {
  title: 'SHARED/Balloon',
  component: Balloon,
  argTypes: {
    type: {
      options: [null, 'line'],
      control: { type: 'select' }
    },
    size: {
      options: ['md', 'lg'],
      control: { type: 'select' }
    }
  }
};

const Template = (args, { argTypes }) => ({
  components: { Balloon },
  props: Object.keys(argTypes),
  template: '<Balloon v-bind="$props">Balloon</Balloon>'
});

const DirectionTemplate = (args, { argTypes }) => ({
  components: { Balloon, Stack },
  props: Object.keys(argTypes),
  template: `
    <Stack direction="row" :spacing="4">
      <Balloon top v-bind="$props">Top</Balloon>
      <Balloon right v-bind="$props">Right</Balloon>
      <Balloon bottom v-bind="$props">Bottom</Balloon>
      <Balloon left v-bind="$props">Left</Balloon>
    </Stack>
  `
});

const BlockTemplate = (args, { argTypes }) => ({
  components: { Balloon, Stack },
  props: Object.keys(argTypes),
  template: `
    <Stack align-items="center" :spacing="4">
      <Balloon v-bind="$props">Block</Balloon>
      <Balloon align-left v-bind="$props">Align Left</Balloon>
      <Balloon align-center v-bind="$props">Align Center</Balloon>
      <Balloon align-right v-bind="$props">Align Right</Balloon>
    </Stack>
  `
});

const SizeTemplate = (args, { argTypes }) => ({
  components: { Balloon, Stack },
  props: Object.keys(argTypes),
  template: `
    <Stack direction="row" :spacing="4">
      <Balloon size="md" v-bind="$props">Medium</Balloon>
      <Balloon size="lg" v-bind="$props">Large</Balloon>
    </Stack>
  `
});

export const Default = Template.bind({});

export const Direction = DirectionTemplate.bind({});

export const Rounded = Template.bind({});
Rounded.args = {
  rounded: true
};

export const Elevated = Template.bind({});
Elevated.args = {
  elevated: true
};

export const Bold = Template.bind({});
Bold.args = {
  bold: true
};

export const Block = BlockTemplate.bind({});
Block.args = {
  block: true
};

export const LINEType = Template.bind({});
LINEType.args = {
  type: 'line'
};

export const Size = SizeTemplate.bind({});
