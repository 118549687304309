import Stack from './Stack.vue';

export default {
  title: 'SHARED/Stack',
  component: Stack,
  argTypes: {
    direction: {
      options: ['row', 'col', 'row-reverse', 'col-reverse'],
      control: { type: 'radio' }
    },
    justifyContent: {
      options: ['start', 'end', 'center', 'between', 'around', 'evenly'],
      control: { type: 'select' }
    },
    alignItems: {
      options: ['start', 'end', 'center', 'stretch', 'baseline'],
      control: { type: 'select' }
    },
    spacing: {
      options: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      control: { type: 'select' }
    }
  },
  parameters: {
    chromatic: { disableSnapshot: true }
  }
};

const Template = (args, { argTypes }) => ({
  components: { Stack },
  props: Object.keys(argTypes),
  template: `
    <Stack v-bind="$props">
      <div style="background: #CEC8BA; padding: 4px; border: 1px solid #696458">A</div>
      <div style="background: #CEC8BA; padding: 4px; border: 1px solid #696458">B</div>
      <div style="background: #CEC8BA; padding: 4px; border: 1px solid #696458">C</div>
    </Stack>
  `
});

const args = {
  direction: 'col',
  spacing: 0
};

export const Default = Template.bind({});
Default.args = {
  ...args
};

export const Column = Template.bind({});
Column.args = {
  ...args
};

export const Row = Template.bind({});
Row.args = {
  ...args,
  direction: 'row'
};

export const Spacing = Template.bind({});
Spacing.args = {
  ...args,
  spacing: 3
};
