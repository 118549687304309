import Card from './Card.vue';

/**
 * NOTE:
 * CardコンポーネントはWrapされる前提で実装されており、
 * DOM構造を強制する作りではないため、Storyは簡素な作りに留める。
 * Cardコンポーネントの活用側（Wrapper側）のStoryでUI担保する。
 */
export default {
  title: 'SHARED/Card',
  component: Card,
  argTypes: {
    radius: {
      options: [0, 12, 30],
      control: { type: 'select' }
    }
  }
};

const Template = (args, { argTypes }) => ({
  components: { Card },
  props: Object.keys(argTypes),
  template: '<Card v-bind="$props">Card</Card>'
});

export const Default = Template.bind({});
Default.args = {
  radius: 0,
  elevation: false,
  tiled: false,
  link: false,
  noticed: false,
  skeleton: false,
  squaredThumbnail: false
};
