import { wordings } from '@/constants/wordings';

/**
 * リワード種別
 *
 * @see https://bitbucket.org/sonicmoov/ucc_coffee_creation_api/src/develop/app/Enums/DatabaseRewardType.php
 */
export class RewardType {
  static MILEAGE = 1; // マイル
  static POINT = 2; // オンラインストアポイント
  static COUPON = 3; // オンラインストアクーポン

  /**
   * タイプIDからタイプ名を取得する
   * @param {number} type
   * @returns {string | undefined}
   */
  static getTypeName = (type) => {
    switch (type) {
      case this.MILEAGE:
        return wordings.mile;
      case this.POINT:
        return wordings.onlineStorePoint;
      case this.COUPON:
        return wordings.onlineStoreCoupon;
    }
  };

  /**
   * タイプIDから単位を取得する
   * @param {number} type
   * @returns {string | undefined}
   */
  static getTypeUnit = (type) => {
    switch (type) {
      case this.MILEAGE:
        return wordings.mile;
      case this.POINT:
        return wordings.point;
      case this.COUPON:
        return '円OFF';
    }
  };
}
