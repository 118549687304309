import { coffeeMap, stylistMessage } from './data/data.js';

export default {
  get() {
    const response = {
      recommend_message: stylistMessage,
      coffee_map: coffeeMap
    };
    return [200, response];

    // error: タイムアウト
    // return [408, { message: 'タイムアウトしました' }];
  }
};
