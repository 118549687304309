import { data } from './data/data';

export default {
  get() {
    return [200, data[0]];

    // error: タイムアウト
    // return [408, { message: 'タイムアウトしました' }];
  }
};
