var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      class: _vm.classes,
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: _vm.width,
        height: _vm.height,
        viewBox: _vm.viewBox,
        "aria-label": _vm.icon.name,
        role: "img",
      },
    },
    [_c("g", { domProps: { innerHTML: _vm._s(_vm.icon.path) } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }