<template>
  <div class="c-coffeeCardTiled">
    <a
      :href="href ? href : false"
      :style="!isLinked ? 'pointer-events: none' : ''"
      v-ripple="isLinked">
      <div class="c-coffeeCardTiled__img">
        <img v-if="src" :src="src" :alt="title" />
        <NoImage v-else />
        <template v-if="impression != null">
          <Icon
            v-if="isFav"
            name="faceGoodBordered"
            color="accent02"
            class="c-coffeeCardTiled__impression" />
          <Icon
            v-else
            name="faceBadBordered"
            color="grey01"
            class="c-coffeeCardTiled__impression" />
        </template>
      </div>

      <div class="c-coffeeCardTiled__content">
        <div v-if="barista" class="c-coffeeCardTiled__contentHead">
          <div
            v-if="barista.image_url"
            class="c-coffeeCardTiled__contentHead__img">
            <ProAvatar :src="barista.image_url" :alt="barista.name" :size="24" :is-bordered="false" />
          </div>
          <div class="c-coffeeCardTiled__contentHead__name">
            {{ barista.name }}
          </div>
          <div v-if="type" class="c-coffeeCardTiled__contentHead__type">
            {{ type }}
          </div>
        </div>

        <div
          v-if="title"
          class="c-coffeeCardTiled__contentTitle"
          :class="titleClasses">
          {{ title }}
        </div>

        <div v-if="description" class="c-coffeeCardTiled__contentBody">
          {{ description }}
        </div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    href: {
      type: [String, Boolean],
      default: ''
    },

    src: {
      type: String,
      default: ''
    },

    title: {
      type: String,
      default: ''
    },

    description: {
      type: String,
      default: ''
    },

    barista: {
      type: Object,
      default: null
    },

    impression: {
      // @values 1:好き | 0:う〜ん...
      type: Number,
      default: null
    },

    titleClamp: {
      type: Number,
      default: 2
    },

    type: {
      type: String,
      default: ''
    }
  },

  computed: {
    isLinked() {
      return this.href.length > 0;
    },

    isFav() {
      return this.impression === 1;
    },

    titleClasses() {
      return this.titleClamp
        ? `c-coffeeCardTiled__contentTitle--clamp${this.titleClamp}`
        : '';
    }
  }
};
</script>
