export const wordings = {
  appName: process.env.VUE_APP_NAME,
  official: 'UCC公式',

  // コンテンツタブ
  top: 'トップ',
  read: '読む',
  join: '参加する',
  study: '学ぶ',

  // 動詞
  save: '保存',
  edit: '編集',
  more: 'を見る',

  // インセンティブ関係
  stage: 'ステージ',
  mile: 'マイル',
  point: 'ポイント',
  onlineStore: 'オンラインストア',
  onlineStorePoint: 'オンラインストアポイント',
  onlineStoreCoupon: 'オンラインストアクーポン',

  // アカウント
  accountLogin: 'ログイン',
  accountCreate: '新規登録',
  accountTakeover: '引継',
  accountInfo: '会員情報',
  accountLink: '連携',
  accountLine: 'LINEアカウント',

  // 画面・コンテンツ名
  mypage: 'マイページ',
  present: 'プレゼント',
  presentBox: 'プレゼントBOX',
  notice: 'お知らせ',
  tutorial: 'チュートリアル',
  mileReward: '特典',
  mileHistory: 'マイル履歴',
  event: 'イベント',
  maintenance: 'メンテナンス'
};
