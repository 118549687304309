import { dialog } from '@/plugins/customUrlScheme';

/**
 * @example
 * $systemDialog(SystemDialogMessage.ERROR_COMMON)
 */
const systemDialog = (_, inject) => {
  const message = (messageId) => {
    return dialog('message', messageId);
  };
  inject('systemDialog', message);
};

export { systemDialog };
