import { usePagination } from '@/composables/usePagination';
import { data } from '../data/rewards';

export default {
  get({ params }) {
    const { paginate } = usePagination(data, 8);
    const response = { ...paginate(params.page) };
    return [200, response];

    // error: タイムアウト
    // return [408, { message: 'タイムアウトしました' }];
  }
};
