import Repository from './Repository';
const resource = '/contact';

export default {
  /**
   * お問い合わせカテゴリ一覧取得
   */
  getCategories() {
    return Repository.get(`${resource}`);
  },

  /**
   * お問い合わせ送信
   *
   * @param {{ mail: string; contact_id: number; content: string; }} payload
   */
  post(payload) {
    return Repository.post(`${resource}`, payload);
  }
};
