import Vue from 'vue';
import VueGtm from '@gtm-support/vue2-gtm';
import { useEnvironment } from '@/composables/useEnvironment';
const { isDebug, GTM_CONTAINER_ID } = useEnvironment();

export const initGtm = (router) => {
  /**
   * Vue Google Tag Manager
   * @see https://www.npmjs.com/package/@gtm-support/vue2-gtm
   */
  Vue.use(VueGtm, {
    id: GTM_CONTAINER_ID,
    enabled: true,
    debug: isDebug,
    vueRouter: router
  });
};
