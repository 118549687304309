var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-coffeeCardTiled" }, [
    _c(
      "a",
      {
        directives: [
          {
            name: "ripple",
            rawName: "v-ripple",
            value: _vm.isLinked,
            expression: "isLinked",
          },
        ],
        style: !_vm.isLinked ? "pointer-events: none" : "",
        attrs: { href: _vm.href ? _vm.href : false },
      },
      [
        _c(
          "div",
          { staticClass: "c-coffeeCardTiled__img" },
          [
            _vm.src
              ? _c("img", { attrs: { src: _vm.src, alt: _vm.title } })
              : _c("NoImage"),
            _vm.impression != null
              ? [
                  _vm.isFav
                    ? _c("Icon", {
                        staticClass: "c-coffeeCardTiled__impression",
                        attrs: { name: "faceGoodBordered", color: "accent02" },
                      })
                    : _c("Icon", {
                        staticClass: "c-coffeeCardTiled__impression",
                        attrs: { name: "faceBadBordered", color: "grey01" },
                      }),
                ]
              : _vm._e(),
          ],
          2
        ),
        _c("div", { staticClass: "c-coffeeCardTiled__content" }, [
          _vm.barista
            ? _c("div", { staticClass: "c-coffeeCardTiled__contentHead" }, [
                _vm.barista.image_url
                  ? _c(
                      "div",
                      { staticClass: "c-coffeeCardTiled__contentHead__img" },
                      [
                        _c("ProAvatar", {
                          attrs: {
                            src: _vm.barista.image_url,
                            alt: _vm.barista.name,
                            size: 24,
                            "is-bordered": false,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "c-coffeeCardTiled__contentHead__name" },
                  [_vm._v(" " + _vm._s(_vm.barista.name) + " ")]
                ),
                _vm.type
                  ? _c(
                      "div",
                      { staticClass: "c-coffeeCardTiled__contentHead__type" },
                      [_vm._v(" " + _vm._s(_vm.type) + " ")]
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm.title
            ? _c(
                "div",
                {
                  staticClass: "c-coffeeCardTiled__contentTitle",
                  class: _vm.titleClasses,
                },
                [_vm._v(" " + _vm._s(_vm.title) + " ")]
              )
            : _vm._e(),
          _vm.description
            ? _c("div", { staticClass: "c-coffeeCardTiled__contentBody" }, [
                _vm._v(" " + _vm._s(_vm.description) + " "),
              ])
            : _vm._e(),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }