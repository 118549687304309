<template>
  <div class="c-baseSelect c-form__item">
    <FormLabel :label="label" :hint="hint" :required="required" />
    <ValidationProvider v-bind="veeProps" v-slot="{ errors, failed }">
      <div class="c-baseSelect__controlWrapper c-formControl">
        <select
          :value="value"
          class="c-baseSelect__control"
          :class="{ 'has-error': failed }"
          @change="$emit('input', $event.target.value)">
          <option value="" hidden>選択してください</option>
          <option v-for="(item, i) in items" :key="i" :value="item.value">
            {{ item.label }}
          </option>
        </select>
      </div>
      <div v-if="errors[0]" class="c-errorMessage">{{ errors[0] }}</div>
    </ValidationProvider>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: ''
    },

    required: {
      type: Boolean,
      default: false
    },

    label: {
      type: String,
      default: ''
    },

    hint: {
      type: String,
      default: ''
    },

    veeProps: {
      type: Object,
      default: () => ({})
    },

    items: {
      /**
       * @type {{ label: string | number; value: string | number }[]}
       */
      type: Array,
      required: true
    }
  }
};
</script>
