<template>
  <li class="c-expantionPanel">
    <button
      type="button"
      class="c-expantionPanel__header"
      :class="{ 'is-active': isExpanded }"
      @click="handleToggle">
      <span class="c-expantionPanel__header__text">
        <slot name="header" />
        <template v-if="!$slots.header">{{ header }}</template>
      </span>
      <Icon
        name="arrowBottom"
        width="20"
        height="20"
        class="c-expantionPanel__header__icon" />
    </button>
    <transition
      name="topSlide"
      @before-enter="beforeEnter"
      @enter="enter"
      @before-leave="beforeLeave"
      @leave="leave">
      <div v-show="isExpanded" class="c-expantionPanel__content topSlide">
        <slot />
        <template v-if="!$slots.default">{{ content }}</template>
      </div>
    </transition>
  </li>
</template>

<script>
export default {
  props: {
    header: {
      type: String,
      default: null
    },

    content: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      isExpanded: false
    };
  },

  methods: {
    // メニューを開閉する
    handleToggle() {
      this.isExpanded = !this.isExpanded;
    },

    // スライド開閉要素の高さ取得
    beforeEnter(el) {
      el.style.height = '0';
    },

    enter(el) {
      el.style.height = el.scrollHeight + 'px';
    },

    beforeLeave(el) {
      el.style.height = el.scrollHeight + 'px';
    },

    leave(el) {
      el.style.height = '0';
    }
  }
};
</script>
