import CoffeeCardTiled from './CoffeeCardTiled.vue';

export default {
  title: 'SHARED/CoffeeCardTiled',
  component: CoffeeCardTiled
};

const Template = (args, { argTypes }) => ({
  components: { CoffeeCardTiled },
  props: Object.keys(argTypes),
  template: '<CoffeeCardTiled v-bind="$props" />'
});

const ListTemplate = (args, { argTypes }) => ({
  components: { CoffeeCardTiled },
  props: Object.keys(argTypes),
  template: `
    <div class="c-cards c-cards--tiled">
      <div class="c-cards__inner">
        <CoffeeCardTiled :impression="0" v-bind="$props" />
      </div>
      <div class="c-cards__inner">
        <CoffeeCardTiled :impression="1" v-bind="$props" />
      </div>
    </div>
  `
});

const args = {
  href: '',
  src: 'https://via.placeholder.com/128x128/b35/fff',
  title:
    '製品タイトル製品タイトル製品タイトル製品タイトル製品タイトル製品タイトル製品タイトル製品タイトル',
  description:
    'コーヒー好きを満足させる、おいしいカフェインレスコーヒー、原材料製法にこだわりました。',
  barista: {
    name: '田中 太郎',
    image_url: 'https://via.placeholder.com/128x128/3c4/fff'
  },
  impression: null,
  titleClamp: 2,
  type: ''
};

export const Default = Template.bind({});
Default.args = {
  ...args
};

export const NoBarista = Template.bind({});
NoBarista.args = {
  ...args,
  barista: null
};

export const WithImpression = ListTemplate.bind({});
WithImpression.args = {
  ...args
};
