import Repository from './Repository';
const resource = '/present';

export default {
  /**
   * プレゼント一覧取得
   *
   * @param {string} [queryString] クエリ文字列 ?page=2
   */
  getPresents(queryString = '') {
    return Repository.get(`${resource}${queryString}`);
  },

  /**
   * プレゼント詳細取得
   *
   * @param {number | string} presentId
   */
  getPresent(presentId) {
    return Repository.get(`${resource}/${presentId}`);
  },

  usePresent(presentId) {
    return Repository.post(`${resource}/${presentId}`);
  }
};
