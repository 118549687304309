var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-baseInput c-form__item" },
    [
      _c("FormLabel", {
        attrs: { label: _vm.label, hint: _vm.hint, required: _vm.required },
      }),
      _c(
        "ValidationProvider",
        _vm._b(
          {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var errors = ref.errors
                  var failed = ref.failed
                  return [
                    _c(
                      "input",
                      _vm._b(
                        {
                          staticClass: "c-baseInput__control c-formControl",
                          class: { "has-error": failed },
                          attrs: { type: _vm.type },
                          domProps: { value: _vm.value },
                          on: {
                            input: function ($event) {
                              return _vm.$emit("input", $event.target.value)
                            },
                          },
                        },
                        "input",
                        _vm.$attrs,
                        false
                      )
                    ),
                    errors[0]
                      ? _c("div", { staticClass: "c-errorMessage" }, [
                          _vm._v(_vm._s(errors[0])),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          },
          "ValidationProvider",
          _vm.veeProps,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }