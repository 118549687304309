<template>
  <a :href="href" class="c-buttonLineLogin" :class="classes">
    <span class="c-buttonLineLogin__logo">
      <Icon
        name="logoLine"
        width="43"
        height="40"
        :color="disabled ? 'lineDisabledText' : 'white01'" />
    </span>
    <span class="c-buttonLineLogin__label"><slot>{{ WORDS.LINE_ID }}と{{ WORDS.LINK }}する</slot></span>
  </a>
</template>

<script>
import { WORDS } from '@/constants/words';
import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
  props: {
    href: {
      type: String,
      default: '#'
    },

    fluid: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  setup: (props) => {
    const classes = computed(() => {
      return {
        'c-buttonLineLogin__fluid': props.fluid,
        'is-disabled': props.disabled
      };
    });

    return {
      WORDS,
      classes
    };
  }
});
</script>
