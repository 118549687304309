import ButtonLineLogin from './ButtonLineLogin.vue';

export default {
  title: 'SHARED/ButtonLineLogin',
  component: ButtonLineLogin
};

const Template = (args, { argTypes }) => ({
  components: { ButtonLineLogin },
  props: Object.keys(argTypes),
  template: '<ButtonLineLogin v-bind="$props" />'
});

export const Default = Template.bind({});
Default.args = {
  href: '',
  fluid: false,
  disabled: false
};
