import { data } from './data/data';

export default {
  get({ values }) {
    const res = data.find((item) => item.id === Number(values.id)) || data[0];
    return [200, res];

    // error: タイムアウト
    // return [408, { message: 'タイムアウトしました' }];
  },

  post() {
    return [200, { id: 1 }];
  }
};
