const data = {
  category: [
    {
      id: 1,
      category: 'アプリについて'
    },
    {
      id: 2,
      category: 'イベントについて'
    },
    {
      id: 3,
      category: 'オンラインストアについて'
    }
  ]
};

export { data };
