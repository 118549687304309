var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-empty" }, [
    _c(
      "div",
      { staticClass: "c-empty__icon" },
      [
        _vm.iconName !== "none"
          ? _c("Icon", { attrs: { name: _vm.iconName } })
          : _vm._e(),
      ],
      1
    ),
    _c("div", { staticClass: "c-empty__text" }, [
      _c("p", { staticClass: "c-title6 c-title6--grey02" }, [
        _vm._v(_vm._s(_vm.text)),
      ]),
    ]),
    _vm.subtext
      ? _c("div", { staticClass: "c-empty__subtext" }, [
          _c("p", [_vm._v(_vm._s(_vm.subtext))]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }