<template>
  <div class="c-noimage">
    <img :src="src" :alt="alt" />
  </div>
  <!-- /.c-noimage -->
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: '/assets/img/pic_noimage01.png'
    },

    alt: {
      type: String,
      default: ''
    }
  }
};
</script>
