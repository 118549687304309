import data from '../data/histories.json';

export default {
  get() {
    return [200, data];

    // error: タイムアウト
    // return [408, { message: 'タイムアウトしました' }];
  }
};
