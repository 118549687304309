<template>
  <span class="c-chip" :class="classes">
    <span class="c-chip__content"><slot>Chip Component</slot></span>
  </span>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api';
export default defineComponent({
  props: {
    type: {
      type: String,
      // @values primary | secondary | black
      default: 'default'
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  setup: (props) => {
    const blockName = 'c-chip';
    const typeClass = computed(() => blockName + '--' + props.type);
    const disabledClass = computed(() => (props.disabled ? 'is-disabled' : ''));
    const classes = computed(() => [typeClass.value, disabledClass.value]);

    return {
      classes
    };
  }
});
</script>
