<template>
  <ul v-if="content" class="pro-card">
    <li v-for="pro in content" :key="pro.index" class="pro-card-item">
      <component
        :is="pro.id ? 'a' : 'div'"
        :href="
          pro.id
            ? $customUrlScheme.page('detail', `url=/pro/detail/${pro.id}`)
            : false
        "
        class="pro-card-item-head"
        @click="onClick">
        <div class="pro-card-item-head-thumbnail">
          <div class="pro-card-item-head-thumbnail__inner">
            <ProAvatar
              :src="pro.image_url"
              size="64"
              :alt="pro.name"
              :is-bordered="false" />
          </div>
        </div>

        <div class="pro-card-item-head-profile">
          <header class="pro-card-item-head-profile__head">
            <p class="pro-card-item-head-profile__title">
              {{ pro['name'] }}
            </p>
            <span class="pro-card-item-head-profile__sub">
              {{ pro['name_alphabetical'] }}
            </span>
          </header>

          <p class="pro-card-item-head-profile__description">
            {{ pro['overview'] }}
          </p>
        </div>
      </component>
    </li>

    <li v-if="description">
      <div class="pro-card-item-bottom">
        <div class="c-card c-card--beige03Bg">
          <p class="c-lead2 c-lead2--beige01">
            {{ description }}
          </p>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  props: {
    content: {
      type: Array,
      default: null
    },

    description: {
      type: String,
      default: ''
    }
  },

  setup: (props, context) => {
    const onClick = () => {
      context.emit('click-pro-card');
    };

    return {
      onClick
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.pro-card {
  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
}

.pro-card-item {
  z-index: 0;
  position: relative;
  &:not(:last-child) {
    margin-bottom: 16px;
  }

  > * {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}

.pro-card-item-head {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px;
  background-color: variables.$white01;
  border-radius: 8px;
  box-shadow: variables.$elevationsMd;
}

.pro-card-item-head-thumbnail {
  flex-basis: 72px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 8px;
  &__inner {
    // アスペクト比 64:64 で画像描画
    @include mixin.imageAspectRatio(64, 64);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    border-radius: 50%;
    overflow: hidden;
  }
}

.pro-card-item-head-profile {
  flex-grow: 1;
  &__head {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 4px;
    flex-wrap: wrap;

    > * {
      margin-bottom: 0;
    }
  }
  &__title {
    padding-right: 8px;
    font-size: 18px;
    line-height: 1.25;
    font-weight: bold;

    > * {
      margin-bottom: 0;
    }
  }
  &__sub {
    color: variables.$grey02;
    font-size: 11px;
  }
  &__description {
    margin-bottom: 0;
    color: variables.$grey02;
    font-size: 13px;
    line-height: 1.5;
    white-space: pre-line;
  }
}

.pro-card-item-bottom {
  position: relative;
  margin-top: 24px;
  width: 100%;
  &::before {
    position: absolute;
    content: '';
    top: -12px;
    left: 42px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 6px 12px 6px;
    border-color: transparent transparent variables.$beige03 transparent;
  }
}
</style>
