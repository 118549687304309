/**
 * イベント管理新規作成画面等で使用する地図設定種別
 *
 * @see https://bitbucket.org/sonicmoov/ucc_coffee_creation_api/src/develop/app/Enums/Event/MapCategoryType.php
 */
export class MapCategoryType {
  static NOTHING = 1; // なし
  static ADDRESS = 2; // 住所
  static POSITION = 3; // 緯度・経度

  /**
   * タイプIDからタイプ名を取得する
   * @param {number} type
   * @returns {string | undefined}
   */
  static getTypeName = (type) => {
    switch (type) {
      case this.NOTHING:
        return 'なし';
      case this.ADDRESS:
        return '住所';
      case this.POSITION:
        return '緯度・経度';
    }
  };
}
