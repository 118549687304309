import BottomSheet from './BottomSheet.vue';

export default {
  title: 'SHARED/BottomSheet',
  component: BottomSheet,
  argTypes: {
    defaultState: {
      options: ['open', 'half'],
      control: { type: 'select' }
    }
  }
};

const Template = (args, { argTypes }) => ({
  components: { BottomSheet },
  props: Object.keys(argTypes),
  template: `
    <BottomSheet v-bind="$props">
      <template v-slot:header>
        <div class="c-section">
          <h2>Header Contents Slot</h2>
          <p>上に引き上げることで、コンテンツ領域を展開できます</p>
        </div>
      </template>
      <div class="c-section">
        <h2>Bottom Sheet Contents Slot</h2>
        <p>BottomSheet は以下の2つの状態を持ちます</p>
        <p>・画面のほぼ全てを占有する open</p>
        <p>・画面の約半分を占有する half</p>
      </div>
    </BottomSheet>
  `
});

export const HalfState = Template.bind({});
HalfState.args = {
  defaultState: 'half'
};

export const OpenState = Template.bind({});
OpenState.args = {
  defaultState: 'open'
};
