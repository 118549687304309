import Repository from '../Repository';
const resource = '/dp/capsule';

export default {
  getList() {
    return Repository.get(`${resource}`);
  },
  getById(id) {
    return Repository.get(`${resource}/${id}`);
  }
};
