import ExpansionPanels from './ExpansionPanels.vue';
import ExpansionPanel from './ExpansionPanel.vue';

export default {
  title: 'SHARED/ExpansionPanel',
  component: ExpansionPanel
};

const Template = (args, { argTypes }) => ({
  components: { ExpansionPanels, ExpansionPanel },
  props: Object.keys(argTypes),
  template: `
    <ExpansionPanels>
      <ExpansionPanel v-bind="$props" />
      <ExpansionPanel v-bind="$props" />
      <ExpansionPanel v-bind="$props" />
    </ExpansionPanels>
  `
});

export const Default = Template.bind({});
Default.args = {
  header: 'Q. このアプリでできること',
  content:
    'A. コーヒーの感想登録情報をもとに今の味覚傾向をマップで確認することができます！'
};
