import * as Sentry from '@sentry/vue';

export const initSentry = async (app, router) => {
  const MODE = process.env.VUE_APP_MODE;
  const isPrd = MODE === 'PRD';
  const SENTRY_DSN = process.env.VUE_APP_SENTRY_DSN;
  const APP_ORIGIN = process.env.VUE_APP_ORIGIN;

  Sentry.init({
    app,
    dsn: SENTRY_DSN,
    environment: MODE,
    enabled: !isPrd,
    // debug: !isPrd,
    logErrors: !isPrd,
    tracesSampleRate: 0.02,

    tracingOptions: {
      trackComponents: true,
      hooks: ['activate', 'create', 'destroy', 'mount', 'update']
    },

    integrations: [
      new Sentry.BrowserTracing({
        tracingOrigins: [APP_ORIGIN],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      })
    ],

    beforeSend(event, hint) {
      if (event.exception) {
        // eslint-disable-next-line no-console
        console.debug(`[Sentry]: (${hint.originalException})`, { event, hint });
      }
      return event;
    }
  });
};
