import { useNativeConnection } from '@/composables/useNativeConnection';
import moment from 'moment';
import { colors } from '@/composables/useColors';

export default (context, inject) => {
  /**
   * 日時のフォーマット、ローカライズなど
   * @see https://momentjs.com/docs/
   */
  moment.locale('ja');
  const momentFormat = {
    date: 'YYYY/MM/DD',
    dateWeek: 'YYYY/MM/DD (ddd)',
    dateTime: 'YYYY/MM/DD (ddd) HH:mm',
    time: 'HH:mm'
  };

  // 1627298048.123 UNIXタイムスタンプ（ms）
  // 1627298048123 UNIXタイムスタンプ（ms）
  // 1627298048 UNIXタイムスタンプ（s）
  const unixRegexp = {
    msX: /\d{13}/,
    s: /\d{10}.*\d*/
  };

  inject('utilities', {
    // YYYY/MM/DD 形式の日付を取得する
    localizeDate(value) {
      if (unixRegexp.msX.test(value)) {
        return moment.unix(value / 1000).format(momentFormat.date);
      } else if (unixRegexp.s.test(value)) {
        return moment.unix(value).format(momentFormat.date);
      } else {
        return moment(value).format(momentFormat.date);
      }
    },

    // YYYY/MM/DD (ddd) 形式の日付を取得する
    localizeDateWeek(value) {
      if (unixRegexp.msX.test(value)) {
        return moment.unix(value / 1000).format(momentFormat.dateWeek);
      } else if (unixRegexp.s.test(value)) {
        return moment.unix(value).format(momentFormat.dateWeek);
      } else {
        return moment(value).format(momentFormat.dateWeek);
      }
    },

    // YYYY/MM/DD (ddd) HH:mm 形式の日時を取得する
    localizeDateTime(value) {
      if (unixRegexp.msX.test(value)) {
        return moment.unix(value / 1000).format(momentFormat.dateTime);
      } else if (unixRegexp.s.test(value)) {
        return moment.unix(value).format(momentFormat.dateTime);
      } else {
        return moment(value).format(momentFormat.dateTime);
      }
    },

    // HH:mm 形式の時刻を取得する
    localizeTime(value) {
      if (unixRegexp.msX.test(value)) {
        return moment.unix(value / 1000).format(momentFormat.time);
      } else if (unixRegexp.s.test(value)) {
        return moment.unix(value).format(momentFormat.time);
      } else {
        return moment(value).format(momentFormat.time);
      }
    },

    getTimestamp() {
      return moment().valueOf();
    },

    isExpiredDateTime(value) {
      const now = moment().format('x');
      const date = moment(value).format('x');
      return now >= date;
    },

    localizePrice(value) {
      const price = new Intl.NumberFormat('ja-JP', {
        style: 'currency',
        currency: 'JPY'
      }).format(value);
      return price;
    },

    toTopScroll() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },

    getRippleOptions(rippleTheme) {
      const theme = (function () {
        if (!rippleTheme) {
          return false;
        } else if (rippleTheme === 'light') {
          return colors.common.grey01;
        } else {
          return colors.common.grey04;
        }
      })();
      if (theme) {
        return {
          directive: 'ripple',
          color: theme,
          initialOpacity: 0.28,
          easing: 'ease-in'
        };
      } else {
        return false;
      }
    },

    /**
     * 指定文字列をクリップボードにコピーする
     *
     * @param {DOMString} value コピーする文字列
     * @see https://developer.mozilla.org/en-US/docs/Web/API/Clipboard/writeText
     */
    async copy(value) {
      const { isAndroid, setClipboard } = useNativeConnection();
      if (isAndroid) {
        // NOTE:
        // Android の WebView は Clipboard にアクセスできないので、
        // ネイティブの関数経由でコピーを実現する
        await setClipboard(value);
      } else {
        await navigator.clipboard.writeText(value);
      }
    },

    /**
     * URLのクエリー文字列を返す
     *
     * @param {USVString} url
     * @returns {string} ?q=123
     *
     * @see https://developer.mozilla.org/ja/docs/Web/API/URL/search
     */
    getUrlSearch(url) {
      try {
        const _url = new URL(url);
        return _url.search;
      } catch {
        return '';
      }
    },

    /**
     * 指定時間だけ待機する
     *
     * @param {number} [ms=1500] ミリ秒
     */
    async sleep(ms = 1500) {
      await new Promise((resolve) => setTimeout(resolve, ms));
    }
  });
};
