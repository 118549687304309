import notice from './noticeRepository';
import contentTabOrder from './contentTabOrderRepository';
import contentTop from './contentTopRepository';
import contentTopNotice from './contentTopNoticeRepository';
import contentRead from './contentReadRepository';
import contentReadFeature from './contentReadFeatureRepository';
import contentReadMedia from './contentReadMediaRepository';
import contentJoin from './contentJoinRepository';
import contentJoinFeature from './contentJoinFeatureRepository';
import productsDetail from './productsDetailRepository';
import present from './presentRepository';
import mileage from './mileageRepository';
import products from './productsRepository';
import help from './helpRepository';
import contact from './contactRepository';
import customer from './customerRepository';
import contentAlreadyRead from './contentAlreadyReadRepository';
import coffeeMap from './coffeeMapRepository';
import line from './lineRepository';
import banners from './bannersRepository';
import barista from './baristaRepository';
import baristaFavorite from './baristaFavoriteRepository';
import video from './videoRepository';

import dpTop from './drip-pod/topRepository';
import dpProRecipe from './drip-pod/proRecipeRepository';
import dpCapsule from './drip-pod/capsuleRepository';
import dpBrewHistory from './drip-pod/brewHistoryRepository';
import dpBrewMyrecipe from './drip-pod/brewMyrecipeRepository';

const repositories = {
  notice,
  contentTabOrder,
  contentTop,
  contentTopNotice,
  contentRead,
  contentReadFeature,
  contentReadMedia,
  contentJoin,
  contentJoinFeature,
  productsDetail,
  present,
  mileage,
  products,
  help,
  contact,
  customer,
  contentAlreadyRead,
  coffeeMap,
  line,
  banners,
  barista,
  baristaFavorite,
  video,

  dpTop,
  dpProRecipe,
  dpCapsule,
  dpBrewHistory,
  dpBrewMyrecipe
};

export const RepositoryFactory = {
  get: (
    name // eslint-disable-next-line
  ) => repositories[name] || console.error(`${name} repository is not found.`)
};
