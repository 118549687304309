import { ContentTab } from '@/constants/ContentTabLabel';

/**
 * @example
 * $contentTabOrder.get();
 */

export default (_, inject) =>
  inject('contentTabOrder', {
    getTabRouteName(value) {
      return ContentTab.getTabRouteName(value);
    }
  });
