export const data = {
  data: [
    {
      id: 4,
      image_url: 'https://placehold.jp/fff/333/640x200.png?text=HEADER',
      catchphrase: 'aaa',
      title: '1004',
      link_url: null,
      description: 'aaa',
      venue_name: 'aa',
      venue_address: null,
      venue_latitude: null,
      venue_longitude: null,
      price: 0,
      caution: 'a\r\nbb\r\n\r\nccc\r\n\r\ndddd',
      additional_images: [
        {
          image_url: 'https://placehold.jp/369/fff/640x200.png?text=IMAGE'
        },
        {
          image_url: 'https://placehold.jp/999/fff/640x200.png?text=IMAGE'
        },
        {
          image_url: 'https://placehold.jp/bca/fff/640x200.png?text=IMAGE'
        }
      ],
      schedules: [
        {
          start_at: '2023-01-01 00:00:00',
          end_at: '2023-01-22 00:00:00'
        },
        {
          start_at: '2022-12-02 00:00:00',
          end_at: '2022-12-03 00:00:00'
        }
      ],
      reward: [
        {
          id: 12,
          name: 'イベント用マイル',
          description: null,
          warning: null,
          type: 1,
          amount: 30,
          coupon_code: null,
          image_url: null
        }
      ],
      baristas: [
        {
          id: 28,
          name: 'てすと',
          name_alphabetical: 'test',
          image_url: 'https://placehold.jp/ac3/fff/640x200.png?text=PRO',
          overview: 'あああ\r\nいいい\r\nううう'
        },
        {
          id: 29,
          name: 'てすと2',
          name_alphabetical: 'aaa',
          image_url: 'https://placehold.jp/ac3/fff/640x200.png?text=PRO',
          overview: 'www\r\negw'
        }
      ],
      products: []
    },
    {
      id: 3,
      image_url: 'https://placehold.jp/9b4/fff/640x200.png?text=HEADER',
      catchphrase: 'test',
      title: 'プロヘッダー画像の確認',
      link_url: null,
      description: 'test',
      venue_name: 'test',
      venue_address: null,
      venue_latitude: null,
      venue_longitude: null,
      price: 0,
      caution: null,
      additional_images: null,
      schedules: [
        {
          start_at: '2022-11-19 00:00:00',
          end_at: '2022-12-31 00:00:00'
        },
        {
          start_at: '2022-11-10 00:00:00',
          end_at: '2022-11-30 00:00:00'
        }
      ],
      reward: [
        {
          id: 12,
          name: 'イベント用マイル',
          description: null,
          warning: null,
          type: 1,
          amount: 30,
          coupon_code: null,
          image_url: null
        }
      ],
      baristas: [
        {
          id: 26,
          name: 'プロ（ヘッダーC）',
          name_alphabetical: 'pro',
          image_url: null,
          overview: null
        },
        {
          id: 25,
          name: 'プロ（ヘッダーB）',
          name_alphabetical: 'pro',
          image_url: null,
          overview: null
        },
        {
          id: 24,
          name: 'プロ（ヘッダーA）',
          name_alphabetical: 'pro',
          image_url: null,
          overview: null
        }
      ],
      products: []
    },
    {
      id: 2,
      image_url: 'https://placehold.jp/333/fff/640x200.png?text=HEADER',
      catchphrase: '各プロの連携を確認するためのダミーイベントです',
      title: '中井千香子プロイベント（ダミー）',
      link_url: null,
      description:
        '「マイブレンド体験」「GCC(Gen Craft Coffee) Selected Blend」の試飲',
      venue_name: 'UCC コーヒーアカデミー神戸校',
      venue_address:
        '神戸市中央区港島中町6丁目6番2 UCC神戸本社に隣接する「UCCコーヒー博物館」内',
      venue_latitude: null,
      venue_longitude: null,
      price: 0,
      caution:
        '1回5,000円\r\n※参加費用には以下の代金も含まれています。\r\n・「マイブレンド体験」で制作した自分専用ブレン',
      additional_images: null,
      schedules: [
        {
          start_at: '2022-12-23 13:00:00',
          end_at: '2022-12-23 18:00:00'
        }
      ],
      reward: [
        {
          id: 12,
          name: 'イベント用マイル',
          description: null,
          warning: null,
          type: 1,
          amount: 30,
          coupon_code: null,
          image_url: null
        }
      ],
      baristas: [
        {
          id: 16,
          name: '中井千香子',
          name_alphabetical: 'Chikako Nakai',
          image_url: 'https://placehold.jp/ac3/fff/640x200.png?text=PRO',
          overview:
            '・UCCコーヒーマスターズ2016　サイフォン部門　優勝・ジャパンブリューワーズカップ2018　優勝\n・ワールドブリューワーズカップ2019　第4位\n・ジャパンサイフォニストチャンピオンシップ2019　優勝\n・(アメリカ)CQI認定Qアラビカグレーダー'
        }
      ],
      products: []
    },
    {
      id: 1,
      image_url: 'https://placehold.jp/36c/fff/640x200.png?text=HEADER',
      catchphrase: '各プロの連携を確認するためのダミーイベントです',
      title: '中平 尚己プロイベント（ダミー）',
      link_url: null,
      description:
        '「マイブレンド体験」「GCC(Gen Craft Coffee) Selected Blend」の試飲',
      venue_name: 'UCC コーヒーアカデミー神戸校',
      venue_address:
        '神戸市中央区港島中町6丁目6番2 UCC神戸本社に隣接する「UCCコーヒー博物館」内',
      venue_latitude: null,
      venue_longitude: null,
      price: 0,
      caution:
        '1回5,000円\r\n※参加費用には以下の代金も含まれています。\r\n・「マイブレンド体験」で制作した自分専用ブレンドのコーヒー豆 100g\r\n・「GCC(Gen Craft Coffee) Selected Blend」コーヒー豆 180g',
      additional_images: null,
      schedules: [
        {
          start_at: '2022-12-23 13:00:00',
          end_at: '2022-12-23 18:00:00'
        }
      ],
      reward: [
        {
          id: 12,
          name: 'イベント用マイル',
          description: null,
          warning: null,
          type: 1,
          amount: 30,
          coupon_code: null,
          image_url: null
        }
      ],
      baristas: [
        {
          id: 17,
          name: '中平 尚己',
          name_alphabetical: 'Naomi Nakahira',
          image_url: 'https://placehold.jp/ac3/fff/640x200.png?text=PRO',
          overview:
            '・UCCコーヒーアドバイザー・UCCコーヒー鑑定士\n・(ブラジル)コーヒー鑑定士\n・(アメリカ)CQI認定Qアラビカグレーダー'
        }
      ],
      products: []
    }
  ],
  next_page_url:
    'http://api.dev.ucc-coffee-creation.sonicmoov.com/content/feature/event?cursor=eyJpZCI6NTQsIl9wb2ludHNUb05leHRJdGVtcyI6dHJ1ZX0',
  prev_page_url: null
};
