import Incentive from './Incentive.vue';

export default {
  title: 'SHARED/Incentive',
  component: Incentive
};

const Template = (args, { argTypes }) => ({
  components: { Incentive },
  props: Object.keys(argTypes),
  template: '<Incentive v-bind="$props" />'
});

export const Default = Template.bind({});
Default.args = {
  reward: {
    type: 1,
    amount: 5
  }
};
