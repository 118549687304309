import { MileStageType } from './enums/MileStageType';

/**
 * ステージ定義
 *
 * @typedef {[boolean, string]} StageDefinition
 * @property StageDefinition[0] UCCゆかりの地かどうか ture: UCCゆかりの地
 * @property StageDefinition[1] ステージ説明
 *
 * @type {StageDefinition[]}
 */
// prettier-ignore
const STAGE_DEFENITION = [
  [true, '1989年、ハワイ島に直営農園を開設。UCC品質コンテストの開催、文化交流を通じ、コナコーヒーのPRにも尽力しています。'],
  [false, 'ラテンアメリカ最北端のコーヒー生産国です。'],
  [true, '1981年、UCCが最初に開設した直営農園はジャマイカ。以来、高品質のブルーマウンテンコーヒーを作り続けています。'],
  [false, '地域ごとに気候条件も様々。バラエティに富んだコーヒーを栽培しています。'],
  [false, '主に高原や山岳地帯の斜面で栽培されています。'],
  [true, 'ホンジュラスの女性生産者の栽培コーヒーを製品化するなど、IWCA（国際コーヒー女性連盟）の活動を支援しています。'],
  [false, '首都を中心にした山間盆地、それを取り囲む4つの火山山麓が栽培地です。'],
  [false, '中米の名産地のひとつ。パナマ産のゲイシャ種も有名です。'],
  [false, '南米ではブラジルに次いで第2位の国別生産量を誇ります。'],
  [false, '北部の県で高品質なコーヒーを生産しています。'],
  [true, '「UCC品質コンテスト」を開催。生産者と良好な関係を築き上質なコーヒーを安定的に調達することを目指しています。'],
  [false, 'アフリカ最高峰の斜面で栽培されていたため「キリマンジァロ」と呼ばれるように。'],
  [true, '地域経済の活性で貧困を減らすJICAの取組み「一村一品運動」に、UCCはコーヒー品質改善プログラムで協力しています。'],
  [false, 'アフリカ有数のコーヒー生産国で、主要産業のひとつになっています。'],
  [true, 'JICA「ベレテ・ゲラ・フォレスト森林保全プロジェクト」に、UCCはコーヒーの品質向上のための技術指導を行っています。'],
  [false, 'エチオピアに次いで古くからコーヒーが栽培されている国です。'],
  [true, '「ブルボンポワントゥ」再生プロジェクト。島の発展をもたらす未来に向けた作物として地元の生産者と取り組んでいます。'],
  [false, '産地としての歴史は古く、1695年に栽培が開始されたと伝わっています。'],
  [true, '「UCC品質コンテスト」を開催。生産者と良好な関係を築き上質なコーヒーを安定的に調達することを目指しています。'],
  [false, 'その地形から、北部では主にアラビカ種、南部ではカネフォラ種を栽培しています。'],
  [true, '「UCC品質コンテスト」を開催。生産者と良好な関係を築き上質なコーヒーを安定的に調達することを目指しています。'],
  [false, 'スマトラ島、ジャワ島などを中心に世界有数のコーヒー生産国です。'],
  [false, '近年注目の生産国。一年を通して温暖な南部で栽培されます。'],
  [true, 'UCCでは沖縄等でコーヒーの栽培技術支援を行うなど、地元の生産者とともに農業の発展に貢献しています。']
];

/**
 * ユニークステージリスト
 */
export const STAGE_LIST = [...new Array(24)].map((_, i) => {
  const id = i + 1;
  return {
    id,
    nameEn: MileStageType.getTypeName(id),
    nameJa: MileStageType.getTypeName(id, 'ja'),
    description: STAGE_DEFENITION[i][1],
    isRelatedPlace: STAGE_DEFENITION[i][0]
  };
});
